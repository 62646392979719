import 'bootstrap/dist/css/bootstrap.min.css';
import Header from './components/Header';
import { Container, Image, Row, Col, Spinner, Form, InputGroup, Alert } from 'react-bootstrap';
import React, { useRef, useState, useEffect } from 'react';
import Footer from './components/Footer';
import { Formik, Form as FormFormik } from 'formik';
import waiting from './images/waiting.png';
import waiting_grey from './images/waiting_grey.png';
import verifying from './images/verifying.png';
import verifying_grey from './images/verifying_grey.png';
import waiting_payment from './images/waiting_payment.png';
import waiting_payment_grey from './images/waiting_payment_grey.png';
import documents from './images/documents.png';
import documents_grey from './images/documents_grey.png';
import sent from './images/sent.png';
import sent_grey from './images/sent_grey.png';
import finish from './images/finish.png';
import finish_grey from './images/finish_grey.png';
import { BottomNavigationAlt, ButtonLoading, ButtonSocialMediaLogin, InputPassword } from './components/alt';

import { useHistory } from 'react-router';
import { useGetTracking } from './libs/hooks';
import { isMobile } from 'react-device-detect';
import Helper from './libs/helper';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const StatusLabel = ({status}) => {
	const [statusNumber,setStatusNumber] = useState(0);
	useEffect(()=>{
		setStatusNumber(Helper.getStatusIndex(status))
	},[status])
	return (
		<>
		
			<div className={"d-flex flex-column align-items-center border p-3 border-rounded "+ (statusNumber >= 4 ? "order-status-border-current" : null)} style={{position:'relative'}}>
				{statusNumber >= 4 && <FontAwesomeIcon icon={faCheckCircle} style={{position: 'absolute',right: 5,top: 5,color: '#fb8005'}} />}
				<img src={statusNumber >= 4 ? documents : documents_grey} style={{ width: 70 }} className="mt-3" />
				Dokumen
				
			</div>
			<div className={"d-flex flex-column align-items-center border p-3 border-rounded "+ (statusNumber >= 5 ? "order-status-border-current" : null)} style={{position:'relative'}}>
				{statusNumber >= 5 && <FontAwesomeIcon icon={faCheckCircle} style={{position: 'absolute',right: 5,top: 5,color: '#fb8005'}} />}
				<img src={statusNumber >= 5 ? sent : sent_grey} style={{ width: 70 }} className="mt-3" />
				Dikirim
			</div>
			<div className={"d-flex flex-column align-items-center border p-3 border-rounded "+ (statusNumber >= 6 ? "order-status-border-current" : null)} style={{position:'relative'}}>
				{statusNumber >= 6 && <FontAwesomeIcon icon={faCheckCircle} style={{position: 'absolute',right: 5,top: 5,color: '#fb8005'}} />}
				<img src={statusNumber >= 6 ? finish : finish_grey} style={{ width: 70 }} className="mt-3" />
				Selesai
			</div>
		</>
	)
}

function Tracking() {
	const formTracking = useRef();
	const history = useHistory();
	const [{ tracking, isLoadingTracking, isNotFound }, fetchTracking] = useGetTracking();

	const validate = (values) => {
		const errors = {};
		// if (!values.email) errors.email = "Required"
		// if (!values.password) errors.password = "Required"
		return errors;
	};

	const onSubmit = async(data) => {
		if(data && data.resi_number){
			await fetchTracking(data.resi_number);
		}
	}

	return (
		<div className="App">
			<Header />
			<Container className="p-4" style={{ minHeight: '100%' }}>
				<h3>Smallpaket Tracking</h3>
				<Row className="justify-content-center pt-4 mb-4">
					<Col sm={12} md={9}>
						<Formik innerRef={formTracking} initialValues={{
							resi_number: "",
						}} onSubmit={onSubmit} onKeyPress={onSubmit} validate={validate} validateOnChange={false} validateOnBlur={false} novalidate className="needs-validation was-validated">
							{({ handleChange, handleBlur, handleSubmit, values, errors }) => (
								<FormFormik>
									<div className="form-group mb-3">
										<InputGroup>
											<Form.Control
												type="text"
												placeholder="Masukkan No. Resi"
												aria-describedby="inputGroupPrepend"
												name="resi_number"
												size="lg"
												value={values.resi_number}
												onChange={handleChange}
												isInvalid={!!errors.resi_number}
											/>
											<ButtonLoading title={"Cek Resi"} isLoading={isLoadingTracking} onClick={()=>onSubmit()}/>
										</InputGroup>
										<div className="invalid-feedback">
											{errors.resi_number}
										</div>
									</div>
									<div className="d-grid mt-3">
									</div>
								</FormFormik>
							)}
						</Formik>
					</Col>
				</Row>
				{isNotFound &&
					<Row className={"justify-content-center"}>
						<Col sm={12} md={9}>
							<Alert variant={"warning"}>
								<span className={"text-dark"}>
									Pesanan tidak ditemukan
								</span>
							</Alert>
						</Col>
					</Row>
				}
				{(tracking && tracking.data) &&
					<Row className={"text-start justify-content-center"}>
						<Col sm={12} md={3}>
							{isMobile ? <h3 className={"text-bold text-center"}>HASIL PELACAKAN</h3> : <h3 className={"text-bold"}>HASIL PELACAKAN</h3> } 

							<div className={"pt-4 text-center mb-5"}>
								<div>
									<h5>FROM</h5>
									<h5>{(tracking.data.data.origin).toString().toUpperCase()}</h5>
								</div>
								<div className='d-flex justify-content-center flex-column align-items-center'>
									<div style={{
										border: '1px solid',
										width: '10px',
										height: '10px',
										borderRadius: '10px',
										backgroundColor: '#52b6d3',
										borderColor: '#52b6d3'
									}}></div>
									<div style={{
										borderRight: '2px dotted',
										height: '5rem',
										borderColor: '#52b6d3'
									}}></div>
									<div style={{
										border: '1px solid',
										width: '10px',
										height: '10px',
										borderRadius: '10px',
										backgroundColor: '#52b6d3',
										borderColor: '#52b6d3'
									}}></div>
								</div>
								<div className={"mt-2"}>
									<h5>TO</h5>
									<h5>{(tracking.data.data.destination).toString().toUpperCase()}</h5>
								</div>
							</div>
						</Col>
						<Col sm={12} md={6}>
							<div className={"mb-5 d-flex justify-content-between"}>
									<StatusLabel status={tracking.data.data.status} />
							</div>
							{isMobile ?
								<h4 className={"text-center text-bold"}>{tracking.data.data.order_number}</h4>
							: 
								<h4 className={"text-start text-bold"}>{tracking.data.data.order_number}</h4>
							}
							{tracking.data.data.trackings.map((v,i,a)=>{
								return (
									<>
									{!isMobile ?
										<div className={"py-2 border-top d-flex flex-row"} key={"trc+"+i}>
											<div className={"text-bold mr-3"} style={{marginRight:10}}>{v.created_at}</div> 
											<div className={"ml-3"}>{v.description}</div>
										</div>
										: 
										<div className={"py-2 border-top"} key={"trc+"+i}>
											<div className={"text-bold mr-3"}>{v.created_at}</div> 
											<div className={"ml-3"}>{v.description}</div>
										</div>
									}
									</>
								)
							})}
							
						</Col>
					</Row>
				}
			</Container>
			<Footer />
			<BottomNavigationAlt active={3} />
		</div>
	);
}

export default Tracking;
