import React, { useState, useEffect, useRef, forwardRef } from 'react';
import Header from './components/Header'
import Footer from './components/Footer'
import Sidebar from './components/Sidebar'
import { useLocation } from 'react-router-dom';
import { Col, Image, Modal, Row, Spinner, Container, Collapse } from 'react-bootstrap';
import { SelectConsignee, SelectShipper, Select2, SelectDocument, SelectPackaging, IconCourier, BottomNavigationAlt } from './components/alt';
import CurrencyInput from 'react-currency-input-field';
import { useCalculateprice, useGetinsurance, usePostshipment } from './libs/hooks';
import Helper from './libs/helper';
import rocket from './images/rocket.gif'
import { faExclamationTriangle, faEnvelope, faChevronCircleUp, faChevronUp, faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { isBrowser } from 'react-device-detect';


export default function Order() {
	const useQuery = () => {
		return new URLSearchParams(useLocation().search);
	}
	const queryParams = useQuery()
	const [shipper, setShipper] = useState(null)
	const [consignee, setConsignee] = useState(null)
	const [itemCategory, setItemCategory] = useState(null)
	const [defaultIdItemCategory, setDefaultIdItemCategory] = useState(null)
	const [itemType, setItemType] = useState(null)
	const [defaultIdItemType, setDefaultIdItemType] = useState(null)
	const [keyItemType, setKeyItemType] = useState(null)
	const [origin, setOrigin] = useState(null)
	const [defaultIdOrigin, setDefaultIdOrigin] = useState(null)
	const [destination, setDestination] = useState(null)
	const [defaultIdDestination, setDefaultIdDestination] = useState(null)
	const [quantity, setQuantity] = useState()
	const [weight, setWeight] = useState(null)
	const [tall, setTall] = useState(null)
	const [long, setLong] = useState(null)
	const [wide, setWide] = useState(null)
	const [itemDescription, setItemDescription] = useState(null)
	const [itemPrice, setItemPrice] = useState(null)
	const [volume, setVolume] = useState(null)
	const [totalWeight, setTotalWeight] = useState(null)
	const [magicNumber, setMagicNumber] = useState(5500)
	const [totalVolume, setTotalVolume] = useState(null)
	const [courier, setCourier] = useState(null)
	const [{ isLoadingCalculatePrice, responseCalculatePrice, errorCalculatePrice }, fetchCalculatePrice, resetCalculatePrice] = useCalculateprice()
	const [addonPrice, setAddonPrice] = useState(0)
	const [documentPrice, setDocumentPrice] = useState(0)
	const [packagingPrice, setPackagingPrice] = useState(0)
	const [priceBeforeInsurance, setPriceBeforeInsurance] = useState(0)
	const [priceAfterInsurance, setPriceAfterInsurance] = useState(0)
	const [totalPrice, setTotalPrice] = useState(0)
	const [insurancePrice, setInsurancePrice] = useState(0)
	const [documents, setDocuments] = useState([])
	const [packages, setPackages] = useState([])
	const [{ rateInsurance }, fetchGetInsurance] = useGetinsurance()
	const [errors, setErrors] = useState({})
	const [couriers, setCouriers] = useState([])
	const [itemDetailKey, setItemDetailKey] = useState(0)
	const [{ responseShipment, isLoadingShipment, errorShipment }, fetchPostShipment] = usePostshipment()
	const [isShowModalLoading, setIsShowModalLoading] = useState(false)
	const [collapseShipper, setCollapseShipper] = useState(true)
	const [collapseConsignee, setCollapseConsignee] = useState(true)
	const [collapseItem, setCollapseItem] = useState(true)
	const [collapseCourier, setCollapseCourier] = useState(true)
	const [collapseSummary, setCollapseSummary] = useState(true)
	const [collapseDocument, setCollapseDocument] = useState(true)
	const [collapsePackaging, setCollapsePackaging] = useState(true)
	const [collapseAll, setCollapseAll] = useState(false);
	const [isClickSubmit,setIsClickSubmit] = useState(false);
	const styles = {
		section: {
			borderRadius: '10px'
		}
	}
	const refShipper = useRef(null)
	const refConsginee = useRef(null)
	const refItem = useRef(null)
	const refCourier = useRef(null)

	const refShipperSubmit = useRef();
	const refConsigneeSubmit = useRef();

	useEffect(()=>{
		setIsShowModalLoading(false)
	},[errorShipment])


	useEffect(() => {

		if (queryParams.get('_type_id')) {
			setDefaultIdItemType({
				value: queryParams.get('_type_id'),
				label: queryParams.get('_type_text'),
			})
		}
		if (queryParams.get('_category_id')) {
			setDefaultIdItemCategory({
				value: queryParams.get('_category_id'),
				label: queryParams.get('_category_text'),
			})
		}
		if (queryParams.get('_origin_id')) {
			setDefaultIdOrigin({
				value: queryParams.get('_origin_id'),
				label: queryParams.get('_origin_text'),
			})
		}
		if (queryParams.get('_destination_id')) {
			setDefaultIdDestination({
				value: queryParams.get('_destination_id'),
				label: queryParams.get('_destination_text'),
			})
		}

		if (queryParams.get('_weight')) { setWeight(queryParams.get('_weight')) }
		if (queryParams.get('_tall')) { setTall(queryParams.get('_tall')) }
		if (queryParams.get('_qty')) { setQuantity(queryParams.get('_qty')) }
		if (queryParams.get('_long')) { setLong(queryParams.get('_long')) }
		if (queryParams.get('_tall')) { setTall(queryParams.get('_tall')) }
		if (queryParams.get('_wide')) { setWide(queryParams.get('_wide')) }
		if (queryParams.get('_weight')) { setWeight(queryParams.get('_weight')) }
		setItemDetailKey(itemDetailKey + 1)

	}, [])

	// calculate volume
	useEffect(() => {
		if (!long || !wide || !tall || !quantity) setTotalVolume(0);
		else {
			setTotalVolume(Helper.getVolume(long, tall, wide, quantity))
		}
	}, [long, wide, tall, quantity])

	// reset Item Type if item Category reset
	useEffect(() => {
		setKeyItemType(keyItemType + 1)
	}, [itemCategory])

	// calculate total weight
	useEffect(() => {
		if (!quantity || !weight) setTotalWeight(0);
		else {
			setTotalWeight(weight * quantity)
		}
	}, [quantity, weight])

	useEffect(() => {
		if (couriers.length <= 0) return;
		if (queryParams.get('_courier_id')) {
			// console.log(couriers)
			couriers.map((v, i, a) => {
				// console.log(v.courier_id,queryParams.get('_courier_id'))
				if (v.courier_id === parseInt(queryParams.get('_courier_id'))) {
					setCourier(v)
					// alert('ada')
				}
			})
		}
	}, [couriers])

	useEffect(() => {
		if (collapseAll) {
			setCollapseItem(false)
			setCollapseShipper(false)
			setCollapseConsignee(false)
			setCollapseCourier(false)
			setCollapseSummary(false)
			setCollapseDocument(false)
			setCollapsePackaging(false)
		} else {
			setCollapseItem(true)
			setCollapseShipper(true)
			setCollapseConsignee(true)
			setCollapseCourier(true)
			setCollapseSummary(true)
			setCollapseDocument(true)
			setCollapsePackaging(true)
		}
	}, [collapseAll])


	// get courier if parameter changes
	useEffect(() => {
		setCourier(null)
		setCouriers([])
		if (!origin || !destination || !itemType || !quantity || !long || !wide || !weight || !tall) {
			return;
		}
		setTimeout(() => {

			fetchCalculatePrice({
				origin_id: origin ? origin.value : null,
				destination_id: destination ? destination.value : null,
				jenis_barang_id: itemType ? itemType.value : null,
				quantity: quantity,
				long: long,
				wide: wide,
				tall: tall,
				weight: weight
			})
		}, 1000)
	}, [origin, destination, itemType, quantity, long, wide, weight, tall])

	// set courier
	useEffect(() => {
		if (!responseCalculatePrice) return;
		setCouriers(responseCalculatePrice.data)
		// console.log(responseCalculatePrice.data)
	}, [responseCalculatePrice])

	// calculate summary
	useEffect(() => {
		let priceAddon = 0;
		let priceDocument = 0;
		let pricepackaging = 0;
		let priceCourier = courier ? courier.price : 0;
		let totalPrice = 0;
		let totalPriceBeforeInsurance = 0;
		let rate = rateInsurance ?? 0;
		let priceRate = 0;
		packages.map((v, i, a) => {
			pricepackaging += v.price;
		})
		documents.map((v, i, a) => {
			priceDocument += v.price;
		})
		priceAddon = priceDocument + pricepackaging;
		totalPrice = priceAddon + priceCourier;
		totalPriceBeforeInsurance = (priceAddon) + priceCourier;
		priceRate = (parseFloat(rate) / 100) * parseFloat(itemPrice??0);
		setAddonPrice(priceAddon)
		setDocumentPrice(priceDocument)
		setPackagingPrice(pricepackaging)
		setPriceBeforeInsurance(totalPriceBeforeInsurance)
		setInsurancePrice(priceRate)
		setTotalPrice(totalPrice + priceRate)
	}, [rateInsurance, courier, documents, packages, itemPrice])

	// after submit
	useEffect(() => {
		if (!responseShipment) return;
		setTimeout(() => {
			// window.location.href = "/order/" + responseShipment.data.id;
			Helper.redirectTo("order-detail/" + responseShipment.data.id)
		}, 2000)
	}, [responseShipment])

	const validate = () => {
		setErrors({});
		let errorList = {};
		let isError = false;
			if (!shipper) {
				errorList['shipper'] = "*Required"
				refShipper.current.scrollIntoView();
				isError = true;
			}
			if (!consignee) {
				errorList['consignee'] = "*Required"
				if(!isError) refConsginee.current.scrollIntoView();
				isError = true;
			}
			if (!itemCategory) {
				errorList['itemCategory'] = "*Required"
				if(!isError) refItem.current.scrollIntoView();
				isError = true;
			}
			if (!itemType) {
				errorList['itemType'] = "*Required"
				if(!isError) refItem.current.scrollIntoView();
				isError = true;
			}
			if (!quantity) {
				errorList['quantity'] = "*Required"
				if(!isError) refItem.current.scrollIntoView();
				isError = true;
			}
			if (!origin) {
				errorList['origin'] = "*Required"
				if(!isError) refItem.current.scrollIntoView();
				isError = true;
			}
			if (!destination) {
				errorList['destination'] = "*Required"
				if(!isError) refItem.current.scrollIntoView();
				isError = true;
			}
			if (!weight) {
				errorList['weight'] = "*Required"
				if(!isError) refItem.current.scrollIntoView();
				isError = true;
			}
			if (!long) {
				errorList['long'] = "*Required"
				if(!isError) refItem.current.scrollIntoView();
				isError = true;
			}
			if (!wide) {
				errorList['wide'] = "*Required"
				if(!isError) refItem.current.scrollIntoView();
				isError = true;
			}
			if (!tall) {
				errorList['tall'] = "*Required"
				if(!isError) refItem.current.scrollIntoView();
				isError = true;
			}
			if (!itemPrice) {
				errorList['itemPrice'] = "*Required"
				if(!isError) refItem.current.scrollIntoView();
				isError = true;
			}
			if (!itemDescription) {
				errorList['itemDescription'] = "*Required"
				if(!isError) refItem.current.scrollIntoView();
				isError = true;
				
			}
			if (!courier) {
				errorList['courier'] = "*Required"
				if(!isError) refCourier.current.scrollIntoView();
				isError = true;
		}

		setErrors(errorList);
		return Object.keys(errorList).length === 0
	}

	const doSubmit = () => {
		
		if (validate()) {
			
			setIsShowModalLoading(true)
			let addons = [];
			packages.map((v, i, a) => {
				addons.push({
					type: 'PACKAGING',
					id: v.id,
					price: v.price
				})
			})
			documents.map((v, i, a) => {
				addons.push({
					type: 'DOCUMENT',
					id: v.id,
					price: v.price
				})
			})
			fetchPostShipment({
				shipper_id: shipper.id,
				consignee_id: consignee.id,
				quantity: quantity,
				long: long,
				wide: wide,
				tall: tall,
				weight: weight,
				courier_id: courier.courier_id,
				origin_id: origin.value,
				destination_id: destination.value,
				category_id: itemCategory.value,
				jenis_barang_id: itemType.value,
				item_description: itemDescription,
				item_price: itemPrice,
				addons: addons
			})
		}
		
	}

	const submitForm = (e) => {
		e.preventDefault();
		doSubmit();
	}

	const onCallAdministrator = () => {
		window.location.href = `mailto:${process.env.REACT_APP_ADMINISTRATOR_EMAIL}`
	}


	return (
		<>
			<Header />
			<div className="app2">
				<Sidebar />
				<Container className="text-start p-4">
					<div className="d-flex justify-content-between">
						<h5 className="text-bold">Buat Pesanan Baru</h5>
						<a href="#" className="text-orange" onClick={() => setCollapseAll(!collapseAll)}>
							{!collapseAll ?
								<FontAwesomeIcon icon={faChevronUp} />
								:
								<FontAwesomeIcon icon={faChevronDown} />
							}
						</a>
					</div>
					<div>
						<form onSubmit={(e) => { setIsClickSubmit(true);submitForm(e) }} onChange={() => {}} style={{ width: '100%' }}>
							<Row>
								<Col sm={12} className="">
									<Row>
										<Col sm={12} className="mb-4" ref={refShipper}>
											<Row className="p-3 border" style={styles.section}>
												<Col sm={12} className="d-flex justify-content-between">
													<h5>Pengirim</h5>
													<a href="#" className="text-orange" onClick={(e) => { e.preventDefault(); setCollapseShipper(!collapseShipper) }}>
														{collapseShipper ?
															<FontAwesomeIcon icon={faChevronUp} />
															:
															<FontAwesomeIcon icon={faChevronDown} />
														}
													</a>
												</Col>
												<Col sm={12}>
													<hr />
												</Col>
												<Collapse in={collapseShipper}>
													<Col sm={12}>
														<SelectShipper onChange={(e) => setShipper(e)} ref={refShipperSubmit} />
														{errors.shipper ? <div className="invalid-feedback2">{errors.shipper}</div> : null}
													</Col>
												</Collapse>
											</Row>
										</Col>
										<Col sm={12} className="mb-4" ref={refConsginee}>
											<Row className="p-3 border" style={styles.section}>
												<Col sm={12} className="d-flex justify-content-between">
													<h5>Penerima</h5>
													<a href="#" className="text-orange" onClick={(e) => { e.preventDefault(); setCollapseConsignee(!collapseConsignee) }}>
														{collapseConsignee ?
															<FontAwesomeIcon icon={faChevronUp} />
															:
															<FontAwesomeIcon icon={faChevronDown} />
														}
													</a>
												</Col>
												<Col sm={12}>
													<hr />
												</Col>
												<Collapse in={collapseConsignee}>
													<Col sm={12}>
														<SelectConsignee onChange={(e) => setConsignee(e)} ref={refConsigneeSubmit} />
														{errors.consignee ? <div className="invalid-feedback2">{errors.consignee}</div> : null}
													</Col>
												</Collapse>
											</Row>
										</Col>
										<Col sm={12} className="mb-4" ref={refItem}>
											<Row className="p-3 border" style={styles.section} key={itemDetailKey}>
												<Col sm={12} className="d-flex justify-content-between">
													<h5>Detail Item</h5>
													<a href="#" className="text-orange" onClick={(e) => { e.preventDefault(); setCollapseItem(!collapseItem) }}>
														{collapseItem ?
															<FontAwesomeIcon icon={faChevronUp} />
															:
															<FontAwesomeIcon icon={faChevronDown} />
														}
													</a>
												</Col>
												<Col sm={12}>
													<hr />
												</Col>
												<Collapse in={collapseItem}>
													<Col sm={12}>
														<Row className="mb-4">
															<Col md={3} xs={12} className="text-start mb-2">
																<strong>*Kategori Barang</strong>
																<Select2
																	url={process.env.REACT_APP_API_HOST_URL + process.env.REACT_APP_API_V1 + "/master/category"}
																	valueId={'id'}
																	valueLabel={'name'}
																	defaultValue={defaultIdItemCategory}
																	placeholder="Pilih Kategori Barang"
																	onChange={(e) => setItemCategory(e)} />
																{errors.itemCategory ? <div className="invalid-feedback2">{errors.itemCategory}</div> : null}
															</Col>
															<Col md={3} xs={12} className="text-start mb-2" key={keyItemType}>
																<strong>*Jenis Barang</strong>
																<Select2
																	url={process.env.REACT_APP_API_HOST_URL + process.env.REACT_APP_API_V1 + "/master/jenisBarang/category/" + (itemCategory ? itemCategory.value : '-')}
																	valueId={'id'}
																	defaultValue={defaultIdItemType}
																	valueLabel={'name'}
																	placeholder="Pilih Jenis Barang"
																	onChange={(e) => setItemType(e)} />
																{errors.itemType ? <div className="invalid-feedback2">{errors.itemType}</div> : null}
															</Col>
															<Col md={3} sm={12} className="text-start">
																<strong>*Dari</strong>
																<Select2
																	url={process.env.REACT_APP_API_HOST_URL + process.env.REACT_APP_API_V1 + "/master/origin"}
																	valueId={'id'}
																	defaultValue={defaultIdOrigin}
																	valueLabel={'name'}
																	placeholder="Pilih Lokasi Asal"
																	onChange={(e) => setOrigin(e)}
																	className="is-invalid"
																/>
																{errors.origin ? <div className="invalid-feedback2">{errors.origin}</div> : null}
															</Col>
															<Col md={3} sm={12} className="text-start">
																<strong>*Ke</strong>
																<Select2
																	url={process.env.REACT_APP_API_HOST_URL + process.env.REACT_APP_API_V1 + "/master/destination"}
																	valueId={'id'}
																	defaultValue={defaultIdDestination}
																	valueLabel={'name'}
																	placeholder="Pilih Lokasi Asal"
																	onChange={(e) => setDestination(e)} />
																{errors.destination ? <div className="invalid-feedback2">{errors.destination}</div> : null}
															</Col>
														</Row>
														<Row className="mb-4">
															<Col md={1} xs={6} className="text-start mb-2">
																<strong>*Jumlah</strong>
																<CurrencyInput defaultValue={quantity} className="form-control" placeholder="" allowDecimals={false} onValueChange={(value, name) => setQuantity(value)} />
																{errors.quantity ? <div className="invalid-feedback2">{errors.quantity}</div> : null}
															</Col>
															<Col md={2} xs={6} className="text-start mb-2">
																<strong>*Berat</strong>
																<div className="input-group mb-0">
																	<CurrencyInput defaultValue={weight} className="form-control" placeholder="" allowDecimals={false} onValueChange={(value, name) => setWeight(value)} />
																	<span className="input-group-text" id="basic-addon2">kg</span>
																</div>
																{errors.weight ? <div className="invalid-feedback2">{errors.weight}</div> : null}
															</Col>
															<Col md={3} xs={6} className="text-start mb-2">
																<strong>*Panjang</strong>
																<div className="input-group mb-0">
																	<CurrencyInput defaultValue={long} className="form-control" placeholder="" allowDecimals={false} onValueChange={(value, name) => setLong(value)} />
																	<span className="input-group-text" id="basic-addon2">cm</span>
																</div>
																{errors.long ? <div className="invalid-feedback2">{errors.long}</div> : null}
															</Col>
															<Col md={3} xs={6} className="text-start mb-2">
																<strong>*Lebar</strong>
																<div className="input-group mb-0">
																	<CurrencyInput defaultValue={wide} className="form-control" placeholder="" allowDecimals={false} onValueChange={(value, name) => setWide(value)} />
																	<span className="input-group-text" id="basic-addon2">cm</span>
																</div>
																{errors.wide ? <div className="invalid-feedback2">{errors.wide}</div> : null}
															</Col>
															<Col md={3} xs={6} className="text-start mb-2">
																<strong>*Tinggi</strong>
																<div className="input-group mb-0">
																	<CurrencyInput defaultValue={tall} className="form-control" placeholder="" allowDecimals={false} onValueChange={(value, name) => setTall(value)} />
																	<span className="input-group-text" id="basic-addon2">cm</span>
																</div>
																{errors.tall ? <div className="invalid-feedback2">{errors.tall}</div> : null}
															</Col>
															{errorCalculatePrice ?
																<Col sm={12} md={12} className="mt-3 border border-danger p-3 text-danger">
																	<FontAwesomeIcon icon={faExclamationTriangle} />&nbsp;
																	Maaf, Layanan yang Anda inginkan saat ini belum tersedia. Hubungi Administrator kami untuk informasi selengkapnya.<br />
																	<button type="button" className="btn btn-primary bg-secondary" onClick={() => onCallAdministrator()} >
																		<FontAwesomeIcon icon={faEnvelope} />&nbsp; Hubungi Administrator
																	</button>
																</Col> : null}
														</Row>
														<Row style={{
															borderTop: '5px solid #f7f7f7'
														}} className="pt-3">
															<Col md={6} sm={12} className="text-start p-3 mb-3" style={{ backgroundColor: '#F7F7F7' }}>
																<Row>
																	<Col md={4} xs={12} className="mb-2">
																		Total Volume: {totalVolume} m<sup>3</sup>
																	</Col>
																	<Col md={4} xs={12}>
																		Total Berat: {totalWeight} kg
																	</Col>
																</Row>
															</Col>
															<Col md={6} sm={12} className="text-md-end text-sm-center">

															</Col>
														</Row>

														<Row className="mt-2">
															<Col md={3} xs={12} className="text-start mb-2">
																<strong>*Deskripsi Barang</strong>
																<div className="input-group mb-0">
																	<input type="text" className="form-control" onChange={(e) => setItemDescription(e.target.value)} />
																</div>
																{errors.itemDescription ? <div className="invalid-feedback2">{errors.itemDescription}</div> : null}
															</Col>
															<Col md={3} xs={12} className="text-start mb-2">
																<strong>*Harga Barang</strong>
																<div className="input-group mb-0">
																	<CurrencyInput className="form-control" placeholder="" allowDecimals={false} onValueChange={(value, name) => setItemPrice(value)} />
																	<span className="input-group-text" id="basic-addon2">IDR</span>
																</div>
																{errors.itemPrice ? <div className="invalid-feedback2">{errors.itemPrice}</div> : null}
															</Col>
														</Row>
													</Col>
												</Collapse>
											</Row>
										</Col>

										{couriers.length > 0 ?
											<Col sm={12} className="mb-4" ref={refCourier}>
												<Row className="p-3 border" style={styles.section}>
													<Col sm={12} className="d-flex justify-content-between">
														<h5>Pilih Kurir</h5>
														<a href="#" className="text-orange" onClick={(e) => { e.preventDefault(); setCollapseCourier(!collapseCourier) }}>
															{collapseCourier ?
																<FontAwesomeIcon icon={faChevronUp} />
																:
																<FontAwesomeIcon icon={faChevronDown} />
															}
														</a>
													</Col>
													<Col sm={12}>
														<hr />
													</Col>
													<Collapse in={collapseCourier}>
														<Col>
															<table className="table table-bordered">
																<thead>
																	<tr className="bg-primary">
																		<th className="text-center text-white" colSpan={isBrowser ? 2 : 1}>Kurir</th>
																		<th className="text-end text-white">Harga</th>
																		<th className="text-center text-white"></th>
																	</tr>
																</thead>
																<tbody>
																	{couriers.map((v, i, a) => {
																		return (
																			<tr key={'c' + i} className={courier === v ? 'bg-selected' : null}>
																				<td width={100} className="text-center">
																					<IconCourier courier_id={v.courier_id} avatar={v.avatar} />
																				</td>
																				{isBrowser ? <td className="text-center">{v.courier}</td> : null}
																				{/* <td>{v.duration ?? '-'}</td> */}
																				<td className="text-end text-bold">{v.price_label} IDR</td>
																				<td className="text-center">
																					<button type="button" className={`btn btn-sm ` + (courier === v ? 'btn-primary' : `btn-outline-primary`)} onClick={() => { setCourier(v) }}>
																						{courier === v ? "Terpilih" : "Pilih"}
																					</button>
																				</td>
																			</tr>
																		)
																	})}
																</tbody>
															</table>
															{errors.courier ? <div className="invalid-feedback2">{errors.courier}</div> : null}
														</Col>
													</Collapse>
												</Row>
											</Col>
											: null}
										{itemType && responseCalculatePrice ?
											<Col sm={12} key={keyItemType}>
												<Row className="mb-4 border p-3" style={styles.section}>
													<Col sm={12} md={12} className="">
														<Row>
															<Col className="d-flex justify-content-between">
																<h5>Dokumen</h5>
																<a href="#" className="text-orange" onClick={(e) => { e.preventDefault(); setCollapseDocument(!collapseDocument) }}>
																	{collapseDocument ?
																		<FontAwesomeIcon icon={faChevronUp} />
																		:
																		<FontAwesomeIcon icon={faChevronDown} />
																	}
																</a>
															</Col>
															<Col sm={12}>
																<hr />
															</Col>
															<Collapse in={collapseDocument}>
																<Col sm={12}>
																	<p>
																		Untuk jenis pengiriman <span className="text-bold text-orange">{itemType.label}</span> membutuhkan dokumen:
																	</p>
																	<SelectDocument onChange={(e) => { setDocuments(e) }} jenisBarangId={itemType.value} />
																</Col>
															</Collapse>
														</Row>
													</Col>
												</Row>
											</Col>
											: null}
										<Col sm={12} className="mb-4">
											<Row sm={12} md={12} className="border ml-2 p-3" style={styles.section}>
												<Col className="d-flex justify-content-between">
													<h5>Packaging</h5>
													<a href="#" className="text-orange" onClick={(e) => { e.preventDefault(); setCollapsePackaging(!collapsePackaging) }}>
														{collapsePackaging ?
															<FontAwesomeIcon icon={faChevronUp} />
															:
															<FontAwesomeIcon icon={faChevronDown} />
														}
													</a>
												</Col>
												<Col sm={12}>
													<hr />
												</Col>
												<Collapse in={collapsePackaging}>
													<Col sm={12}>
														<SelectPackaging onChange={(e) => { setPackages(e) }} />
													</Col>
												</Collapse>
											</Row>
										</Col>
										<Col sm={12} className="mb-4">
											<Row className="p-3 border" style={styles.section}>
												<Col sm={12} className="d-flex justify-content-between">
													<h5>Ringkasan Transaksi</h5>
													<a href="#" className="text-orange" onClick={(e) => { e.preventDefault(); setCollapseSummary(!collapseSummary) }}>
														{collapseSummary ?
															<FontAwesomeIcon icon={faChevronUp} />
															:
															<FontAwesomeIcon icon={faChevronDown} />
														}
													</a>
												</Col>
												<Col sm={12}>
													<hr />
												</Col>
												<Collapse in={collapseSummary}>
													<Col sm={12} className="" style={styles.section}>
														<Row className="mb-2">
															<Col><span>Pengiriman</span></Col>
															<Col className="text-end">{Helper.formatMoney(courier ? courier.price : 0)}</Col>
														</Row>
														<Row className="mb-2">
															<Col><span>Dokumen</span></Col>
															<Col className="text-end">{Helper.formatMoney(documentPrice)}</Col>
														</Row>
														<Row className="mb-2">
															<Col><span>Packaging</span></Col>
															<Col className="text-end">{Helper.formatMoney(packagingPrice)}</Col>
														</Row>
														<hr />
														<Row>
															<Col><span>Harga Sebelum Asuransi</span></Col>
															<Col className="text-end">{Helper.formatMoney(priceBeforeInsurance)}</Col>
														</Row>
														<hr />
														<Row>
															<Col><span>Asuransi ({rateInsurance}%)</span></Col>
															<Col className="text-end">{Helper.formatMoney(insurancePrice)}</Col>
														</Row>
														<hr />
														<Row>
															<Col><span>Total Tagihan</span></Col>
															<Col className="text-end">
																<strong style={{ fontSize: '1.2em' }}>{Helper.formatMoney(totalPrice)}</strong>
															</Col>
														</Row>
													</Col>

												</Collapse>
											</Row>
										</Col>
										<Col sm={12} className="mt-3 text-end">
											<button type="submit" className="btn btn-primary btn-lg">
												{isLoadingShipment ?
													<Spinner
														as="span"
														animation="border"
														size="sm"
														role="status"
														aria-hidden="true"
													/> : null}
												&nbsp;Submit
											</button>
											<Modal show={isShowModalLoading} backdrop="static" centered onHide={() => setIsShowModalLoading(false)}>
												<Modal.Header>
													<Modal.Title>Create Order...</Modal.Title>
												</Modal.Header>

												<Modal.Body className="text-center">
													<Image src={rocket} size="md" />
													<h5 style={{ fontWeight: 'bold' }} className="mt-2">Please Wait...</h5>
												</Modal.Body>
											</Modal>
										</Col>
									</Row>
								</Col>
							</Row>
						</form>

					</div>
				</Container>
			</div >
			<Footer />
			<BottomNavigationAlt active={1} />
		</>
	)
}