import 'bootstrap/dist/css/bootstrap.min.css';
import Header from './components/Header';
import { Container, Image } from 'react-bootstrap';
import React from 'react';
import Footer from './components/Footer';
import soon from './images/soon.jpg'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import Helper from './libs/helper';


function Soon() {

	
	return (
		<div className="App">
			<Header />
			<Container className="p-4">
				<Image src={soon} style={{ width:'70%' }} /> <br/>
				{/* <span>Wohoo!!! This page under construction...</span> <br/> */}
				<button className="btn btn-primary mt-2" onClick={()=>{Helper.redirectTo('')}}>
					<FontAwesomeIcon icon={faChevronLeft} /> &nbsp;
					Back
				</button>
			</Container>
			<Footer />
		</div>
	);
}

export default Soon;
