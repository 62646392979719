import 'bootstrap/dist/css/bootstrap.min.css';
// import "@fontsource/nunito-sans"
import "@fontsource/roboto"
import 'mapbox-gl/dist/mapbox-gl.css';
import './App.scss';

import React, { useEffect } from "react";
import {
	BrowserRouter as Router,
	Switch,
	Route,
	Redirect,
} from "react-router-dom";
import Home from './Home';
import Order from './Order';
import Profile from './Profile';
import History from './History';
import Helper from './libs/helper';
import OrderDetail from './OrderDetail';
import Soon from './Soon';
import Login from './Login';
import Register from './Register';
import Forgot from './ForgotPassword';
import ResetPassword from './ResetPassword';
import EmailVerification from './EmailVerification';
import AddressBook from './AddressBook';
import KebijakanPrivasi from './KebijakanPrivasi';
import MUserProfile from './MUserProfile';
import SyaratKetentuan from './SyaratKetentuan';
import Notifikasi from './Notifikasi';
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import Tracking from "./Tracking";

Sentry.init({
  dsn: "https://7ff8416ba6b0410aa88a8aad41520653@o1076614.ingest.sentry.io/6084102",
  integrations: [new Integrations.BrowserTracing()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});

function App() {

	const PrivateRoute = ({ component: Component, ...rest }) => {

		return (
			<Route
				{...rest}
				render={props =>
					Helper.guard() ? (
						<Component {...props} />
					) : (
						<Redirect
							to={{
								pathname: "/login",
								state: { from: props.location }
							}}
						/>
					)
				}
			/>
		);
	}
	return (
		<Router basename={process.env.REACT_APP_PATH_NAME}>
			<div>
				<Switch>

					<PrivateRoute path={`/profile`} component={Profile} />
					<PrivateRoute path={`/order-history`} component={History} />
					<PrivateRoute path={`/order`} component={Order} />
					<PrivateRoute path={`/address-shipper`} component={AddressBook} />
					<PrivateRoute path={`/order-detail/:id`} component={OrderDetail} />
					<PrivateRoute path={`/m-user-profile`} component={MUserProfile} />
					<PrivateRoute path={`/notifikasi`} component={Notifikasi} />
					<PrivateRoute path={`/tracking`} component={Tracking} />
					<Route path={`/login`}>
						<Login />
					</Route>
					<Route path={`/register`}>
						<Register />
					</Route>
					<Route path={`/forgot`}>
						<Forgot />
					</Route>
					<Route path={`/resetPassword`}>
						<ResetPassword />
					</Route>
					<Route path={`/emailVerification`}>
						<EmailVerification />
					</Route>
					<Route path={`/soon`}>
						<Soon />
					</Route>
					<Route path={`/kebijakan-privasi`}>
						<KebijakanPrivasi />
					</Route>
					<Route path={`/syarat-ketentuan`}>
						<SyaratKetentuan />
					</Route>
					<Route path={`/`}>
						<Home />
					</Route>
				</Switch>
			</div>
		</Router>
	);
}

export default App;
