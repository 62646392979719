import { faChevronDown, faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { Col, Container, Modal, Row, Spinner, Badge } from 'react-bootstrap';
import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect';
import { useHistory } from 'react-router';
import { BottomNavigationAlt, EmptyIcon, SearchIcon } from './components/alt';
import Footer from './components/Footer';
import Header from './components/Header';
import Sidebar from './components/Sidebar';
import Helper from './libs/helper';
import { useGetshipment } from './libs/hooks';
import waiting from './images/waiting.png';
import verifying from './images/verifying.png';
import waiting_payment from './images/waiting_payment.png';
import documents from './images/documents.png';
import sent from './images/sent.png';
import finish from './images/finish.png';

export default function History() {
	const [{ shipmentList, isLoadingShipment, responseSummary, isEmpty, isHasLoadMore }, fetchShipment, fetchLoadMore] = useGetshipment();
	const [search, setSearch] = useState("")
	const history = useHistory();
	const [selectedStatus, setSelectedStatus] = useState(null)
	const HistoryRow = ({ data }) => {
		const [isShowModal, setIsShowModal] = useState(false);
		const styles = {
			title: {
				color: "grey",
				fontSize: '10px',
			},
			subTitle: {
				color: "#333",
				fontSize: '14px',
			},
			subTitle2: {
				color: "#333",
				fontSize: '12px',
				fontWeight: 'bold'
			}
		}
		return (
			<>
				{isBrowser ?
					<Row md={12} className="shadow-sm m-1 mb-3" style={{
						border: '1px solid #f3f3f3',
						borderRadius: 10,
						marginBottom: 20,
						padding: 0,
					}}>
						<Col sm={12}>
							<Row className="p-3" style={{ backgroundColor: "#fafafa" }}>
								<Col md={3} xs={6} lg={3} className="mb-2">
									<div style={styles.title}>Order No</div>
									<div style={styles.subTitle}>{data.order_number}</div>
									<div style={styles.subTitle2}>{data.order_date}</div>
								</Col>
								<Col md={3} xs={6} lg={3} className="mb-2">
									<div style={styles.title}>Total Price</div>
									<div style={styles.subTitle}>{Helper.formatMoney(data.total_amount)}</div>
								</Col>
								<Col md={6} xs={6} lg={6} className="text-end">
									<button type="button" className="btn btn-outline-secondary btn-sm" onClick={() => Helper.routeTo(history, '/order-detail/' + data.id)}>
										Lihat Detail
									</button>
								</Col>
							</Row>
							<Row className="p-3" style={{ backgroundColor: 'unset' }}>
								<Col md={3}>
									<div style={styles.title}>Item</div>
									<div style={styles.subTitle}>{data.item}</div>
								</Col>
								<Col md={3}>
									<div style={styles.title}>Origin/Destination</div>
									<div style={styles.subTitle}>
										{data.origin} - {data.destination}
									</div>
								</Col>
								<Col md={3}>
									<div style={styles.title}>Courier</div>
									<div style={styles.subTitle}>{data.courier}</div>
								</Col>
								<Col md={3}>
									<div style={styles.title}>Status</div>
									<div style={styles.subTitle}>{data.status}</div>
								</Col>
							</Row>
						</Col>
					</Row>
					:
					<Row className="border border-rounded shadow-sm mb-3" onClick={(e) => { Helper.routeTo(history, '/order-detail/' + data.id) }}>
						<Col sm={12} className="pt-2">
							<Row className="mb-1 pb-1" style={{ borderBottom: '1px solid #f3f3f3' }}>
								<Col xs={12} sm={12} className="d-flex justify-content-between">
									<div className="d-flex flex-column">
										<span className="text-bold" style={{ fontSize: '.8em', padding: 0, margin: 0 }}>{data.order_number}</span>
										<span className="" style={{ fontSize: '.7em', padding: 0, margin: 0 }}>{data.order_date}</span>
									</div>
									<div>
										<small className="border border-rounded px-2 bg-secondary text-white text-bold" style={{ fontSize: '.6em', border: '1px solid' }}>
											{data.status}
										</small>
									</div>
								</Col>
							</Row>

							<Row className="mb-1">
								<Col xs={12} className="d-flex flex-column">
									<span className="text-bold" style={{ fontSize: '.8em' }}>{data.item}</span>
									<span className="text-bold" style={{ fontSize: '.7em', color: 'grey' }}>{data.quantity} unit</span>
								</Col>
							</Row>
							<Row className="pb-2">
								<Col xs={12} className="d-flex flex-column">
									<span className="" style={{ fontSize: '.8em' }}>Total Harga</span>
									<span className="text-bold" style={{ fontSize: '.7em' }}>{Helper.formatMoney(data.total_amount)} unit</span>
								</Col>
							</Row>
						</Col>
					</Row>

				}

			</>
		)
	}

	const onSearch = () => {
		fetchShipment(search, selectedStatus)
	}

	useEffect(() => {
		// if (selectedStatus == null) return;
		fetchShipment(search, selectedStatus)
	}, [selectedStatus])

	const RowStatus = ({ status, status_label }) => {
		const [icon, setIcon] = useState(waiting);
		const [label, setLabel] = useState(null);
		const [summaryCount, setSummaryCount] = useState(0)
		useEffect(() => {
			if (status === 1) {
				setLabel("Menunggu Barang")
				setIcon(waiting)
				setSummaryCount(responseSummary.length > 0 && responseSummary[0] ? responseSummary[0].number_of_orders : 0)
			}
			if (status === 2) {
				setLabel("Validasi Pesanan")
				setIcon(verifying)
				setSummaryCount(responseSummary.length > 0 && responseSummary[1] ? responseSummary[1].number_of_orders : 0)
			}
			if (status === 3) {
				setLabel("Pembayaran")
				setIcon(waiting_payment)
				setSummaryCount(responseSummary.length > 0 && responseSummary[2] ? responseSummary[2].number_of_orders : 0)
			}
			if (status === 4) {
				setLabel("Pengurusan Dokumen")
				setIcon(documents)
				setSummaryCount(responseSummary.length > 0 && responseSummary[3] ? responseSummary[3].number_of_orders : 0)
			}
			if (status === 5) {
				setLabel("Dikirim")
				setIcon(sent)
				setSummaryCount(responseSummary.length > 0 && responseSummary[4] ? responseSummary[4].number_of_orders : 0)
			}
			if (status === 6) {
				setLabel("Diterima")
				setIcon(finish)
				setSummaryCount(responseSummary.length > 0 && responseSummary[5] ? responseSummary[5].number_of_orders : 0)
			}
		}, [])
		return (
			<>
				<Col md={3} xs={3} lg={2} className="mb-2">
					<div className={`border border-rounded ps-1 pe-1 pb-3 text-center ` + (selectedStatus === status ? ' order-status-border-current' : null)} style={{
						position: 'relative',
						minHeight: 200,
					}}
						onClick={() => { setSelectedStatus(status) }}
					>
						<img src={icon} style={{ width: 50 }} className="mt-3" /> <br />
						<small className={`mt-2 text-bold text-orange`}>{label}</small><hr />
						<h2 className="text-orange">{summaryCount}</h2>
					</div>
				</Col>

			</>
		)
	}
	const RowStatus2 = ({ status, status_label }) => {
		const [icon, setIcon] = useState(waiting);
		const [label, setLabel] = useState(null);
		const [summaryCount, setSummaryCount] = useState(0)
		useEffect(() => {
			if (status === 0) {
				setLabel("Semua Pesanan")
				setIcon(waiting)
				let total = (responseSummary.length > 0 && responseSummary[0] ? responseSummary[0].number_of_orders : 0);
				total += responseSummary.length > 0 && responseSummary[1] ? responseSummary[1].number_of_orders : 0;
				total += responseSummary.length > 0 && responseSummary[2] ? responseSummary[2].number_of_orders : 0;
				total += responseSummary.length > 0 && responseSummary[3] ? responseSummary[3].number_of_orders : 0;
				total += responseSummary.length > 0 && responseSummary[4] ? responseSummary[4].number_of_orders : 0;
				total += responseSummary.length > 0 && responseSummary[5] ? responseSummary[5].number_of_orders : 0;
				total += responseSummary.length > 0 && responseSummary[6] ? responseSummary[6].number_of_orders : 0;
				total += responseSummary.length > 0 && responseSummary[7] ? responseSummary[7].number_of_orders : 0;

				setSummaryCount(total)
			}
			if (status === 1) {
				setLabel("Menunggu Barang")
				setIcon(waiting)
				setSummaryCount(responseSummary.length > 0 && responseSummary[0] ? responseSummary[0].number_of_orders : 0)
			}
			if (status === 2) {
				setLabel("Validasi Pesanan")
				setIcon(verifying)
				setSummaryCount(responseSummary.length > 0 && responseSummary[1] ? responseSummary[1].number_of_orders : 0)
			}
			if (status === 3) {
				setLabel("Pembayaran")
				setIcon(waiting_payment)
				setSummaryCount(responseSummary.length > 0 && responseSummary[2] ? responseSummary[2].number_of_orders : 0)
			}
			if (status === 4) {
				setLabel("Pengurusan Dokumen")
				setIcon(documents)
				setSummaryCount(responseSummary.length > 0 && responseSummary[3] ? responseSummary[3].number_of_orders : 0)
			}
			if (status === 5) {
				setLabel("Dikirim")
				setIcon(sent)
				setSummaryCount(responseSummary.length > 0 && responseSummary[4] ? responseSummary[4].number_of_orders : 0)
			}
			if (status === 6) {
				setLabel("Diterima")
				setIcon(finish)
				setSummaryCount(responseSummary.length > 0 && responseSummary[5] ? responseSummary[5].number_of_orders : 0)
			}
			if (status === 7) {
				setLabel("Dibatalkan")
				setIcon(finish)
				setSummaryCount(responseSummary.length > 0 && responseSummary[6] ? responseSummary[6].number_of_orders : 0)
			}
		}, [])
		return (
			<a href="#" className={'a-history-status d-flex' + (selectedStatus === (status) ? ' a-history-status-selected' : null)} onClick={(e) => { e.preventDefault(); setSelectedStatus(status) }}>
				{label}&nbsp;<span className="ml-2"><Badge bg="secondary">{summaryCount}</Badge></span>
			</a>
		)
	}
	return (
		<div className="App">
			<Header />
			<div className="app2">
				<Sidebar />
				<Container className="text-start p-4">
					<h5><strong>Daftar Transaksi</strong></h5>
					<div className="mt-3">
						{/* <Row className="mb-4 d-flex justify-content-start">
							<RowStatus status={1} status_label="Menunggu Barang Dikirim" />
							<RowStatus status={2} status_label="Menunggu Barang Dikirim" />
							<RowStatus status={3} status_label="Menunggu Barang Dikirim" />
							<RowStatus status={4} status_label="Menunggu Barang Dikirim" />
							<RowStatus status={5} status_label="Menunggu Barang Dikirim" />
							<RowStatus status={6} status_label="Menunggu Barang Dikirim" />
						</Row> */}

						<Row className="mb-4">
							<Col md={3} sm={12}>
								<div className="input-group mb-3">
									<input type="search" className="form-control" disabled={isLoadingShipment} placeholder="Cari transaksimu disini" value={search} onChange={(e) => setSearch(e.target.value)} />
									<button className="btn btn-outline-secondary" type="button" id="button-addon2" onClick={() => { onSearch() }}>
										<FontAwesomeIcon icon={faSearch} />
									</button>
								</div>
							</Col>
							{isBrowser ?
								<Col sm={12} md={12} className="mt-2 wrapper-history-status d-flex align-items-center">
									<strong className="">Status: </strong>&nbsp;&nbsp;

									<RowStatus2 status={0} />
									<RowStatus2 status={1} />
									<RowStatus2 status={2} />
									<RowStatus2 status={3} />
									<RowStatus2 status={4} />
									<RowStatus2 status={5} />
									<RowStatus2 status={6} />
									<RowStatus2 status={7} />
								</Col>
								:
								<Col sm={12} md={12} className="mt-2">
									<strong className="wrapper-history-status-mobile">Status: </strong><br />
									<div style={{
										overflow: 'auto',
										display: 'flex',
										whiteSpace: 'nowrap'	
									}}>
										<RowStatus2 status={0} />
										<RowStatus2 status={1} />
										<RowStatus2 status={2} />
										<RowStatus2 status={3} />
										<RowStatus2 status={4} />
										<RowStatus2 status={5} />
										<RowStatus2 status={6} />
										<RowStatus2 status={7} />
									</div>
								</Col>
							}
						</Row>

						{isLoadingShipment ?
							<div className="text-center">
								<Spinner
									as="span"
									animation="border"
									size="sm"
									role="status"
									aria-hidden="true"
								/> &nbsp;</div>
							: null}
						{shipmentList.map((v, i, a) => {
							return (
								<HistoryRow key={'htr' + v.id} data={v} />
							)
						})}
						{!isLoadingShipment && isHasLoadMore ?
							<div className="d-flex justify-content-center">
								<button className="btn btn-sm btn-secondary" type="button" onClick={() => fetchLoadMore(search, selectedStatus)}>
									Tampilkan Lebih Banyak <FontAwesomeIcon icon={faChevronDown} />
								</button>
							</div>
							: null}

						{shipmentList.length === 0 && isEmpty && !isLoadingShipment ?
							<EmptyIcon text="Tidak ada transaksi ditemukan..." />
							: null}
					</div>
				</Container>
			</div>
			<Footer />
			<BottomNavigationAlt active={2} />
		</div>

	)
}