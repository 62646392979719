import 'bootstrap/dist/css/bootstrap.min.css';
import Header from './components/Header';
import { Container, Image, Row, Col, Spinner, Form } from 'react-bootstrap';
import React, { useRef, useState, useEffect } from 'react';
import Footer from './components/Footer';
import { Formik, Form as FormFormik } from 'formik';
import { useRequestchangepassword, useRequestresetpassword } from './libs/hooks';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { BottomNavigationAlt, InputPassword } from './components/alt';
import Helper from './libs/helper';
import { useHistory } from 'react-router';


function Forgot() {

	const [{ responseResetpassword, isLoadingResetpassword, errorResetpassword }, fetchResetpassword] = useRequestresetpassword()
	const [{ responseChangepassword, isLoadingChangepassword, errorChangePassword }, fetchChangepassword] = useRequestchangepassword()
	const formForgotPassword = useRef();
	const formResetPassword = useRef();
	const history = useHistory();
	const [userEmail, setUserEmail] = useState(null);

	useEffect(() => {
		if (Helper.guard()) Helper.redirectTo('')
	}, [])

	useEffect(() => {
		if (!responseResetpassword) {
			setUserEmail(null)
			return;
		}

	}, [responseResetpassword])
	useEffect(() => {
		if (!responseChangepassword) {
			setUserEmail(null)
			return;
		}
		setTimeout(() => {
			Helper.redirectTo("login")
		}, 3000)

	}, [responseChangepassword])

	const validate = (values) => {
		const errors = {};
		if (!values.email) errors.email = "Required"
		return errors;
	};

	const onSubmit = (data, { setFieldError }) => {
		// alert('login')
		setUserEmail(data.email)
		fetchResetpassword({
			email: data.email,
		}, setFieldError)
	}

	

	return (
		<div className="App">
			<Header />
			<Container className="p-4">

				<h3>Lupa Password</h3>

				<Row className="justify-content-center">
					<Col sm={12} md={5} className="border shadow p-4 text-start" style={{ borderRadius: 20 }}>

						<Formik innerRef={formForgotPassword} initialValues={{
							email: "",
						}} onSubmit={onSubmit} validate={validate} validateOnChange={false} validateOnBlur={false} novalidate className="needs-validation was-validated">
							{({ handleChange, handleBlur, handleSubmit, values, errors }) => (
								<FormFormik>
									<div className="text-center mb-4" style={{ color: 'grey' }}>
										Silahkan masukkan email yang terdaftar!
									</div>
									{responseResetpassword ?
										<div className="alert alert-success">
											{responseResetpassword ? responseResetpassword.status.message : '-'}
										</div> : null}
									{errorResetpassword ?
										<div className="alert alert-danger">
											{errorResetpassword ? errorResetpassword.status.message : '-'}
										</div>
										: null}
									<div className="grey-600 mb-3">
										Sudah ingat password? <a href="#" className="text-primary" onClick={() => Helper.routeTo(history, '/login')}>Masuk di sini!</a>
									</div>
									<div className="form-group mb-3">
										<Form.Control
											type="text"
											placeholder="Email"
											aria-describedby="inputGroupPrepend"
											name="email"
											size="lg"
											value={values.email}
											onChange={handleChange}
											isInvalid={!!errors.email}
										/>
										<div className="invalid-feedback">
											{errors.email}
										</div>
									</div>

									<div className="d-grid mt-3">
										<button type="submit" className="btn btn-primary btn-block btn-lg bg-secondary" disabled={isLoadingResetpassword}>
											{isLoadingResetpassword ?
												<>
													<Spinner
														as="span"
														animation="border"
														size="sm"
														role="status"
														aria-hidden="true"
													/> &nbsp;</>
												: null}
											Submit
										</button>
									</div>
								</FormFormik>
							)}
						</Formik>

					</Col>
				</Row>
			</Container>
			<Footer />
			<BottomNavigationAlt active={3} />
		</div>
	);
}

export default Forgot;
