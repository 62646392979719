import 'bootstrap/dist/css/bootstrap.min.css';
import Header from './components/Header';
import { Container, Image, Row, Col, Spinner, Form } from 'react-bootstrap';
import React, { useRef, useState, useEffect } from 'react';
import Footer from './components/Footer';
import { useLocation } from 'react-router-dom';
import { Formik, Form as FormFormik } from 'formik';
import { useAuthsso, useLogin, useProfile } from './libs/hooks';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { BottomNavigationAlt, ButtonSocialMediaLogin, InputPassword } from './components/alt';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import Helper from './libs/helper';
import { useHistory } from 'react-router';
import checkImage from './images/check-mark-verified.gif';



function EmailVerification() {
	const [{ responseProfile, isLoadingProfile, isLoadingUpdateProfile, responseRequestVerification, responseConfirmVerification, errorConfirmVerification }, fetchProfile, fetchRequestEmailVerification, fetchVerificationEmail] = useProfile();
	const useQuery = () => {
		return new URLSearchParams(useLocation().search);
	}
	const queryParams = useQuery()
	useEffect(() => {
		fetchVerificationEmail(queryParams.get('token'))
	}, [])
	return (
		<div className="App">
			<Header />
			<Container className="p-4" style={{ minHeight: '100%' }}>
				<h3>Verifikasi Email</h3>
				<Row className="justify-content-center">
					<Col sm={12} md={5} className="border shadow p-4 text-center" style={{ borderRadius: 20 }}>
						{isLoadingProfile ?
							<>
								<Spinner
									as="span"
									animation="border"
									size="sm"
									role="status"
									aria-hidden="true"
								/> &nbsp;</>
							: (responseConfirmVerification ?
								<div>
									<img src={checkImage} width={150} /> <br />
									Terima kasih telah melakukan verifikasi email.
								</div> : null
							)
						}
						{errorConfirmVerification ?
							errorConfirmVerification.status.message
							: null}
					</Col>
				</Row>
			</Container>
			<Footer />
			<BottomNavigationAlt active={3} />
		</div >
	);
}

export default EmailVerification;
