import React from 'react';
import { Col, Container, Image, Row } from 'react-bootstrap';
import ig from '../images/ig.svg';
import twitter from '../images/twitter.svg';
import fb from '../images/fb.svg';
import Helper from '../libs/helper';
import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect';
import { useHistory } from 'react-router';
export default function Footer() {
	const history = useHistory();
	return (
		<>
			<div className="pt-4 pb-4 bg-primary footer">
				<Container className="mt-4">
					<Row>
						<Col md={6} className="d-flex justify-content-start align-items-start flex-column mb-3">
							<div style={{ fontSize: '2em', fontWeight: 'bold', color: 'white' }}>SMALLPAKET.COM</div>
							<p className="text-start" style={{ color: "white", width: '100%' }}>
								Selamat datang di Smallpaket.com, sebuah platform digitalisasi proses ekspor dengan segala fasilitas yang membuat proses ekspor kamu selesai dalam one click.
							</p>
						</Col>
						<Col md={2} sm={12} className="d-flex justify-content-start align-items-start flex-column mb-3">
							<h5 style={{ color: 'white' }} className="mb-3 text-bold">Layanan</h5>
							<a href="#" style={{ color: 'white', textDecoration: 'none' }} onClick={(e) => { e.preventDefault(); Helper.routeTo(history, '/kebijakan-privasi') }} className="mb-2">Kebijakan Privasi</a>
							<a href="#" style={{ color: 'white', textDecoration: 'none' }} onClick={(e) => { e.preventDefault(); Helper.routeTo(history, '/syarat-ketentuan') }} className="mb-2">Syarat & Ketentuan</a>
						</Col>
						<Col md={4} sm={12} className="d-flex justify-content-start align-items-start flex-column mb-3">
							<h5 style={{ color: 'white' }} className="mb-3 text-bold">Kontak Kami</h5>
							<Row>
								<Col xs={12} className="text-white text-start">
									<strong>Alamat</strong>
								</Col>
								<Col xs={12} className="text-start text-white mb-2">
										Jl. Raya Pondok Duta Raya No. 17 RT 10 RW 8, Kel. Tugu, Kec. Cimanggis, Kota Depok, Jawa Barat 16451
								</Col>
								<Col xs={12} className="text-white text-start">
									<strong>Email:</strong>
								</Col>
								<Col xs={12} className="text-start text-white mb-2">
									<a href="mailto:smallpaket@gmail.com" className="text-white">
										smallpaket@gmail.com
									</a>
								</Col>
								<Col xs={12} className="text-white text-start">
									<strong>Telepon:</strong>
								</Col>
								<Col xs={12} className="text-start text-white mb-2">
									021 87732105
								</Col>
							</Row>
						</Col>
					</Row>
				</Container>
				<hr style={{ color: 'white', border: '2px' }} />
				<Container style={{
					marginBottom: isMobile ? 50 : 0
				}}>
					<Row>
						<Col md={6} sm={12} style={{ color: 'white' }} className="text-md-start text-sm-center">
							© 2021 Copyright smallpaket.com. {isMobile?<br />:null}All Rights Reserved.
						</Col>
						<Col md={6} sm={12} className="text-md-end text-sm-center">
							<div className="text-right">
								{/* <a href="https://www.instagram.com/smallpaket.id/" className="pr-3">
								<Image src={fb} alt="fb" />
							</a>&nbsp;&nbsp;
							<a href="https://instragram.com" className="pr-3">
								<Image src={twitter} alt="twitter" />
							</a>&nbsp;&nbsp; */}
								{!isMobile ?
									<a href="https://www.instagram.com/smallpaket.id/">
										<Image src={ig} alt="ig" />
									</a>
									: null}
							</div>
						</Col>
					</Row>
				</Container>
			</div>
		</>
	);
}
