import axios from 'axios';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import Helper from './helper';

const URLV1 = process.env.REACT_APP_API_HOST_URL + process.env.REACT_APP_API_V1;


export const useRegister = () => {
	const [url, setUrl] = useState(URLV1 + "/auth/register")
	const [isLoadingRegister, setIsLoadingRegister] = useState(false);
	const [responseRegister, setResponseRegister] = useState(null)
	useEffect(() => {
		// alert(URLV1)
	}, [])

	const fetchRegister = async (data, setFieldError) => {
		setResponseRegister(null)
		setIsLoadingRegister(true)
		try {
			const res = await axios.post(url, data, {
				"Content-Type": "application/json"
			})
			setResponseRegister(res.data)
		} catch (e) {
			Helper.fetchError(url, e, setFieldError)
		}
		setIsLoadingRegister(false)
	}

	return [{ responseRegister, isLoadingRegister }, fetchRegister]
}

export const useLogin = () => {
	const [url, setUrl] = useState(URLV1 + "/auth/login")
	const [isLoadingLogin, setIsLoadingLogin] = useState(false);
	const [responseLogin, setResponseLogin] = useState(null)
	const [errorLogin, setErrorLogin] = useState(null)
	useEffect(() => {
		// alert(URLV1)
	}, [])

	const fetchLogin = async (data, setFieldError) => {
		setResponseLogin(null)
		setIsLoadingLogin(true)
		setErrorLogin(null)
		try {
			const res = await axios.post(url, data, {
				"Content-Type": "application/json"
			})
			Helper.set('accessToken', res.data.data.credentials.access_token);
			Helper.set('tokenUpdateAt', Math.floor(Date.now() / 1000));
			setResponseLogin(res.data)
		} catch (e) {
			// Helper.fetchError(url, e, setFieldError)
			setErrorLogin(e)
		}
		setIsLoadingLogin(false)
	}

	return [{ responseLogin, isLoadingLogin, errorLogin }, fetchLogin]
}

export const useRequestresetpassword = () => {
	const [url, setUrl] = useState(URLV1 + "/auth/forgotPassword")
	const [isLoadingResetpassword, setIsLoadingResetpassword] = useState(false);
	const [responseResetpassword, setResponseResetpassword] = useState(null)
	const [errorResetpassword, setErrorResetpassword] = useState(null)

	const fetchResetpassword = async (data, setFieldError) => {
		setResponseResetpassword(null)
		setErrorResetpassword(null)
		setIsLoadingResetpassword(true)
		try {
			const res = await axios.post(url, data, {
				"Content-Type": "application/json"
			})
			setResponseResetpassword(res.data)
			// setResponseResetpassword(data.email)
		} catch (e) {
			// console.log(e.response.data)
			// Helper.fetchError(url, e, setFieldError)
			// setErrorLogin(e)
			setErrorResetpassword(e.response.data)
		}
		setIsLoadingResetpassword(false)
	}

	return [{ responseResetpassword, isLoadingResetpassword, errorResetpassword }, fetchResetpassword]
}

export const useRequestchangepassword = () => {
	const [url, setUrl] = useState(URLV1 + "/auth/confirmForgotPassword")
	const [isLoadingChangepassword, setIsLoadingChangepassword] = useState(false);
	const [responseChangepassword, setResponseChangepassword] = useState(null)
	const [errorChangePassword, setErrorResetpassword] = useState(null);

	const fetchChangepassword = async (data, setFieldError) => {
		setResponseChangepassword(null)
		setIsLoadingChangepassword(true)
		setErrorResetpassword(null)
		try {
			const res = await axios.post(url, data, {
				"Content-Type": "application/json"
			})
			setResponseChangepassword(res.data)
		} catch (e) {
			// Helper.fetchError(url, e, setFieldError)
			setErrorResetpassword(e.response.data)
			// setErrorLogin(e)
		}
		setIsLoadingChangepassword(false)
	}

	return [{ responseChangepassword, isLoadingChangepassword, errorChangePassword }, fetchChangepassword]
}

export const checkRefreshToken = async () => {

	try {
		if (Helper.getIsNeedRefreshToken()) {
			const res = await axios.post(URLV1 + "/auth/refresh", {}, {
				headers: {
					"Accept": "application/json",
					"Content-Type": "application/json",
					"Authorization": "Bearer " + Helper.get('accessToken')
				}
			})
			Helper.set('accessToken', res.data.data.access_token);
			Helper.set('tokenUpdateAt', Math.floor(Date.now() / 1000));
		}
	} catch (e) {
		// alert("Sesi Anda telah habis.")
		Helper.logout();
	}
}

export const useProfile = () => {
	const [url, setUrl] = useState(URLV1 + "/auth/profile")
	const [urlVerification, setUrlVerification] = useState(URLV1 + "/auth/requestEmailVerification")
	const [urlConfirmVerification, setUrlConfirmVerification] = useState(URLV1 + "/auth/confirmEmailVerification")
	const [isLoadingProfile, setIsLoadingProfile] = useState(false);
	const [isLoadingUpdateProfile, setIsLoadingUpdateProfile] = useState(false);
	const [responseProfile, setResponseProfile] = useState(null)
	const [responseRequestVerification, setResponseRequestVerification] = useState(null)
	const [responseConfirmVerification, setResponseConfirmVerification] = useState(null)
	const [errorConfirmVerification, setErrorConfirmVerification] = useState(null)

	const fetchProfile = async () => {
		await checkRefreshToken();
		setIsLoadingProfile(true)
		try {
			const res = await axios.get(url, {
				headers: {
					"Content-Type": "application/json",
					"Authorization": "Bearer " + Helper.get('accessToken')
				}
			})
			setResponseProfile(res.data)
			Helper.set('profile', JSON.stringify(res.data))
		} catch (e) {
			// Helper.fetchError(url, e, setFieldError)
		}
		setIsLoadingProfile(false)
	}

	const fetchRequestEmailVerification = async () => {
		await checkRefreshToken();
		setIsLoadingProfile(true)
		try {
			const res = await axios.get(urlVerification, {
				headers: {
					"Content-Type": "application/json",
					"Authorization": "Bearer " + Helper.get('accessToken')
				}
			})
			setResponseRequestVerification(res.data);
		} catch (e) {
			// Helper.fetchError(url, e, setFieldError)
		}
		setIsLoadingProfile(false)
	}

	const fetchVerificationEmail = async (token) => {
		await checkRefreshToken();
		setIsLoadingProfile(true)
		try {
			const res = await axios.post(urlConfirmVerification, {
				token: token
			}, {
				headers: {
					"Content-Type": "application/json",
					"Authorization": "Bearer " + Helper.get('accessToken')
				}
			})
			fetchProfile()
			setResponseConfirmVerification(res.data);
		} catch (e) {
			// Helper.fetchError(url, e, setFieldError)
			setErrorConfirmVerification(e.response.data)
		}
		setIsLoadingProfile(false)
	}



	useEffect(() => {
		if (Helper.guard()) {
			fetchProfile()
		}
	}, [])

	return [{ responseProfile, isLoadingProfile, isLoadingUpdateProfile, responseRequestVerification, responseConfirmVerification, errorConfirmVerification }, fetchProfile, fetchRequestEmailVerification, fetchVerificationEmail]
}

export const useUpdateprofile = () => {
	const [url, setUrl] = useState(URLV1 + "/auth/profile")
	const [urlAvatar, setUrlAvatar] = useState(URLV1 + "/auth/avatar")
	const [isLoadingUpdateProfile, setIsLoadingUpdateProfile] = useState(false);
	const [responseUpdateProfile, setResponseUpdateProfile] = useState(null)

	const saveProfile = async (data, setFieldError) => {
		try {
			const res = await axios.post(url, data, {
				headers: {
					"Content-Type": "application/json",
					"Authorization": "Bearer " + Helper.get('accessToken')
				}
			})
			// fetchProfile()
			setResponseUpdateProfile(res.data)
		} catch (e) {
			Helper.fetchError(url, e, setFieldError)
		}
	}

	const updateProfile = async (data, setFieldError) => {
		// alert('update')
		await checkRefreshToken();
		setIsLoadingUpdateProfile(true)
		if (data.avatar_new) {
			//upload first
			// alert('upload')
			try {
				var formData = new FormData();
				formData.append('avatar', data.avatar_new)
				const res = await axios.post(urlAvatar, formData, {
					headers: {
						"Content-Type": "multipart/form-data",
						"Authorization": "Bearer " + Helper.get('accessToken')
					}
				})
				let profileData = data;
				profileData.avatar = res.data.data;
				await saveProfile(profileData, setFieldError);
			} catch (e) {
				Helper.fetchError(url, e, setFieldError)
			}
		} else {
			await saveProfile(data, setFieldError);
		}
		setIsLoadingUpdateProfile(false)
	}

	return [{ responseUpdateProfile, isLoadingUpdateProfile }, updateProfile]
}

export const useUpdatepassword = () => {
	const [url, setUrl] = useState(URLV1 + "/auth/changePassword")
	const [isLoadingUpdatePassword, setIsLoadingUpdatePassword] = useState(false);
	const [responseUpdatePassword, setResponseUpdatePassword] = useState(null)

	const updatePassword = async (data, setFieldError) => {
		// alert('update')
		await checkRefreshToken();
		setIsLoadingUpdatePassword(true)
		try {
			const res = await axios.post(url, data, {
				headers: {
					"Content-Type": "application/json",
					"Authorization": "Bearer " + Helper.get('accessToken')
				}
			})
			// fetchProfile()
			setResponseUpdatePassword(res.data)
		} catch (e) {
			Helper.fetchError(url, e, setFieldError)
		}
		setIsLoadingUpdatePassword(false)
	}

	return [{ responseUpdatePassword, isLoadingUpdatePassword }, updatePassword]
}

export const useGetkategori = () => {
	const [url, setUrl] = useState(URLV1 + "/master/category")
	const [isLoadingKategori, setIsLoadingKategori] = useState(false);
	const [responseKategori, setResponseKategori] = useState([])

	const fetchKategori = async () => {
		// alert('update')
		await checkRefreshToken();
		setIsLoadingKategori(true)
		try {
			const res = await axios.get(url, {
				headers: {
					"Content-Type": "application/json",
					"Authorization": "Bearer " + Helper.get('accessToken')
				}
			})
			setResponseKategori(res.data.data.data)
		} catch (e) {
			console.error(url, e)
		}
		setIsLoadingKategori(false)
	}

	useEffect(() => {
		fetchKategori()
	}, [])

	return [{ responseKategori, isLoadingKategori }, fetchKategori]
}


export const useJenisbarang = () => {
	const [url, setUrl] = useState(URLV1 + "/master/jenisBarang/category")
	const [isLoadingJenisbarang, setIsLoadingJenisbarang] = useState(false);
	const [responseJenisbarang, setResponseJenisbarang] = useState([])

	const fetchJenisbarang = async (kategori_id) => {
		// alert('update')
		await checkRefreshToken();
		setIsLoadingJenisbarang(true)
		try {
			const res = await axios.get(url, {
				headers: {
					"Content-Type": "application/json",
					"Authorization": "Bearer " + Helper.get('accessToken')
				}
			})
			setResponseJenisbarang(res.data.data.data)
		} catch (e) {
			console.error(url, e)
		}
		setIsLoadingJenisbarang(false)
	}

	useEffect(() => {
		fetchJenisbarang()
	}, [])

	return [{ responseJenisbarang, isLoadingJenisbarang }, fetchJenisbarang]
}

export const useLogout = () => {
	const [url, setUrl] = useState(URLV1 + "/auth/logout")
	const [isLoadingLogout, setIsLoadingLogout] = useState(false);
	const [responseLogout, setResponseLogout] = useState(null)

	const fetchLogout = async () => {
		setIsLoadingLogout(true)
		try {
			const res = await axios.post(url, {}, {
				headers: {
					"Accept": "application/json",
					"Content-Type": "application/json",
					"Authorization": "Bearer " + Helper.get('accessToken')
				}
			})
			setResponseLogout(res.data)
			Helper.logout();
		} catch (e) {
			Helper.logout();
			console.error(e)
			// Helper.fetchError(url, e, setFieldError)
		}
		setIsLoadingLogout(false)
	}

	return [{ responseLogout, isLoadingLogout }, fetchLogout]
}

export const useRefreshtoken = () => {
	const [url, setUrl] = useState(URLV1 + "/auth/refresh")
	const [isLoadingRefreshToken, setIsLoadingRefreshToken] = useState(false);
	const [responseRefreshToken, setResponseRefreshToken] = useState(null)

	const fetchRefreshToken = async () => {
		setIsLoadingRefreshToken(true)
		try {
			const res = await axios.post(url, {}, {
				headers: {
					"Accept": "application/json",
					"Content-Type": "application/json",
					"Authorization": "Bearer " + Helper.get('accessToken')
				}
			})
			setResponseRefreshToken(res.data)
			Helper.set('accessToken', res.data.data.access_token);
			Helper.set('tokenUpdateAt', Math.floor(Date.now() / 1000));
		} catch (e) {
			// alert("Sesi Anda telah habis.")
			Helper.logout();
			console.error(e)
		}
		setIsLoadingRefreshToken(false)
	}

	return [{ isLoadingRefreshToken, responseRefreshToken }, fetchRefreshToken]
}

export const useCalculateprice = () => {
	const [url, setUrl] = useState(URLV1 + "/master/deliveryPrice")
	const [isLoadingCalculatePrice, setIsLoadingCalculatePrice] = useState(false);
	const [responseCalculatePrice, setResponseCalculatePrice] = useState(null);
	const [errorCalculatePrice, setErrorCalculatePrice] = useState(null);

	const countingIfAnonymous = async () => {
		setErrorCalculatePrice(null)
		if (Helper.guard()) {
			await checkRefreshToken();
			return true;
		}
		let currentCount = Helper.get('countingAnonymous')

		if (!currentCount || isNaN(currentCount)) {
			Helper.set('countingAnonymous', 1);
			return true;
		}
		currentCount = parseInt(currentCount) + 1;
		Helper.set('countingAnonymous', currentCount);
		if (currentCount <= 3) {
			return true;
		}
		setResponseCalculatePrice(null)
		return false;
	}

	const fetchCalculatePrice = async (data) => {
		let isCanSubmit = await countingIfAnonymous()
		if (isCanSubmit) {
			setIsLoadingCalculatePrice(true)
			setResponseCalculatePrice(null)
			setErrorCalculatePrice(null);
			try {
				const params = new URLSearchParams(data).toString();
				const res = await axios.get(url + "?" + params, {
					headers: {
						"Content-Type": "application/json"
					}
				})
				let tmp = res.data;
				// console.log(tmp)
				tmp.data.sort((a, b) => a.price - b.price);
				setResponseCalculatePrice(tmp)
			} catch (e) {
				console.error(url, e);
				setErrorCalculatePrice("Not Found")
			}
			setIsLoadingCalculatePrice(false)
		} else {
			Helper.redirectTo('login')
			// setErrorCalculatePrice("Your account has reach limit. Please Login!");
		}
	}
	const resetCalculatePrice = () => {
		setResponseCalculatePrice(null)
	}

	return [{ isLoadingCalculatePrice, responseCalculatePrice, errorCalculatePrice }, fetchCalculatePrice, resetCalculatePrice];
}

export const useGetshipper = () => {
	const [url, setUrl] = useState(URLV1 + "/shipper")
	const [isLoadingShipper, setIsLoadingShipper] = useState(false)
	const [isEmptyShipper, setIsEmpty] = useState(false)
	const [shipperList, setShipperList] = useState([])
	const [isHasLoadMore, setIsHasLoadMore] = useState(false)
	const [currentPage, setCurrentPage] = useState(1)

	const fetchGetShipper = async (search = null, resetList = true, page = 1) => {
		await checkRefreshToken();
		setIsEmpty(false)
		setIsLoadingShipper(true)
		let data = shipperList.data;
		if (resetList) setShipperList([]);

		let params = {
			orderBy: 'default',
			sortedBy: 'desc',
			page: page,
			per_page: 25,
		}
		if (search) params.search = search;
		let queryParams = new URLSearchParams(params)
		try {
			const res = await axios.get(url + "?" + queryParams, {
				headers: {
					"Content-Type": "application/json",
					"Authorization": "Bearer " + Helper.get('accessToken')
				}
			})
			if (resetList) setShipperList(res.data.data.data)
			else {
				res.data.data.data.map((v, i, a) => {
					setShipperList([...shipperList, v])

				})
			}
			setCurrentPage(res.data.data.meta.pagination.current_page)
			if (res.data.data.meta.pagination.total_pages > res.data.data.meta.pagination.current_page) {
				setIsHasLoadMore(true);
			} else {
				setIsHasLoadMore(false);
			}
			if (res.data.data.data.length === 0) {
				setIsEmpty(true)
			}
		} catch (e) {
			alert(e)
		}
		setIsLoadingShipper(false)
	}

	const fetchDeleteShipper = async (row, showConfirm = true) => {
		await checkRefreshToken();
		let state = showConfirm ? false : true;
		if (!state) {
			if (confirm("Apakah Anda yakin?")) {
				state = true;
			}
		}
		if (state) {
			setIsLoadingShipper(true)
			try {
				const res = await axios.delete((url + "/" + row.id), {
					headers: {
						"Content-Type": "application/json",
						"Authorization": "Bearer " + Helper.get('accessToken')
					}
				})
				fetchGetShipper();
			} catch (e) {
				alert(e)
			}
			setIsLoadingShipper(false)
		}
	}

	const setDefaultShipper = async (id) => {
		await checkRefreshToken();
		setIsLoadingShipper(true)
		try {
			const res = await axios.patch(url + "/default/" + id, {}, {
				headers: {
					"Content-Type": "application/json",
					"Authorization": "Bearer " + Helper.get('accessToken')
				}
			})
			fetchGetShipper()
		} catch (e) {
			console.error(url, e);
			// setErrorConsignee(e)
			alert(e)
		}
		setIsLoadingShipper(false)
	}

	const fetchLoadMore = async (search) => {
		setCurrentPage(currentPage + 1)
		fetchGetShipper(search, false, currentPage + 1);
	}

	useEffect(() => {
		fetchGetShipper()
	}, [])
	return [{ shipperList, isLoadingShipper, isEmptyShipper, isHasLoadMore }, fetchGetShipper, fetchDeleteShipper, setDefaultShipper, fetchLoadMore];
}

export const useQuery = () => {
	return new URLSearchParams(useLocation().search);
}

export const useGetinsurance = () => {
	const [url, setUrl] = useState(URLV1 + "/shipment/insurance");
	const [rateInsurance, setRateInsurance] = useState(0)

	const fetchGetInsurance = async () => {
		await checkRefreshToken();
		setRateInsurance(0)
		try {
			const res = await axios.get(url, {
				headers: {
					"Content-Type": "application/json",
					"Authorization": "Bearer " + Helper.get('accessToken')
				}
			})
			setRateInsurance(res.data.data.rate)
		} catch (e) {
			alert(e)
		}
	}

	useEffect(() => {
		fetchGetInsurance();
	}, [])

	return [{ rateInsurance }, fetchGetInsurance];
}

export const usePostshipper = () => {
	const [url, setUrl] = useState(URLV1 + "/shipper")
	const [isLoadingPostShipper, setIsLoadingShipper] = useState(false)
	const [responseShipper, setResponseShipper] = useState(null)
	const [errorShipper, setErrorShipper] = useState(null);

	const fetchPostShipper = async (data) => {
		await checkRefreshToken();
		setIsLoadingShipper(true)
		setResponseShipper(null)
		try {
			const params = new URLSearchParams(data).toString();
			const res = await axios.post(url, data, {
				headers: {
					"Content-Type": "application/json",
					"Authorization": "Bearer " + Helper.get('accessToken')
				}
			})
			setResponseShipper(res.data)
		} catch (e) {
			console.error(url, e);
			setErrorShipper(e);
			// alert(e)
		}
		setIsLoadingShipper(false)
	}

	const fetchPutShipper = async (data, id) => {
		await checkRefreshToken();
		setIsLoadingShipper(true)
		setResponseShipper(null)
		try {
			const res = await axios.put(url + "/" + id, data, {
				headers: {
					"Content-Type": "application/json",
					"Authorization": "Bearer " + Helper.get('accessToken')
				}
			})
			setResponseShipper(res.data)
		} catch (e) {
			console.error(url, e);
			setErrorShipper(e);
			alert(e)
		}
		setIsLoadingShipper(false)
	}

	return [{ responseShipper, isLoadingPostShipper, errorShipper }, fetchPostShipper, fetchPutShipper]
}

export const usePostconsignee = () => {
	const [url, setUrl] = useState(URLV1 + "/consignee")
	const [isLoadingPostConsignee, setIsLoadingConsignee] = useState(false)
	const [responseConsignee, setResponseConsignee] = useState(null)
	const [errorConsignee, setErrorConsignee] = useState(null);

	const fetchPostConsignee = async (data) => {
		await checkRefreshToken();
		setIsLoadingConsignee(true)
		setResponseConsignee(null)
		try {
			const params = new URLSearchParams(data).toString();
			const res = await axios.post(url, data, {
				headers: {
					"Content-Type": "application/json",
					"Authorization": "Bearer " + Helper.get('accessToken')
				}
			})
			setResponseConsignee(res.data)
		} catch (e) {
			console.error(url, e);
			setErrorConsignee(e)
			// alert(e)
		}
		setIsLoadingConsignee(false)
	}

	const fetchPutConsignee = async (data, id) => {
		await checkRefreshToken();
		setIsLoadingConsignee(true)
		setResponseConsignee(null)
		try {
			const res = await axios.put(url + "/" + id, data, {
				headers: {
					"Content-Type": "application/json",
					"Authorization": "Bearer " + Helper.get('accessToken')
				}
			})
			setResponseConsignee(res.data)
		} catch (e) {
			console.error(url, e);
			setErrorConsignee(e)
			alert(e)
		}
		setIsLoadingConsignee(false)
	}




	return [{ responseConsignee, isLoadingPostConsignee, errorConsignee }, fetchPostConsignee, fetchPutConsignee]
}

export const useGetconsignee = () => {
	const [url, setUrl] = useState(URLV1 + "/consignee")
	const [isLoadingConsignee, setIsLoadingConsignee] = useState(false)
	const [consigneeList, setConsigneeList] = useState([])
	const [isEmptyConsignee, setIsEmpty] = useState(false)
	const [isHasLoadMore, setIsHasLoadMore] = useState(false)
	const [currentPage, setCurrentPage] = useState(1);

	const fetchGetConsignee = async (search = null, resetList = true, page = 1) => {
		await checkRefreshToken();
		setIsLoadingConsignee(true)
		setConsigneeList([]);
		setIsEmpty(false)
		try {
			let params = {
				orderBy: 'default',
				sortedBy: 'desc',
				page: page,
				per_page: 25
			}
			if (search) params.search = search;
			let queryParams = new URLSearchParams(params)
			const res = await axios.get(url + "?" + queryParams, {
				headers: {
					"Content-Type": "application/json",
					"Authorization": "Bearer " + Helper.get('accessToken')
				}
			})
			if (resetList) setConsigneeList(res.data.data.data)
			else {
				res.data.data.data.map((v, i, a) => {
					setConsigneeList([...consigneeList, v])
				})
			}
			setCurrentPage(res.data.data.meta.pagination.current_page)
			if (res.data.data.meta.pagination.total_pages > res.data.data.meta.pagination.current_page) {
				setIsHasLoadMore(true);
			} else {
				setIsHasLoadMore(false);
			}
			if (res.data.data.data.length === 0) {
				setIsEmpty(true)
			}
		} catch (e) {
			alert(e)
		}
		setIsLoadingConsignee(false)
	}

	const fetchDeleteConsignee = async (row, showConfirm = true) => {
		await checkRefreshToken();
		let state = showConfirm ? false : true;
		if (!state) {
			if (confirm("Apakah Anda yakin?")) {
				state = true;
			}
		}
		if (state) {
			setIsLoadingConsignee(true)
			try {
				const res = await axios.delete((url + "/" + row.id), {
					headers: {
						"Content-Type": "application/json",
						"Authorization": "Bearer " + Helper.get('accessToken')
					}
				})
				fetchGetConsignee();
			} catch (e) {
				alert(e)
			}
			setIsLoadingConsignee(false)
		}
	}

	const setDefaultConsignee = async (id) => {
		await checkRefreshToken();
		setIsLoadingConsignee(true)
		try {
			const res = await axios.patch(url + "/default/" + id, {}, {
				headers: {
					"Content-Type": "application/json",
					"Authorization": "Bearer " + Helper.get('accessToken')
				}
			})
			fetchGetConsignee()
		} catch (e) {
			console.error(url, e);
			// setErrorConsignee(e)
			alert(e)
		}
		setIsLoadingConsignee(false)
	}

	const fetchLoadMore = async (search) => {
		setCurrentPage(currentPage + 1)
		fetchGetConsignee(search, false, currentPage + 1)
	}

	useEffect(() => {
		fetchGetConsignee()
	}, [])
	return [{ consigneeList, isLoadingConsignee, isEmptyConsignee, isHasLoadMore }, fetchGetConsignee, fetchDeleteConsignee, setDefaultConsignee, fetchLoadMore]
}


export const usePostshipment = () => {
	const [url, setUrl] = useState(URLV1 + "/shipment")
	const [isLoadingShipment, setIsLoadingShipment] = useState(false)
	const [responseShipment, setResponseShipment] = useState(null)
	const [errorShipment, setErrorShipment] = useState(null);

	const fetchPostShipment = async (data) => {
		await checkRefreshToken();
		setIsLoadingShipment(true)
		setResponseShipment(null)
		try {
			const params = new URLSearchParams(data).toString();
			const res = await axios.post(url, data, {
				headers: {
					"Content-Type": "application/json",
					"Authorization": "Bearer " + Helper.get('accessToken')
				}
			})
			setResponseShipment(res.data)
		} catch (e) {
			console.error(url, e);
			setErrorShipment(e)
			// alert(e)
		}
		setIsLoadingShipment(false)
	}

	return [{ responseShipment, isLoadingShipment, errorShipment }, fetchPostShipment]
}

export const useGetshipment = () => {
	const [url, setUrl] = useState(URLV1 + "/shipment")
	const [isLoadingShipment, setIsLoadingShipment] = useState(false)
	const [isEmpty, setIsEmpty] = useState(true)
	const [shipmentList, setShipmentList] = useState([])
	const [responseSummary, setResponseSummary] = useState([])
	const [isHasLoadMore, setIsHasLoadMore] = useState(false)
	const [currentPage, setCurrentPage] = useState(1)

	const fetchShipment = async (search = null, status = null, resetList = true, page = 1) => {
		await checkRefreshToken();
		if (resetList) setShipmentList([])
		let searchData = "";
		searchData += (search?`order_number:${search};`:'')
		searchData += (status>0?`status:${status}`:'')

		let params = {
			orderBy: 'id',
			sortedBy: 'desc',
			page: page,
			per_page: 25,
		}
		// if (search) params.search = search;
		let queryParams = new URLSearchParams(params)
		setIsLoadingShipment(true)
		try {
			const res = await axios.get(url + "?" + queryParams+"&search="+searchData, {
				headers: {
					"Content-Type": "application/json",
					"Authorization": "Bearer " + Helper.get('accessToken')
				}
			})
			// console.log(res.data.data.data)
			if (resetList) setShipmentList(res.data.data.data)
			else {
				res.data.data.data.map((v, i, a) => {
					setShipmentList([...shipmentList, v])
				})
			}
			setCurrentPage(res.data.data.meta.pagination.current_page)
			if (res.data.data.meta.pagination.total_pages > res.data.data.meta.pagination.current_page) {
				setIsHasLoadMore(true);
			} else {
				setIsHasLoadMore(false);
			}
			if (res.data.data.data.length === 0) {
				setIsEmpty(true)
			}

		} catch (e) {
			alert(e)
		}
		setIsLoadingShipment(false)
	}

	const fetchSummary = async () => {
		try {
			const res = await axios.get(url + "/summary", {
				headers: {
					"Content-Type": "application/json",
					"Authorization": "Bearer " + Helper.get('accessToken')
				}
			})
			setResponseSummary(res.data.data)
		} catch (e) {
			console.error(e)
		}
	}

	const fetchLoadMore = async (search, status = null) => {
		setCurrentPage(currentPage + 1)
		fetchShipment(search, status, false, currentPage + 1);
	}

	useEffect(() => {
		fetchShipment()
		fetchSummary();
	}, [])

	return [{ shipmentList, isLoadingShipment, responseSummary, isEmpty, isHasLoadMore }, fetchShipment, fetchLoadMore]
}

export const useGetshipmentdetail = () => {
	const [url, setUrl] = useState(URLV1 + "/shipment/")
	const [isLoadingShipment, setIsLoadingShipment] = useState(false)
	const [shipment, setShipment] = useState(null)
	const [trackings, setTracking] = useState([])

	const fetchShipment = async (id) => {
		await checkRefreshToken();
		setIsLoadingShipment(true)
		setTracking([])
		try {
			const res = await axios.get(url + id, {
				headers: {
					"Content-Type": "application/json",
					"Authorization": "Bearer " + Helper.get('accessToken')
				}
			})
			setShipment(res.data.data.data)
			if (res.data.data.data.trackings) {
				let _trackings = []
				res.data.data.data.trackings.map((v, i, a) => {
					let row = {
						text: v.description,
						ts: moment(v.created_at).toISOString()
					}
					_trackings.push(row)
				})
				setTracking(_trackings)
			}
		} catch (e) {
			alert(e)
		}
		setIsLoadingShipment(false)
	}

	return [{ shipment, isLoadingShipment, trackings }, fetchShipment]
}

export const useGetdocuments = () => {
	const [url, setUrl] = useState(URLV1 + "/master/jenisBarang/document/");
	const [responseDocument, setResponseDocument] = useState([])
	const [isLoadingDocument, setIsLoadingDocument] = useState(false)

	const fetchDocuments = async (jenisBarangId) => {
		await checkRefreshToken();
		setIsLoadingDocument(true)
		setResponseDocument([])
		try {
			const res = await axios.get(url + jenisBarangId + "?search=&orderBy=id&sortedBy=asc", {
				headers: {
					"Content-Type": "application/json",
					"Authorization": "Bearer " + Helper.get('accessToken')
				}
			})
			// console.log(res.data.data.data.documents)
			setResponseDocument(res.data.data.data.documents)
		} catch (e) {
			console.error('document', e);
			// alert(e)
		}
		setIsLoadingDocument(false)
	}

	return [{ responseDocument, isLoadingDocument }, fetchDocuments];
}

export const useGetpackages = () => {
	const [url, setUrl] = useState(URLV1 + "/master/packaging?search=&orderBy=id&sortedBy=asc");
	const [responsePackage, setResponsePackage] = useState([])
	const [isLoadingPackage, setIsLoadingPackage] = useState(false)

	const fetchPackages = async () => {
		await checkRefreshToken();
		setIsLoadingPackage(true)
		setResponsePackage([])
		try {
			const res = await axios.get(url, {
				headers: {
					"Content-Type": "application/json",
					"Authorization": "Bearer " + Helper.get('accessToken')
				}
			})
			// console.log(res.data.data.data.documents)
			setResponsePackage(res.data.data.data)
		} catch (e) {
			console.error(url, e);
			// alert(e)
		}
		setIsLoadingPackage(false)
	}

	return [{ responsePackage, isLoadingPackage }, fetchPackages];
}

export const useNotification = () => {
	const [url, setUrl] = useState(URLV1 + "/notification");
	const [responseNotification, setResponse] = useState([])
	const [responseReadNotification, setResponseRead] = useState(null)
	const [responseReadAll, setResponseReadAll] = useState(null);
	const [responseSummary, setResponseSummary] = useState(null)
	const [isLoadingNotification, setIsLoading] = useState(false)
	const [isEmptyNotification, setIsEmpty] = useState(false)
	const [isHasLoadMoreNotification, setIsHasLoadMore] = useState(false)
	const [currentPageNotification, setCurrentPage] = useState(1)

	const fetchNotification = async (search = null, resetList = true, page = 1) => {
		// await checkRefreshToken();
		if (Helper.guard()) {
			setIsEmpty(false)
			setIsLoading(true)
			let data = responseNotification.data;
			if (resetList) setResponse([]);

			let params = {
				orderBy: 'id',
				sortedBy: 'desc',
				page: page,
				per_page: 25,
			}
			if (search) params.search = search;
			let queryParams = new URLSearchParams(params)
			try {
				const res = await axios.get(url + "?" + queryParams, {
					headers: {
						"Content-Type": "application/json",
						"Authorization": "Bearer " + Helper.get('accessToken')
					}
				})
				if (resetList) setResponse(res.data.data.data)
				else {
					res.data.data.data.map((v, i, a) => {
						setResponse([...responseNotification, v])
					})
				}
				setCurrentPage(res.data.data.meta.pagination.current_page)
				if (res.data.data.meta.pagination.total_pages > res.data.data.meta.pagination.current_page) {
					setIsHasLoadMore(true);
				} else {
					setIsHasLoadMore(false);
				}
				if (res.data.data.data.length === 0) {
					setIsEmpty(true)
				}
			} catch (e) {
				// alert(e)
				console.error(e)
			}
			setIsLoading(false)
		}
	}

	const setReadNotification = async (id) => {
		await checkRefreshToken();
		setIsLoading(true);
		try {
			const res = await axios.put(url + "/read/" + id, {}, {
				headers: {
					"Content-Type": "application/json",
					"Authorization": "Bearer " + Helper.get('accessToken')
				}
			})
			setResponseRead(res.data)
			fetchSummary()
		} catch (e) {
			console.error(e);
		}
		setIsLoading(false);
	}

	const setReadAll = async () => {
		try {
			await checkRefreshToken();
			const res = await axios.patch(url + "/read/all", {}, {
				headers: {
					"Content-Type": "application/json",
					"Authorization": "Bearer " + Helper.get('accessToken')
				}
			})
			setResponseReadAll(res.data)
			fetchSummary()
			fetchNotification();

		} catch (e) {
			alert(e)
		}

	}

	const fetchSummary = async () => {
		try {
			await checkRefreshToken();
			const res = await axios.get(url + "/summary", {
				headers: {
					"Content-Type": "application/json",
					"Authorization": "Bearer " + Helper.get('accessToken')
				}
			})
			setResponseSummary(res.data.data);
		} catch (e) {
			console.error('_summaryNotification', e)
		}
	}

	const fetchLoadMoreNotification = async (search) => {
		setCurrentPage(currentPageNotification + 1);
		fetchNotification(search, false, currentPageNotification + 1)
	}

	useEffect(() => {
		if (Helper.guard()) {
			fetchNotification();
			fetchSummary()
		}
	}, [])
	return [{ responseNotification, isLoadingNotification, responseReadNotification, responseReadAll, responseSummary, isHasLoadMoreNotification }, fetchNotification, setReadNotification, setReadAll, fetchSummary, fetchLoadMoreNotification];
}

export const useBanner = () => {
	const [url, setUrl] = useState(URLV1 + "/master/banner")
	const [bannerList, setList] = useState([])
	const [isLoadingBanner, setIsLoading] = useState(false);
	const fetchBanner = async () => {
		setIsLoading(true)
		try {
			const res = await axios.get(url, {
				headers: {
					"Content-Type": "application/json",
				}
			})
			setList(res.data.data.data)
		} catch (e) {
			console.error(e)
		}
		setIsLoading(false)
	}
	useEffect(() => {
		fetchBanner()
	}, [])
	return [{ bannerList, isLoadingBanner }, fetchBanner];
}

export const useGetTracking = () => {
	const [url, setUrl] = useState(URLV1 + "/shipment/tracking/")
	const [tracking, setTracking] = useState([])
	const [isLoadingTracking, setIsLoading] = useState(false);
	const [isNotFound,setIsNotFound] = useState(false);
	const fetchTracking = async (resi_number) => {
		setIsLoading(true)
		setTracking(null);
		setIsNotFound(false);
		const newUrl = url + resi_number;
		try {
			await checkRefreshToken();
			const res = await axios.get(newUrl, {
				headers: {
					"Content-Type": "application/json",
					"Authorization": "Bearer " + Helper.get('accessToken')
				}
			})
			let data = res.data;
			if(data.data.data.status === "Dikirim") {
				data.status_number = 1;
			} else if(data.data.data.status === "Selesai") {
				data.status_number = 2;
			} else {
				data.status_number = 3;
			}
			setTracking(data)
		} catch (e) {
			console.error(e)
			setIsNotFound(true);
		}
		setIsLoading(false)
	}
	return [{ tracking, isLoadingTracking, isNotFound }, fetchTracking];
}

export const useAuthsso = () => {
	const [accessToken, setAccessToken] = useState()
	const [url, setUrl] = useState(URLV1 + "/auth/profile")
	const [isLoadingSSO, setIsLoading] = useState(false);
	const useQuery = () => {
		return new URLSearchParams(useLocation().search);
	}
	const queryParams = useQuery()

	const fetchProfile = async (token) => {
		setIsLoading(true)
		try {
			const res = await axios.get(url, {
				headers: {
					"Content-Type": "application/json",
					"Authorization": "Bearer " + token
				}
			})
			Helper.set('accessToken', token);
			Helper.set('tokenUpdateAt', Math.floor(Date.now() / 1000));
			Helper.set('profile', JSON.stringify(res.data))
			Helper.routeTo("/")
		} catch (e) {
			// Helper.fetchError(url, e, setFieldError)
			Helper.redirectTo("login")
		}
	}

	useEffect(() => {
		if (queryParams.get('access_token')) {
			fetchProfile(queryParams.get('access_token'))
		}
	}, [])

	return [accessToken, isLoadingSSO]
}