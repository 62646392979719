import React, { useEffect, useState } from 'react';
import Header from './components/Header'
import HeaderBackMobile from './components/HeaderBackMobile'
import Sidebar from './components/Sidebar'
import { Col, Collapse, Container, Image, Modal, Row, Spinner } from 'react-bootstrap';
import Footer from './components/Footer';
import { useParams } from 'react-router';
import { useGetshipmentdetail } from './libs/hooks';
import Helper from './libs/helper';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faChevronDown, faChevronUp, faCircle, faCircleNotch, faClock, faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import waiting from './images/waiting.png';
import waiting_grey from './images/waiting_grey.png';
import verifying from './images/verifying.png';
import verifying_grey from './images/verifying_grey.png';
import waiting_payment from './images/waiting_payment.png';
import waiting_payment_grey from './images/waiting_payment_grey.png';
import documents from './images/documents.png';
import documents_grey from './images/documents_grey.png';
import sent from './images/sent.png';
import sent_grey from './images/sent_grey.png';
import finish from './images/finish.png';
import finish_grey from './images/finish_grey.png';
import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect';
import { SelectWordingStatus } from './components/alt';
import Timeline from 'react-time-line';

export default function OrderDetail() {

	const [{ shipment, isLoadingShipment, trackings }, fetchShipment] = useGetshipmentdetail();
	const [collapseInformasi, setCollapseInformasi] = useState(true);
	const [collapseAll, setCollapseAll] = useState(true);
	const [collapseItem, setCollapseItem] = useState(true);
	const [collapseAddon, setCollapseAddon] = useState(true);
	const [collapsePrice, setCollapsePrice] = useState(true);
	const [volume, setVolume] = useState(0);
	const [salesPrice, setSalesPrice] = useState(0)
	const [documentPrice, setDocumentPrice] = useState(0)
	const [packagingPrice, setPackagingPrice] = useState(0)
	const [priceBeforeInsurance, setPriceBeforeInsurance] = useState(0)

	const styles = {
		title: {
			fontWeight: 'bold',
			fontSize: '.9em',
			color: '#333'
		},
		subtitle: {
			fontSize: '1.1em',
			color: '#333'
		},
	}
	const { id } = useParams()

	useEffect(() => {
		setCollapseItem(collapseAll)
		setCollapseInformasi(collapseAll)
		setCollapsePrice(collapseAll)
		setCollapseAddon(collapseAll)
	}, [collapseAll])

	useEffect(() => {
		if (!id) return;
		fetchShipment(id);
	}, [id])

	useEffect(() => {
		if (!shipment) return;
		try {
			setVolume(Helper.getVolume(shipment.long, shipment.tall, shipment.wide, shipment.quantity))
			setSalesPrice(shipment.sales_price)
			let totalPriceDokumen = 0;
			let totalPricePackaging = 0;
			shipment.addons.map((v, i, a) => {
				if (v.type === "DOCUMENT") {
					totalPriceDokumen += parseFloat(v.price);
				} else if (v.type === "PACKAGING") {
					totalPricePackaging += parseFloat(v.price)
				}
			})
			let subTotal = parseFloat(shipment.sales_price) + parseFloat(totalPriceDokumen) + parseFloat(totalPricePackaging)
			setDocumentPrice(totalPriceDokumen)
			setPackagingPrice(totalPricePackaging)
			setPriceBeforeInsurance(subTotal)
		} catch (e) {
			console.error(e)
		}
	}, [shipment])

	const RowStatus = ({ status, status_label }) => {
		const [label, setLabel] = useState("-")
		const [icon, setIcon] = useState(waiting);
		const [isCurrentStatus, setIsCurrentStatus] = useState(false)
		const [isDone, setIsDone] = useState(false)
		const [statusNo, setStatusNo] = useState(0)
		const [showModal, setShowModal] = useState(false);
		useEffect(() => {
			let statusIndex = Helper.getStatusIndex(status_label);
			let currentStatus = status === statusIndex;
			setIsCurrentStatus(currentStatus);
			setIsDone(statusIndex > status)
		}, [])
		useEffect(() => {
			if (status === 1) {
				setLabel("Menunggu Barang")
				setIcon(isCurrentStatus || isDone ? waiting : waiting_grey)
				setStatusNo(1)
			}
			if (status === 2) {
				setLabel("Validasi Pesanan")
				setIcon(isCurrentStatus || isDone ? verifying : verifying_grey)
				setStatusNo(2)
			}
			if (status === 3) {
				setLabel("Pembayaran")
				setIcon(isCurrentStatus || isDone ? waiting_payment : waiting_payment_grey)
				setStatusNo(3)
			}
			if (status === 4) {
				setLabel("Pengurusan Dokumen")
				setIcon(isCurrentStatus || isDone ? documents : documents_grey)
				setStatusNo(4)
			}
			if (status === 5) {
				setLabel("Dikirim")
				setIcon(isCurrentStatus || isDone ? sent : sent_grey)
				setStatusNo(5)
			}
			if (status === 6) {
				setLabel("Diterima")
				setIcon(isCurrentStatus || isDone ? finish : finish_grey)
				setStatusNo(6)
			}
		}, [isDone, isCurrentStatus])
		return (
			<>

				<Col md={3} xs={3} lg={2} className="mb-2">
					<div className={`border border-rounded ps-1 pe-1 pb-3 text-center ` + (isCurrentStatus ? ' order-status-border-current' : null)} style={{
						position: 'relative',
						minHeight: 200,
					}}>
						{isDone ?
							<FontAwesomeIcon icon={faCheckCircle} style={{
								position: 'absolute',
								right: 5,
								top: 5,
								color: '#fb8005'
							}} /> : null}
						<img src={icon} style={{ width: 50 }} className="mt-3" /> <br />
						<small className={`mt-2 text-bold ` + (isCurrentStatus || isDone ? 'text-orange' : 'text-grey')}>{label}</small><hr />
						{isCurrentStatus ?
							<button className="btn btn-sm btn-outline-secondary" type="button" onClick={() => setShowModal(true)}>
								<FontAwesomeIcon icon={faInfoCircle} />&nbsp;
								Info
							</button>
							:
							(isDone ?
								<small><i style={{ color: 'grey' }}>Selesai</i></small>
								:
								<small><i style={{ color: 'grey' }}>Pending</i></small>

							)
						}
					</div>
				</Col>
				<Modal show={showModal} onHide={() => setShowModal(false)}>
					<Modal.Header closeButton>
						<Modal.Title>{label}</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<SelectWordingStatus shipment={shipment} status_no={statusNo} />
					</Modal.Body>
					<Modal.Footer>
						<button className="btn btn-secondary" onClick={() => setShowModal(false)}>
							Oke
						</button>
					</Modal.Footer>
				</Modal>
			</>
		)
	}

	const StatusViewMobile = () => {
		const [label, setLabel] = useState("-")
		const [icon, setIcon] = useState(waiting);
		const [isDone, setIsDone] = useState(false)
		const [statusNo, setStatusNo] = useState(0)
		const [showModal, setShowModal] = useState(false);
		const [status,setStatus] = useState(Helper.getStatusIndex(shipment.status))
		
		useEffect(() => {
			if (status === 1) {
				setLabel("Menunggu Barang")
				setStatusNo(1)
			}
			if (status === 2) {
				setLabel("Validasi Pesanan")
				setStatusNo(2)
			}
			if (status === 3) {
				setLabel("Pembayaran")
				setStatusNo(3)
			}
			if (status === 4) {
				setLabel("Pengurusan Dokumen")
				setStatusNo(4)
			}
			if (status === 5) {
				setLabel("Dikirim")
				setStatusNo(5)
			}
			if (status === 6) {
				setLabel("Diterima")
				setStatusNo(6)
			}
		}, [isDone])
		return (
			<>
				<Row className="border border-rounded pt-3 ps-3 pe-1 pb-3 mb-4">
					<Col sm={12} className="d-flex justify-content-between">
						<span className="text-bold">{label}</span>
						<button className="btn btn-sm btn-secondary"  onClick={() => setShowModal(true)}>Detail</button>
					</Col>
				</Row>
				<Modal show={showModal} onHide={() => setShowModal(false)}>
					<Modal.Header closeButton>
						<Modal.Title>{shipment.status}</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<SelectWordingStatus shipment={shipment} status_no={statusNo} />
					</Modal.Body>
					<Modal.Footer>
						<button className="btn btn-secondary" onClick={() => setShowModal(false)}>
							Oke
						</button>
					</Modal.Footer>
				</Modal>
			</>
		)
	}

	const InfoTimeline = () => {
		const [showModalTimeline, setShowModalTimeline] = useState(false)
		return (
			<>
				{shipment.status && shipment.status != "Cancel"?
				<a href="#" className="text-orange" style={{ textDecoration: 'none' }} onClick={(e) => { e.preventDefault(); setShowModalTimeline(true) }}>
					<FontAwesomeIcon icon={faInfoCircle} /> Lihat Tracking
				</a>
				: null }
				<Modal show={showModalTimeline} onHide={() => setShowModalTimeline(false)}>
					<Modal.Header closeButton>
						<Modal.Title>Tracking</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<Timeline items={trackings} format="HH:mm" />
					</Modal.Body>
					<Modal.Footer>
						<button className="btn btn-secondary" onClick={() => setShowModalTimeline(false)}>
							Oke
						</button>
					</Modal.Footer>
				</Modal>
			</>
		)
	}

	return (
		<>
			{isBrowser ? <Header /> : <HeaderBackMobile title="Detail Transaksi" />}
			<div className="app2">
				<Sidebar />
				<Container className="p-4" style={{ width: '100%' }}>
					{shipment ?
						<>
							{isBrowser ?
								<div className="d-flex justify-content-between">
									<h5 className="text-bold">
										Detail Transaksi 
										{shipment.status === "Cancel" ? 
											<small className="ml-4 badge bg-danger" style={{ marginLeft:10 }}>
												Dibatalkan
											</small>
										: null}
									</h5>
									<InfoTimeline />
								</div>
								: null}
							{isBrowser ?
							( shipment.status != "Cancel"?
								<Row className="mb-4 d-flex justify-content-start">
									<RowStatus status={1} status_label={shipment.status} />
									<RowStatus status={2} status_label={shipment.status} />
									<RowStatus status={3} status_label={shipment.status} />
									<RowStatus status={4} status_label={shipment.status} />
									<RowStatus status={5} status_label={shipment.status} />
									<RowStatus status={6} status_label={shipment.status} />
								</Row> : null
							)
								:
								<StatusViewMobile />
							}
							<Row>
								<Col sm={12} className="d-flex justify-content-end">
									<a href="#" className="text-orange" onClick={(e) => { e.preventDefault(); setCollapseAll(!collapseAll) }}>
										{collapseAll ?
											<FontAwesomeIcon icon={faChevronUp} />
											:
											<FontAwesomeIcon icon={faChevronDown} />
										}
									</a>
								</Col>
							</Row>
							<Row className="border border-rounded p-3 mb-4">

								<Col sm={12} className="d-flex justify-content-between">
									<span className="title-detail-order">Informasi Pesanan</span>
									<span className="title-detail-order">
										<a href="#" className="text-orange" onClick={(e) => { e.preventDefault(); setCollapseInformasi(!collapseInformasi) }}>
											{collapseInformasi ?
												<FontAwesomeIcon icon={faChevronUp} />
												:
												<FontAwesomeIcon icon={faChevronDown} />
											}
										</a>
									</span>
								</Col>
								<Col sm={12}><hr /></Col>
								<Col sm={12}>
									<Collapse in={collapseInformasi}>
										{isBrowser ?
											<Row className="p-2">
												<Col sm={12} md={3} className="mb-3">
													<div style={styles.title}>Order No</div>
													<div style={styles.subtitle}>{shipment.order_number}</div>
												</Col>
												<Col sm={12} md={3} className="mb-3">
													<div style={styles.title}>Order Date</div>
													<div style={styles.subtitle}>{shipment.order_date}</div>
												</Col>
												<Col sm={12} md={3} className="mb-3">
													<div style={styles.title}>Order Status</div>
													<div style={styles.subtitle}>{shipment.status}</div>
												</Col>
												<Col sm={12} md={3} className="mb-3">
													<div style={styles.title}>Total Harga</div>
													<div style={styles.subtitle}>{Helper.formatMoney(shipment.total_amount)}</div>
												</Col>

												<Col sm={12} md={3} className="mb-3">
													<div style={styles.title}>Dari</div>
													<div style={styles.subtitle}>{shipment.origin.name}</div>
												</Col>
												<Col sm={12} md={3} className="mb-3">
													<div style={styles.title}>Tujuan</div>
													<div style={styles.subtitle}>{shipment.destination}</div>
												</Col>
												<Col sm={12} md={3} className="mb-3">
													<div style={styles.title}>Kurir</div>
													<div style={styles.subtitle}>{shipment.courier}</div>
												</Col>
												<Col sm={12} className="mb-3 divider-order-detail"></Col>
												<Col sm={12} md={6} className="mb-3">
													<div style={styles.title}>Pengirim</div>
													<div style={styles.subtitle}>
														<div>Nama: {shipment.shipper.name}</div>
														<div>Telepon: {shipment.shipper.phone}</div>
														<div>Alamat: {shipment.shipper.address}</div>
													</div>
												</Col>
												<Col sm={12} md={6} className="mb-3" style={{ borderLeft: '1px solid #aaa' }}>
													<div style={styles.title}>Penerima</div>
													<div style={styles.subtitle}>
														<div>Nama: {shipment.consignee.name}</div>
														<div>Telepon: {shipment.consignee.phone}</div>
														<div>Alamat: {shipment.consignee.address}</div>
														<div>Kodepos: {shipment.consignee.postal_code}</div>
														<div>Tax ID: {shipment.consignee.tax_id}</div>
													</div>
												</Col>

											</Row>
											:
											<Row>
												<Col xs={6} sm={6}><small className="text-grey">Order No</small></Col>
												<Col xs={6} sm={6} className="text-end"><small>{shipment.order_number}</small></Col>

												<Col xs={6} sm={6}><small className="text-grey">Order Date</small></Col>
												<Col xs={6} sm={6} className="text-end"><small>{shipment.order_date}</small></Col>

												<Col xs={6} sm={6}><small className="text-grey">Total Harga</small></Col>
												<Col xs={6} sm={6} className="text-end"><small>{Helper.formatMoney(shipment.total_amount)}</small></Col>

												<Col xs={12}><hr /></Col>

												<Col xs={6} sm={6}><small className="text-grey">Dari</small></Col>
												<Col xs={6} sm={6} className="text-end"><small>{shipment.origin.name}</small></Col>

												<Col xs={6} sm={6}><small className="text-grey">Tujuan</small></Col>
												<Col xs={6} sm={6} className="text-end"><small>{shipment.destination}</small></Col>

												<Col xs={6} sm={6}><small className="text-grey">Kurir</small></Col>
												<Col xs={6} sm={6} className="text-end"><small>{shipment.courier}</small></Col>

											</Row>
										}
									</Collapse>
								</Col>
							</Row>

							<Row className="border border-rounded p-3 mb-4">
								<Col sm={12} className="d-flex justify-content-between">
									<span className="title-detail-order">Detail Barang</span>
									<span className="title-detail-order">
										<a href="#" className="text-orange" onClick={(e) => { e.preventDefault(); setCollapseItem(!collapseItem) }}>
											{collapseItem ?
												<FontAwesomeIcon icon={faChevronUp} />
												:
												<FontAwesomeIcon icon={faChevronDown} />
											}
										</a>
									</span>
								</Col>
								<Col sm={12}><hr /></Col>
								<Col sm={12}>
									{collapseItem ?
										<>
											{isBrowser ?
												<Row className="p-3 mt-2">
													<Col sm={12} md={3} className="mb-3">
														<div style={styles.title}>Kategori</div>
														<div style={styles.subtitle}>{shipment.category}</div>
													</Col>
													<Col sm={12} md={3} className="mb-3">
														<div style={styles.title}>Jenis Barang</div>
														<div style={styles.subtitle}>{shipment.jenis_barang}</div>
													</Col>
													<Col sm={12}></Col>
													<Col sm={12} md={3} >
														<div style={styles.title}>Jumlah</div>
														<div style={styles.subtitle}>{shipment.quantity} Barang</div>
													</Col>
													<Col sm={12} md={3} >
														<div style={styles.title}>Volume</div>
														<div style={styles.subtitle}>{volume}m<sup>3</sup></div>
													</Col>
													<Col sm={12} md={3} >
														<div style={styles.title}>Berat</div>
														<div style={styles.subtitle}>{Helper.getWeight(shipment.weight, shipment.quantity)}kg</div>
													</Col>
													<Col sm={12} md={12} className="mt-4">
														<div>
															<small style={{ color: '#333' }}>
																<FontAwesomeIcon icon={faCircleNotch} className="text-orange" />&nbsp;&nbsp;
																{shipment.quantity}x Unit- {shipment.long}cm x {shipment.wide}cm x {shipment.tall}cm - {shipment.weight}kg
															</small>
														</div>
														<div style={{ paddingLeft: 20 }}>
															<small style={{ color: '#333' }}>{shipment.item}</small><br />
															<small style={{ color: '#333' }}>Harga Barang: {Helper.formatMoney(shipment.item_price)}</small>
														</div>
													</Col>

												</Row>
												:
												<Row>
													<Col xs={6} sm={6}><small className="text-grey">Kategori</small></Col>
													<Col xs={6} sm={6} className="text-end"><small>{shipment.category}</small></Col>

													<Col xs={6} sm={6}><small className="text-grey">Jenis Barang</small></Col>
													<Col xs={6} sm={6} className="text-end"><small>{shipment.jenis_barang}</small></Col>

													<Col xs={6} sm={6}><small className="text-grey">Jumlah Barang</small></Col>
													<Col xs={6} sm={6} className="text-end"><small>{shipment.quantity} unit</small></Col>

													<Col xs={6} sm={6}><small className="text-grey">Volume</small></Col>
													<Col xs={6} sm={6} className="text-end"><small>{volume}m<sup>3</sup></small></Col>

													<Col xs={6} sm={6}><small className="text-grey">Berat</small></Col>
													<Col xs={6} sm={6} className="text-end"><small>{Helper.getWeight(shipment.weight, shipment.quantity)}kg</small></Col>

													<Col xs={12}><hr /></Col>
													<Col xs={12}>
														<div>
															<small style={{ color: '#333' }}>
																<FontAwesomeIcon icon={faCircleNotch} className="text-orange" />&nbsp;&nbsp;
																{shipment.quantity}x Unit- {shipment.long}cm x {shipment.wide}cm x {shipment.tall}cm - {shipment.weight}kg
															</small>
														</div>
														<div style={{ paddingLeft: 20 }}>
															<small style={{ color: '#333' }}>{shipment.item}</small><br />
															<small style={{ color: '#333' }}>Harga Barang: {Helper.formatMoney(shipment.item_price)}</small>
														</div>
													</Col>
												</Row>
											}
										</>
										: null}
								</Col>
							</Row>

							<Row className="border border-rounded p-3 mb-4">
								<Col sm={12} className="d-flex justify-content-between">
									<span className="title-detail-order">Add On</span>
									<span className="title-detail-order">
										<a href="#" className="text-orange" onClick={(e) => { e.preventDefault(); setCollapseAddon(!collapseAddon) }}>
											{collapseAddon ?
												<FontAwesomeIcon icon={faChevronUp} />
												:
												<FontAwesomeIcon icon={faChevronDown} />
											}
										</a>
									</span>
								</Col>
								<Col sm={12}><hr /></Col>
								<Col sm={12}>
									{collapseAddon ?
										<Row>
											<Col sm={12}>
												<h6>Dokumen:</h6>
												<ul>
													{shipment.addons.map((v, i, a) => {
														if (v.type === "DOCUMENT") {
															return (
																<li key={'dc-' + i}>{v.name} <small>({Helper.formatMoney(v.price)})</small></li>
															)
														}
													})}
												</ul>
												<h6>Packaging:</h6>
												<ul>
													{shipment.addons.map((v, i, a) => {
														if (v.type === "PACKAGING") {
															return (
																<li key={'dc-' + i}>{v.name} <small>({Helper.formatMoney(v.price)})</small></li>
															)
														}
													})}
												</ul>
											</Col>
										</Row>
										: null}
								</Col>
							</Row>
							<Row className="border border-rounded p-3 mb-4">
								<Col sm={12} className="d-flex justify-content-between">
									<span className="title-detail-order">Rincian Harga</span>
									<span className="title-detail-order">
										<a href="#" className="text-orange" onClick={(e) => { e.preventDefault(); setCollapsePrice(!collapsePrice) }}>
											{collapsePrice ?
												<FontAwesomeIcon icon={faChevronUp} />
												:
												<FontAwesomeIcon icon={faChevronDown} />
											}
										</a>
									</span>
								</Col>
								<Col sm={12}><hr /></Col>
								<Col sm={12}>
									{collapsePrice ?
										<>
											<Row className="mb-2">
												<Col><span>Pengiriman</span></Col>
												<Col className="text-end">{Helper.formatMoney(salesPrice)}</Col>
											</Row>
											<Row className="mb-2">
												<Col><span>Dokumen</span></Col>
												<Col className="text-end">{Helper.formatMoney(documentPrice)}</Col>
											</Row>
											<Row className="mb-2">
												<Col><span>Packaging</span></Col>
												<Col className="text-end">{Helper.formatMoney(packagingPrice)}</Col>
											</Row>
											<hr />
											<Row>
												<Col><span>Harga Sebelum Asuransi</span></Col>
												<Col className="text-end">{Helper.formatMoney(priceBeforeInsurance)}</Col>
											</Row>
											<hr />
											<Row>
												<Col><span>Asuransi ({shipment.insurance_value})</span></Col>
												<Col className="text-end">{Helper.formatMoney(shipment.insurance_amount)}</Col>
											</Row>
											<hr />
											<Row>
												<Col><span>Total Tagihan</span></Col>
												<Col className="text-end">
													<strong style={{ fontSize: '1.2em' }}>{Helper.formatMoney(shipment.total_amount)}</strong>
												</Col>
											</Row>
										</>
										: null}
								</Col>
							</Row>

						</> : null}
				</Container>
			</div>
			<Footer />
		</>
	)
}