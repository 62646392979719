import 'bootstrap/dist/css/bootstrap.min.css';
import Header from './components/Header';
import { Container, Image, Row, Col, Spinner, Form } from 'react-bootstrap';
import React, { useRef, useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import Footer from './components/Footer';
import { Formik, Form as FormFormik } from 'formik';
import { useRequestchangepassword, useRequestresetpassword } from './libs/hooks';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { BottomNavigationAlt, InputPassword } from './components/alt';
import Helper from './libs/helper';
import { useHistory } from 'react-router';


function Forgot() {
	const useQuery = () => {
		return new URLSearchParams(useLocation().search);
	}
	const queryParams = useQuery()
	const [{ responseChangepassword, isLoadingChangepassword, errorChangePassword }, fetchChangepassword] = useRequestchangepassword()
	const formResetPassword = useRef();
	const history = useHistory();
	const [userEmail, setUserEmail] = useState(null);

	useEffect(() => {
		if (Helper.guard()) Helper.redirectTo('')
	}, [])

	useEffect(() => {
		if (!responseChangepassword) {
			setUserEmail(null)
			return;
		}
		setTimeout(() => {
			Helper.redirectTo("login")
		}, 3000)

	}, [responseChangepassword])

	const validate = (values) => {
		const errors = {};
		if (!values.password) errors.password = "Required"
		if (!values.password_confirmation) errors.password_confirmation = "Required"
		if (values.password != values.password_confirmation) errors.password_confirmation = "Password harus sama"
		return errors;
	};

	const onSubmitResetPassword = (data, { setFieldError }) => {
		// alert(queryParams.get('token'))
		fetchChangepassword({
			token: queryParams.get('token'),
			password: data.password,
			password_confirmation: data.password_confirmation
		}, setFieldError)
	}

	return (
		<div className="App">
			<Header />
			<Container className="p-4">

				<h3>Reset Password</h3>

				<Row className="justify-content-center">
					<Col sm={12} md={5} className="border shadow p-4 text-start" style={{ borderRadius: 20 }}>

						<Formik innerRef={formResetPassword} initialValues={{
							password: "",
							password_confirmation: "",
						}} onSubmit={onSubmitResetPassword} validate={validate} validateOnChange={false} validateOnBlur={false} novalidate className="needs-validation was-validated">
							{({ handleChange, handleBlur, handleSubmit, values, errors }) => (
								<FormFormik>
									<div className="text-center mb-4" style={{ color: 'grey' }}>
										Silahkan ubah password Anda.
									</div>
									{
										responseChangepassword ?
											<div className="alert alert-success">
												{responseChangepassword.status.message}
											</div>
											: null
									}
									{errorChangePassword ?
										<div className="alert alert-danger">
											{errorChangePassword ? errorChangePassword.status.message : null}
										</div>
										: null}
									<div className="grey-600 mb-3">
										Sudah ingat password? <a href="#" className="text-primary" onClick={() => Helper.routeTo(history, '/login')}>Masuk di sini!</a>
									</div>
									<div className="form-group mb-3">
										<label>Password Baru</label>
										<InputPassword isInvalid={!!errors.password} invalidFeedback={errors.password} onChange={(e) => formResetPassword.current.setFieldValue('password', e)} />
										<div className="invalid-feedback">
											{errors.password}
										</div>
									</div>
									<div className="form-group mb-3">
										<label>Konfirmasi Password Baru</label>
										<InputPassword isInvalid={!!errors.password_confirmation} invalidFeedback={errors.password_confirmation} onChange={(e) => formResetPassword.current.setFieldValue('password_confirmation', e)} />
										<div className="invalid-feedback">
											{errors.password_confirmation}
										</div>
									</div>

									<div className="d-grid mt-3">
										<button type="submit" className="btn btn-primary btn-block btn-lg bg-secondary" disabled={isLoadingChangepassword}>
											{isLoadingChangepassword ?
												<>
													<Spinner
														as="span"
														animation="border"
														size="sm"
														role="status"
														aria-hidden="true"
													/> &nbsp;</>
												: null}
											Submit
										</button>
									</div>
								</FormFormik>
							)}
						</Formik>

					</Col>
				</Row>
			</Container>
			<Footer />
			<BottomNavigationAlt active={3} />
		</div>
	);
}

export default Forgot;
