import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useRef, useState } from 'react';
import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect';
import { useHistory } from 'react-router';

export default function HeaderBackMobile({title}) {
	const history = useHistory();
	return (
		<div className="p-3 d-flex align-items-center" style={{ borderBottom:'1px solid grey' }}>
			<FontAwesomeIcon icon={faArrowLeft} onClick={()=>history.goBack()} /> 
			<span className="ms-3 text-bold">{title}</span>
		</div>
	)
}