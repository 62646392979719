import { faFile, faHeart, faHistory, faMap, faPlusCircle, faPlusSquare, faSquare, faTachometerAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react"
import { ProSidebar, Menu, MenuItem, SubMenu } from 'react-pro-sidebar';
import { Link } from "react-router-dom";
import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect';

export default function Sidebar() {
	return (
		<>
			{isBrowser ?
				<ProSidebar>
					<Menu iconShape="square">
						<MenuItem>
							<FontAwesomeIcon icon={faTachometerAlt} /> &nbsp;
							Home <Link to="/" />
						</MenuItem>
						<MenuItem>
							<FontAwesomeIcon icon={faPlusCircle} /> &nbsp;
							Form Pesanan <Link to="/order" />
						</MenuItem>
						<MenuItem>
							<FontAwesomeIcon icon={faHistory} /> &nbsp;
							Riwayat <Link to="/order-history" />
						</MenuItem>
						<MenuItem>
							<FontAwesomeIcon icon={faMap} /> &nbsp;
							Daftar Alamat <Link to="/address-shipper" />
						</MenuItem>
						{/* <SubMenu title="Transaksi" icon={<FontAwesomeIcon icon={faFile} />}>
						<MenuItem>Riwayat <Link to="/order-history" /></MenuItem>
					</SubMenu> */}
					</Menu>
				</ProSidebar>
				: null}
		</>
	)
}