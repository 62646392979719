import 'bootstrap/dist/css/bootstrap.min.css';
import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect';
import Header from './components/Header';
import { Container, Row, Col, Image, Table } from 'react-bootstrap';
import React from 'react';
// import './App.css';
import check from './images/check.png';
import clock from './images/clock.png';
import tap from './images/tap.png';
import money from './images/money.png';
import unlimited from './images/unlimited.png';
import efficient from './images/efficient.png';
import secure from './images/secure-shield.png';
import trust from './images/trust.png';
import transparency from './images/transparency.png';
import branding from './images/branding.png';
import checkmark from './images/checkmark.png';
import pay from './images/pay.png';
import paket from './images/package.png';
import browser_3d from './images/3d_browser.png';
import plus_3d from './images/3d_plus.png';
import wallet_3d from './images/3d_wallet.png';
import clock_3d from './images/3d_clock.png';
import rocket_3d from './images/3d_rocket.png';
import check_3d from './images/3d_check.png';
import Carousel from 'react-grid-carousel'
import Footer from './components/Footer';
import { BottomNavigationAlt, FormBooking } from './components/alt';
import { useBanner } from './libs/hooks';


function Home() {
	const [{ bannerList, isLoadingBanner }, fetchBanner] = useBanner();

	const CarouselBanner = () => {
		const styles = {
			container: {
				marginTop: 20,
			},
			image: {
				// height: 300,
				borderRadius: 15,
			}
		}

		const onClickBanner = (v)=>{
			if(v.link){
				window.location.href = v.link;
			}
		}

		return (
			<div style={styles.container}>
				<Carousel cols={2} rows={1} gap={20} loop autoplay={3000} showDots={true}>
					{bannerList.map((v, i, a) => {
						return (
							<Carousel.Item key={"b" + i + v.id}>
								<img width="100%" style={styles.image} src={v.avatar} height={isMobile ? 80 : 200}  onClick={()=>onClickBanner(v)} />
							</Carousel.Item>
						)

					})}
				</Carousel>
				{/* <div style={{
					display: 'flex',
					position: 'absolute',
					justifyContent: 'flex-end',
					transform: 'translateY(-70%)',
					right: 0,
				}}>
					<small>
						<a href="/">Lihat Semua</a>
					</small>
				</div> */}
			</div>
		)
	}

	const Section3 = () => {

		return (
			<>
				<FormBooking />
			</>
		)
	}

	const Section4 = () => {
		return (
			<div style={{ minHeight: '300px', backgroundColor: '#fafbfd' }} className={"mt-4 " + (isMobile ? 'py-4' : 'p-4')}>
				<Container className="mt-4 d-flex justify-content-md-center align-items-center flex-column">
					<span className="section-landing-title mb-3">Jasa Kirim #1 Pilihan Eksportir Indonesia</span>
					<p className={isMobile ? 'w-100' : 'w-75'}>
						Sebagai Jasa Kirim No.1 pilihan eksportir Indonesia, kita sangat memahami keresahan kaum eksportir nusantara yang kesulitan dalam mengirim barang skala kecil seperti parcels, dokumen, hingga sample barang keluar negeri dengan aman, transparan dan dihandle secara profesional hingga zero mistake.
					</p>
					<p className={isMobile ? 'w-100' : 'w-75'}>
						Kita menyadari bahwa masalah tersebut perlu  diselesaikan dengan solusi yang nyata. Dan selamat datang buat #sahabat smallpaket semua, kalian berada diera revolusi industri logistik pengiriman internasional yang tidak biasa. Rasakan pengalaman yang berbeda ketika mengirim dokumen & Paket Internasional bersama Smallpaket.com.
					</p>
				</Container>
			</div>
		)
	}

	const Section5 = () => {
		return (
			<Container className="mb-4 pb-4">
				<Row>
					<Col className="d-flex justify-contentcenter align-items-md-center flex-column mt-4 mb-3 pt-4 pb-4">
						<span className="section-landing-title">How It Works</span>
						{isBrowser ?
							<Row className="mt-4 pt-4 d-flex justify-content-center" style={{
								width: '100%',
							}}>
								<Col xs={12} md={2} className="d-flex flex-column text-center justify-content-center align-items-center mb-4">
									<Image src={browser_3d} width={50} />
									<h6 className="mt-4">
										<strong>Step 1</strong>
									</h6>
									<div>
										Cek Biaya Pengiriman
									</div>

								</Col>
								<Col xs={12} md={2} className="d-flex flex-column justify-content-center align-items-center mb-4">
									<Image src={plus_3d} width={50} />
									<h6 className="mt-4">
										<strong>Step 2</strong>
									</h6>
									<div>
										Buat Order
									</div>
								</Col>
								<Col xs={12} md={2} className="d-flex flex-column justify-content-center align-items-center mb-4">
									<Image src={check_3d} width={50} />
									<h6 className="mt-4">
										<strong>Step 3</strong>
									</h6>
									<div>
										Validasi Order
									</div>
								</Col>
								<Col xs={12} md={2} className="d-flex flex-column justify-content-center align-items-center mb-4">
									<Image src={wallet_3d} width={50} />
									<h6 className="mt-4">
										<strong>Step 4</strong>
									</h6>
									<div>
										Pembayaran
									</div>
								</Col>
								<Col xs={12} md={2} className="d-flex flex-column justify-content-md-center justify-content-xs-start align-items-center mb-4">
									<Image src={rocket_3d} width={50} />
									<h6 className="mt-4">
										<strong>Step 5</strong>
									</h6>
									<div>
										Proses Pengiriman
									</div>
								</Col>
							</Row>
							:
							<Container className="mt-3">
								<table className="table table-striped table-bordered">
									<tbody>
									<tr>
										<th>
											<Image src={browser_3d} width={50} />
										</th>
										<td className="text-start" style={{ fontWeight: 'bold', fontSize: '1.1em' }}>Cek Biaya Pengiriman</td>
									</tr>
									<tr>
										<th>
											<Image src={plus_3d} width={50} />
										</th>
										<td className="text-start" style={{ fontWeight: 'bold', fontSize: '1.1em' }}>Buat Order</td>
									</tr>
									<tr>
										<th>
											<Image src={check_3d} width={50} />
										</th>
										<td className="text-start" style={{ fontWeight: 'bold', fontSize: '1.1em' }}>Validasi Order</td>
									</tr>
									<tr>
										<th>
											<Image src={wallet_3d} width={50} />
										</th>
										<td className="text-start" style={{ fontWeight: 'bold', fontSize: '1.1em' }}>Pembayaran</td>
									</tr>
									<tr>
										<th>
											<Image src={rocket_3d} width={50} />
										</th>
										<td className="text-start" style={{ fontWeight: 'bold', fontSize: '1.1em' }}>Pengiriman</td>
									</tr>
									</tbody>
								</table>
							</Container>
						}
					</Col>
				</Row>
			</Container>
		)
	}

	const Section6 = () => {
		return (
			<div className="pb-4" style={{
				backgroundColor: '#FFEEE0'
			}}>
				<Row>
					<Col className="d-flex justify-content-center align-items-md-center flex-column mt-4 mb-3 pt-4 pb-4">
						<h1 style={{
							color: '#707070',
							fontWeight: 'bold'
						}}>Why Choose Us</h1>
						{/* <p className="p-4 mt-3">
							Lorem ipsum dolor sit amet, consectetur adipiscing elit. In urna lectus,
							mattis non accumsan in, tempor dictum neque.
						</p> */}
						<Container className="mt-4">
							{/* <Row>
								<Col className="d-flex justify-content-center flex-column align-items-center">
									<Image src={clock} width={30} alt="Check" />
									<span>Cepat</span>
								</Col>
								<Col className="d-flex justify-content-center flex-column align-items-center">
									<Image src={tap} width={30} alt="Check" />
									<span>Mudah</span>
								</Col>
								<Col className="d-flex justify-content-center flex-column align-items-center">
									<Image src={money} width={30} alt="Check" />
									<span>Murah</span>
								</Col>
								<Col className="d-flex justify-content-center flex-column align-items-center">
									<Image src={unlimited} width={30} alt="Check" />
									<span>Tanpa Kuota</span>
								</Col>
								<Col className="d-flex justify-content-center flex-column align-items-center">
									<Image src={efficient} width={30} alt="Check" />
									<span>Efisien</span>
								</Col>
								<Col className="d-flex justify-content-center flex-column align-items-center">
									<Image src={transparency} width={30} alt="Check" />
									<span>Transparan</span>
								</Col>
								<Col className="d-flex justify-content-center flex-column align-items-center">
									<Image src={secure} width={30} alt="Check" />
									<span>Aman</span>
								</Col>
								<Col className="d-flex justify-content-center flex-column align-items-center">
									<Image src={trust} width={30} alt="Check" />
									<span>Terpercaya</span>
								</Col>
							</Row> */}
							<div className="table-responsive">
								<Table striped bordered responsive hover className="mt-4">
									{/* <thead>
										<tr>
											<th style={{ fontSize: '1.5em' }}><strong>Advance Features</strong></th>
										</tr>
									</thead> */}
									<tbody>
										<tr>
											<td>Cepat</td>
											<td>
												<Image src={check} width={30} alt="Check" />
											</td>

										</tr>
										<tr>
											<td>Mudah</td>
											<td>
												<Image src={check} width={30} alt="Check" />
											</td>
										</tr>
										<tr>
											<td>Murah</td>
											<td>
												<Image src={check} width={30} alt="Check" />
											</td>
										</tr>
										<tr>
											<td>Tanpa Kuota</td>
											<td>
												<Image src={check} width={30} alt="Check" />
											</td>
										</tr>
										<tr>
											<td>Efisien</td>
											<td>
												<Image src={check} width={30} alt="Check" />
											</td>
										</tr>
										<tr>
											<td>Transparan</td>
											<td>
												<Image src={check} width={30} alt="Check" />
											</td>
										</tr>
										<tr>
											<td>Aman</td>
											<td>
												<Image src={check} width={30} alt="Check" />
											</td>
										</tr>
										<tr>
											<td>Terpercaya</td>
											<td>
												<Image src={check} width={30} alt="Check" />
											</td>
										</tr>

									</tbody>
								</Table>
							</div>
						</Container>
					</Col>
				</Row>
			</div>
		)
	}
	const Section6B = () => {
		return (
			<div className="pb-4" style={{
				backgroundColor: '#FFEEE0'
			}}>
				<Container>
					<Row>
						<Col className="d-flex justify-content-center align-items-md-center flex-column mt-4 mb-3 pt-4 pb-4">
							<span className="section-landing-title">Why Choose Us</span>
							{/* <p className="p-4 mt-3">
							Lorem ipsum dolor sit amet, consectetur adipiscing elit. In urna lectus,
							mattis non accumsan in, tempor dictum neque.
						</p> */}
							<Container className="mt-4">
								<Row>
									<Col md={3} lg={3} sm={6} xs={6} className="d-flex justify-content-center flex-column align-items-center mb-4 mt-4">
										<Image src={clock} width={60} alt="Check" />
										<span className="mt-2" style={{ fontSize: '1.3em' }}>Cepat</span>
									</Col>
									<Col md={3} lg={3} sm={6} xs={6} className="d-flex justify-content-center flex-column align-items-center mb-4 mt-4">
										<Image src={tap} width={60} alt="Check" />
										<span className="mt-2" style={{ fontSize: '1.3em' }}>Mudah</span>
									</Col>
									<Col md={3} lg={3} sm={6} xs={6} className="d-flex justify-content-center flex-column align-items-center mb-4 mt-4">
										<Image src={money} width={60} alt="Check" />
										<span className="mt-2" style={{ fontSize: '1.3em' }}>Murah</span>
									</Col>
									<Col md={3} lg={3} sm={6} xs={6} className="d-flex justify-content-center flex-column align-items-center mb-4 mt-4">
										<Image src={unlimited} width={60} alt="Check" />
										<span className="mt-2" style={{ fontSize: '1.3em' }}>Tanpa Kuota</span>
									</Col>
									<Col md={3} lg={3} sm={6} xs={6} className="d-flex justify-content-center flex-column align-items-center mb-4 mt-4">
										<Image src={efficient} width={60} alt="Check" />
										<span className="mt-2" style={{ fontSize: '1.3em' }}>Efisien</span>
									</Col>
									<Col md={3} lg={3} sm={6} xs={6} className="d-flex justify-content-center flex-column align-items-center mb-4 mt-4">
										<Image src={transparency} width={60} alt="Check" />
										<span className="mt-2" style={{ fontSize: '1.3em' }}>Transparan</span>
									</Col>
									<Col md={3} lg={3} sm={6} xs={6} className="d-flex justify-content-center flex-column align-items-center mb-4 mt-4">
										<Image src={secure} width={60} alt="Check" />
										<span className="mt-2" style={{ fontSize: '1.3em' }}>Aman</span>
									</Col>
									<Col md={3} lg={3} sm={6} xs={6} className="d-flex justify-content-center flex-column align-items-center mb-4 mt-4">
										<Image src={trust} width={60} alt="Check" />
										<span className="mt-2" style={{ fontSize: '1.3em' }}>Terpercaya</span>
									</Col>
								</Row>
							</Container>
						</Col>
					</Row>
				</Container>
			</div>
		)
	}
	return (
		<div className="App">
			<Header />
			<Container>
				<CarouselBanner />
				<Section3 />
			</Container>
			<Section4 />
			<Section5 />
			<Section6B />
			<Footer />
			<BottomNavigationAlt active={0} />
		</div>
	);
}

export default Home;
