
import React, { useRef } from 'react';
import { Col, Container, Row, Spinner } from 'react-bootstrap';
import Footer from './components/Footer';
import Header from './components/Header';
import HeaderBackMobile from './components/HeaderBackMobile';
import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect';
import { WordingKebijakanPrivasi } from './components/alt';

function KebijakanPrivasi() {


	return (
		<div style={{

		}}>
			{isBrowser ? <Header /> : <HeaderBackMobile title="Kebijakan Privasi" />}
			<div className="app">
				<Container className="text-start p-4">
					<WordingKebijakanPrivasi />
				</Container>
			</div>
			<div style={{

			}}>
				<Footer />
			</div>
		</div >
	)
}

export default KebijakanPrivasi;