import 'bootstrap/dist/css/bootstrap.min.css';
import Header from './components/Header';
import { Container, Row, Col, Spinner, Form, Modal, Button } from 'react-bootstrap';
import React, { useRef, useState, useEffect } from 'react';
import Footer from './components/Footer';
import { Formik, Form as FormFormik } from 'formik';
import { useRegister } from './libs/hooks';
import { BottomNavigationAlt, ButtonSocialMediaLogin, InputPassword, WordingSyaratKetentuan } from './components/alt';
import Helper from './libs/helper';
import { useHistory } from 'react-router';
import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect';


function Register() {
	const [{ responseRegister, isLoadingRegister }, fetchRegister] = useRegister()
	const [showTermCondition, setShowTermCondition] = useState(false)
	const formRegister = useRef();
	const history = useHistory();
	useEffect(() => {
		if (!responseRegister) return;
		// fetchProfile();

		setTimeout(() => {
			Helper.redirectTo('Login')
		}, 3000)
	}, [responseRegister])

	useEffect(() => {
		if (Helper.guard()) Helper.redirectTo('')
	}, [])

	const validate = (values) => {
		const errors = {};
		if (!values.name) errors.name = "Required"
		if (!values.email) errors.email = "Required"
		if (!values.password) errors.password = "Required"
		if (!values.password_confirmation) errors.password_confirmation = "Required"
		if (!values.is_agree) errors.is_agree = "Required"
		return errors;
	};

	const onSubmit = (data, { setFieldError }) => {
		
		fetchRegister({
			name: data.name,
			email: data.email,
			password: data.password,
			password_confirmation: data.password_confirmation,
		}, setFieldError)
	}

	return (
		<div className="App">
			<Header />
			<Container className="p-4">
				<h3>Pendaftaran Akun</h3>
				<Row className="justify-content-center">
					<Col sm={12} md={5} className="border shadow p-4 text-start" style={{ borderRadius: 20 }}>
						<Formik innerRef={formRegister} initialValues={{
							name: "",
							email: "",
							password: "",
							password_confirmation: "",
							is_agree: false
						}} onSubmit={onSubmit} validate={validate} validateOnChange={false} validateOnBlur={false} novalidate className="needs-validation was-validated">
							{({ handleChange, handleBlur, handleSubmit, values, errors }) => (
								<FormFormik>
									<div className="text-center mb-4" style={{ color: 'grey' }}>
										Selamat datang di smallpaket.com, <br /> kirim barang ke luar negeri kapan saja dan di mana saja
									</div>
									{responseRegister ?
										<div className="alert alert-success">
											{responseRegister ? responseRegister.status.message : null}
										</div>
										: null}
									<div className="grey-600 mb-3">
										Sudah punya akun? <a href="#" className="text-primary" onClick={() => Helper.routeTo(history, '/login')}>Masuk di sini!</a>
									</div>
									<div className="form-group mb-3">
										<Form.Control
											type="text"
											placeholder="Name"
											aria-describedby="inputGroupPrepend"
											name="name"
											size="lg"
											value={values.name}
											onChange={handleChange}
											isInvalid={!!errors.name}
										/>
										<div className="invalid-feedback2">
											{errors.name}
										</div>
									</div>
									<div className="form-group mb-3">
										<Form.Control
											type="text"
											placeholder="Email"
											aria-describedby="inputGroupPrepend"
											name="email"
											size="lg"
											value={values.email}
											onChange={handleChange}
											isInvalid={!!errors.email}
										/>
										<div className="invalid-feedback2">
											{errors.email}
										</div>
									</div>
									<div className="form-group mb-3">
										<InputPassword isInvalid={!!errors.password} invalidFeedback={errors.password} onChange={(e) => formRegister.current.setFieldValue('password', e)} />
									</div>
									<div className="form-group mb-3">
										<InputPassword isInvalid={!!errors.password_confirmation} invalidFeedback={errors.password_confirmation} onChange={(e) => formRegister.current.setFieldValue('password_confirmation', e)} />
									</div>
									{isBrowser ?
										<>
											<div className="form-group mt-3 d-flex justify-content-start align-items-center">
												<input type="checkbox" value="1" disabled={false} checked={values.is_agree} style={{ width: 20, height: 20 }} onChange={(e) => { formRegister.current.setFieldValue('is_agree', e.target.checked) }} />
												&nbsp;Saya telah menyetujui <a href="#" className="text-bold text-orange" onClick={(e) => { e.preventDefault(); setShowTermCondition(true) }}>&nbsp; Syarat dan Ketentuan</a>

											</div>
											<div className="invalid-feedback2">
												{errors.is_agree}
											</div>
										</>
										:
										<div className="form-group mt-3 d-flex align-items-center justify-content-start" style={{ fontSize: '.8em' }}>
											<input type="checkbox" className="" value="1" disabled={false} checked={values.is_agree} style={{ width: 20, height: 20 }}  onChange={(e) => { formRegister.current.setFieldValue('is_agree', e.target.checked) }} />
											&nbsp;Saya telah menyetujui <a href="#" className="text-bold text-orange" onClick={(e) => { e.preventDefault(); setShowTermCondition(true) }}>&nbsp; Syarat dan Ketentuan</a>
											<div className="invalid-feedback2">
												{errors.is_agree}
											</div>
										</div>

									}
									<div className="d-grid mt-3">
										<button type="submit" className="btn btn-primary btn-block btn-lg bg-secondary" disabled={isLoadingRegister}>
											{isLoadingRegister ?
												<>
													<Spinner
														as="span"
														animation="border"
														size="sm"
														role="status"
														aria-hidden="true"
													/> &nbsp;</>
												: null}
											Daftar
										</button>

										<ButtonSocialMediaLogin />
									</div>
									<Modal size="lg" show={showTermCondition} onHide={() => setShowTermCondition(false)} scrollable={true}>
										<Modal.Header closeButton>
											<Modal.Title>Syarat dan Ketentuan</Modal.Title>
										</Modal.Header>
										<Modal.Body>
											<WordingSyaratKetentuan />
											<div className="p-3 mb-3 d-flex align-items-center justify-content-center">
												<input type="checkbox" checked={values.is_agree} onChange={(e) => { formRegister.current.setFieldValue('is_agree', e.target.checked) }} style={{ width: 20, height: 20 }} value="1" /> <span className="text-bold">
													&nbsp;Saya telah membaca dan menyetujui syarat & ketentuan yang berlaku.</span>
											</div>
										</Modal.Body>
										{values.is_agree ?
											<Modal.Footer>
												<Button variant="secondary" onClick={() => setShowTermCondition(false)}>
													Oke
												</Button>
											</Modal.Footer>
											: null}
									</Modal>
								</FormFormik>
							)}
						</Formik>
					</Col>
				</Row>
			</Container>
			<Footer />
			<BottomNavigationAlt active={3} />
		</div>
	);
}

export default Register;
