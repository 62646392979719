import { Formik, Form as FormFormik } from 'formik';
import React, { useRef, useState, Image, useEffect } from 'react';
import { Col, Container, Row, Spinner, Tabs, Tab, Modal, Nav, SplitButton, Dropdown } from 'react-bootstrap';
import Footer from './components/Footer';
import Header from './components/Header';
import Sidebar from './components/Sidebar';
import { useGetconsignee, useGetshipper, usePostconsignee, usePostshipper } from './libs/hooks';
import ModalDialog from 'react-bootstrap/ModalDialog'
import { faChevronDown, faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect';
import empty from './images/empty.png'
import Switch from "react-switch";
import { BottomNavigationAlt } from './components/alt';
import HeaderBackMobile from './components/HeaderBackMobile';

function AddressBook() {
	const [key, setKey] = useState('Daftar Pengirim');

	const ListShipper = () => {
		const [{ shipperList, isLoadingShipper, isEmptyShipper, isHasLoadMore }, fetchGetShipper, fetchDeleteShipper, setDefaultShipper, fetchLoadMore] = useGetshipper()
		const [{ responseShipper, isLoadingPostShipper, errorShipper }, fetchPostShipper, fetchPutShipper] = usePostshipper()
		const [showModal, setShowModal] = useState(false)
		const [showModalSetDefault, setShowModalSetDefault] = useState(false)
		const [showModalCreate, setShowModalCreate] = useState(false)
		const [selectedRow, setSelectedRow] = useState(null)
		const [search, setSearch] = useState("")
		const [formName, setFormName] = useState("")
		const [formPhone, setFormPhone] = useState("")
		const [formAddress, setFormAddress] = useState("")
		const [formIsDefault, setFormIsDefault] = useState(false)
		const [formErrors, setFormErrors] = useState({})
		const [formState, setFormState] = useState("create")

		useEffect(() => {
			if (!responseShipper) return;
			setShowModalCreate(false)
			let resData = responseShipper.data;
			resData.default = 1;
			if (formIsDefault) {
				setDefaultShipper(resData.id)
			}
			fetchGetShipper(search)
		}, [responseShipper])

		const onSearch = () => {
			fetchGetShipper(search)
		}

		const validate = () => {
			let errors = {}
			setFormErrors({})
			if (!formName) errors.formName = "Required"
			if (!formPhone) errors.formPhone = "Required"
			if (formPhone && formPhone.length < 3) errors.formPhone = "Minimum 3 character"
			if (!formAddress) errors.formAddress = "Required"
			if (formAddress && formAddress.length < 3) errors.formAddress = "Minimum 3 character"
			setFormErrors(errors)
			return Object.keys(errors).length === 0
		}

		const onSubmit = (e) => {
			e.preventDefault();
			if (validate()) {
				if (formState === "create") {
					fetchPostShipper({
						name: formName,
						phone: formPhone,
						address: formAddress,
						default: formIsDefault ? 1 : 0
					})
				} else {
					fetchPutShipper({
						name: formName,
						phone: formPhone,
						address: formAddress,
						default: formIsDefault ? 1 : 0,
						status: selectedRow.status
					}, selectedRow.id)
				}
			}
		}

		const onSetStateForm = (state, row = null) => {
			setSelectedRow(row)
			setFormState(state);
			if (row !== null) {
				setFormName(row.name)
				setFormPhone(row.phone)
				setFormAddress(row.address)
				setFormIsDefault(row.default)
			} else {
				setFormName("")
				setFormPhone("")
				setFormAddress("")
				setFormIsDefault("")

			}
			setShowModalCreate(true);
		}

		return (
			<>
				<Row>

					<Col sm={12}>
						<Row className="d-flex justify-content-between">
							<Col sm={12} md={4}>
								<div className="form-group">
									<div className="input-group mb-3">
										<input type="search" className="form-control" disabled={isLoadingShipper} placeholder="Pencarian" value={search} onChange={(e) => setSearch(e.target.value)} />
										<button className="btn btn-outline-secondary" type="button" id="button-addon2" onClick={() => { onSearch() }}>
											<FontAwesomeIcon icon={faSearch} />
										</button>
									</div>
								</div>
							</Col>
							<Col sm={12} md={8} className="text-end mb-3">
								<button className="btn btn-primary" type="button" onClick={() => onSetStateForm("create")}>
									Tambah Pengirim Baru
								</button>
							</Col>
						</Row>

					</Col>
					{isLoadingShipper ?
						<Col sm={12} className="text-center">
							<Spinner
								as="span"
								animation="border"
								size="sm"
								role="status"
								aria-hidden="true"
							/>
						</Col> : null}
					{shipperList && !isEmptyShipper ?
						shipperList.map((v, i, a) => {
							return (
								<Col sm={12} key={v.id} className={`mb-3 border shadow p-3 border-rounded border-shipper-selected` + (v.default === 1 ? ' bg-selected' : null)}>
									<span className="text-bold">{v.name} {v.default === 1 ? <small className="badge bg-secondary">Utama</small> : null}</span> <br />
									<small><span className="text-bold">Telepon:</span> {v.phone}</small><br />
									<small><span className="text-bold">Alamat:</span> {v.address}</small><br />
									{isBrowser ?
										<>
											<a href="#" className="action-address-book-row action-address-book-row-border-right" onClick={(e) => { e.preventDefault(); onSetStateForm("edit", v) }}>Ubah Pengirim</a>
											{v.default !== 1 ? <a href="#" className="action-address-book-row action-address-book-row-border-right" onClick={(e) => { e.preventDefault(); setSelectedRow(v); setShowModalSetDefault(true) }}>Jadikan Pengirim Utama</a> : null}
											<a href="#" className="action-address-book-row" onClick={(e) => { e.preventDefault(); setSelectedRow(v); setShowModal(true) }}>Hapus</a>
										</> :
										<>
											<SplitButton
												key={v.id}
												id={`dropdown-split-variants-` + v.id}
												variant={'outline-secondary'}
												title={"Ubah Pengirim"}
												className="w-100 mt-3"
												onClick={(e) => { e.preventDefault(); onSetStateForm("edit", v) }}
											>
												<Dropdown.Item eventKey="1" onClick={(e) => { e.preventDefault(); setSelectedRow(v); setShowModalSetDefault(true) }}>Jadikan Penerima Utama</Dropdown.Item>
												<Dropdown.Divider />
												<Dropdown.Item eventKey="2" onClick={(e) => { e.preventDefault(); setSelectedRow(v); setShowModal(true) }}>Hapus</Dropdown.Item>

											</SplitButton>
										</>
									}
								</Col>
							)
						})
						: null}
					{isEmptyShipper ?
						<Col className="p-4 mt-4 text-center">
							<img src={empty} alt="empty" style={{
								width: 120
							}} />
							<h6 className="mt-2 text-bold">Tidak Ditemukan</h6>
						</Col>
						: null}

					{isHasLoadMore ?
						<Col className="p-4 mt-4 text-center">
							<button type="button" className="btn bg-secondary text-white" onClick={(e) => fetchLoadMore(search)}>
								<FontAwesomeIcon icon={faChevronDown} /> &nbsp;
								Selanjutnya
							</button>
						</Col>
						: null}
				</Row>

				<Modal
					size="sm"
					show={showModal}
					onHide={() => setShowModal(false)}
					backdrop="static"
					keyboard={false}
					centered
				>
					<Modal.Header closeButton>
						<Modal.Title>Konfirmasi</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						{selectedRow ?
							<div className="text-center">
								{`Apakah Anda yakin untuk menghapus "${selectedRow.name}"? Anda tidak dapat mengembalikan penerima yang sudah dihapus.`}
							</div> : null}

						<hr />
						<Row>
							<Col className="d-md-block">
								<button className="btn btn-outline-primary" style={{ width: '100%' }} type="button" onClick={() => setShowModal(false)}>Batal</button>
							</Col>
							<Col className="d-md-block">
								<button className="btn btn-primary btn-block" style={{ width: '100%' }} type="button" onClick={() => { fetchDeleteShipper(selectedRow, false); setShowModal(false) }}>Hapus</button>
							</Col>
						</Row>
					</Modal.Body>

				</Modal>

				<Modal
					size="sm"
					show={showModalSetDefault}
					onHide={() => setShowModalSetDefault(false)}
					backdrop="static"
					keyboard={false}
					centered
				>
					<Modal.Header closeButton>
						<Modal.Title>Konfirmasi</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						{selectedRow ?
							<div className="text-center">
								{`Apakah Anda yakin pengirim "${selectedRow.name}" akan dijadikan sebagai pengirim utama?`}
							</div> : null}

						<hr />
						<Row>
							<Col className="d-md-block">
								<button className="btn btn-outline-primary" style={{ width: '100%' }} type="button" onClick={() => setShowModal(false)}>Batal</button>
							</Col>
							<Col className="d-md-block">
								<button className="btn btn-primary btn-block" style={{ width: '100%' }} type="button" onClick={() => { setDefaultShipper(selectedRow.id, false); setShowModalSetDefault(false) }}>Ya</button>
							</Col>
						</Row>
					</Modal.Body>

				</Modal>

				<Modal
					show={showModalCreate}
					onHide={() => setShowModalCreate(false)}
					backdrop="static"
					keyboard={false}
					centered
				>
					<Modal.Header closeButton>
						<Modal.Title>
							{formState === "create" ? "Tambah Pengirim Baru" : "Ubah Pengirim"}
						</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<form onSubmit={(e) => onSubmit(e)}>
							<div md={1} xs={6} className="text-start mb-2 form-group">
								<strong>*Nama Pengirim</strong>
								<input className="form-control" value={formName} type="text" onChange={(e) => setFormName(e.target.value)} />
								{formErrors.formName ? <div className="invalid-feedback2">{formErrors.formName}</div> : null}
							</div>
							<div md={1} xs={6} className="text-start mb-2 form-group">
								<strong>*Nomor Telepon Pengirim</strong>
								<input className="form-control" value={formPhone} type="text" onChange={(e) => setFormPhone(e.target.value)} />
								{formErrors.formPhone ? <div className="invalid-feedback2">{formErrors.formAddress}</div> : null}
							</div>
							<div md={1} xs={6} className="text-start mb-2 form-group">
								<strong>*Alamat Pengirim</strong>
								<textarea className="form-control" value={formAddress} type="textarea" onChange={(e) => setFormAddress(e.target.value)}></textarea>
								{formErrors.formAddress ? <div className="invalid-feedback2">{formErrors.formAddress}</div> : null}
							</div>
							<div md={1} xs={6} className="text-start mb-2 form-group">
								<strong>Jadikan Pengirim Utama</strong> {formIsDefault ? 'Ya' : 'Tidak'}<br />
								<Switch height={20} width={40} handleDiameter={10} onChange={(e) => { setFormIsDefault(e) }} checked={formIsDefault} />
							</div>
							<div md={1} xs={6} className="text-end mb-2 form-group">
								<button className="btn btn-primary" type="submit" onClick={(e) => { onSubmit(e) }} disabled={isLoadingPostShipper}>
									{isLoadingPostShipper ?
										<>
											<Spinner
												as="span"
												animation="border"
												size="sm"
												role="status"
												aria-hidden="true"
											/> &nbsp;</>
										: null}
									Simpan
								</button>
							</div>
						</form>
					</Modal.Body>

				</Modal>
			</>
		)
	}

	const ListConsignee = () => {
		const [{ consigneeList, isLoadingConsignee, isEmptyConsignee, isHasLoadMore }, fetchGetConsignee, fetchDeleteConsignee, setDefaultConsignee, fetchLoadMore] = useGetconsignee()
		const [{ responseConsignee, isLoadingPostConsignee, errorConsignee }, fetchPostConsignee, fetchPutConsignee] = usePostconsignee()
		const [showModal, setShowModal] = useState(false)
		const [showModalSetDefault, setShowModalSetDefault] = useState(false)
		const [showModalCreate, setShowModalCreate] = useState(false)
		const [selectedRow, setSelectedRow] = useState(null)
		const [search, setSearch] = useState("")
		const [formName, setFormName] = useState("")
		const [formPhone, setFormPhone] = useState("")
		const [formAddress, setFormAddress] = useState("")
		const [formPostalCode, setFormPostalCode] = useState("")
		const [formTaxId, setFormTaxId] = useState("")

		const [formIsDefault, setFormIsDefault] = useState(false)
		const [formErrors, setFormErrors] = useState({})
		const [formState, setFormState] = useState("create")

		useEffect(() => {
			if (!responseConsignee) return;
			setShowModalCreate(false)
			let resData = responseConsignee.data;
			resData.default = 1;
			if (formIsDefault) {
				setDefaultConsignee(resData.id)
			}
			fetchGetConsignee(search)
		}, [responseConsignee])

		const onSearch = () => {
			fetchGetConsignee(search)
		}

		const validate = () => {
			let errors = {}
			setFormErrors({})
			if (!formName) errors.formName = "Required"
			if (!formPhone) errors.formPhone = "Required"
			if (formPhone && formPhone.length < 3) errors.formPhone = "Minimum 3 character"
			if (!formAddress) errors.formAddress = "Required"
			if (formAddress && formAddress.length < 3) errors.formAddress = "Minimum 3 character"
			if (!formPostalCode) errors.formPostalCode = "Required"
			if (formPostalCode && formPostalCode.length < 3) errors.formPostalCode = "Minimum 3 character"
			if (!formTaxId) errors.formTaxId = "Required"
			if (formTaxId && formTaxId.length < 3) errors.formTaxId = "Minimum 3 character"
			setFormErrors(errors)
			return Object.keys(errors).length === 0
		}

		const onSubmit = (e) => {
			e.preventDefault();
			if (validate()) {
				if (formState === "create") {
					fetchPostConsignee({
						name: formName,
						phone: formPhone,
						address: formAddress,
						postal_code: formPostalCode,
						tax_id: formTaxId,
						default: formIsDefault ? 1 : 0
					})
				} else {
					fetchPutConsignee({
						name: formName,
						phone: formPhone,
						address: formAddress,
						postal_code: formPostalCode,
						tax_id: formTaxId,
						default: formIsDefault ? 1 : 0,
						status: selectedRow.status
					}, selectedRow.id)

				}
			}
		}

		const onSetStateForm = (state, row = null) => {
			setSelectedRow(row)
			setFormState(state);
			if (row !== null) {
				setFormName(row.name)
				setFormPhone(row.phone)
				setFormAddress(row.address)
				setFormIsDefault(row.default)
				setFormTaxId(row.tax_id)
				setFormPostalCode(row.postal_code)
			} else {
				setFormName("")
				setFormPhone("")
				setFormAddress("")
				setFormIsDefault("")
				setFormPostalCode("")
				setFormTaxId("")

			}
			setShowModalCreate(true);
		}

		return (
			<>
				<Row>

					<Col sm={12}>
						<Row className="d-flex justify-content-between">
							<Col sm={12} md={4}>
								<div className="form-group">
									<div className="input-group mb-3">
										<input type="search" className="form-control" disabled={isLoadingConsignee} placeholder="Pencarian" value={search} onChange={(e) => setSearch(e.target.value)} />
										<button className="btn btn-outline-secondary" type="button" id="button-addon2" onClick={() => { onSearch() }}>
											<FontAwesomeIcon icon={faSearch} />
										</button>
									</div>
								</div>
							</Col>
							<Col sm={12} md={8} className="text-end mb-3">
								<button className="btn btn-primary" type="button" onClick={() => onSetStateForm("create")}>
									Tambah Penerima Baru
								</button>
							</Col>
						</Row>

					</Col>
					{isLoadingConsignee ?
						<Col sm={12} className="text-center">
							<Spinner
								as="span"
								animation="border"
								size="sm"
								role="status"
								aria-hidden="true"
							/>
						</Col> : null}
					{consigneeList && !isEmptyConsignee ?
						consigneeList.map((v, i, a) => {
							return (
								<Col sm={12} key={v.id} className={`mb-3 border shadow p-3 border-rounded border-shipper-selected` + (v.default === 1 ? ' bg-selected' : null)}>
									<span className="text-bold">{v.name} {v.default === 1 ? <small className="badge bg-secondary">Utama</small> : null}</span> <br />
									<small><span className="text-bold">Telepon:</span> {v.phone}</small><br />
									<small><span className="text-bold">Alamat:</span> {v.address}</small><br />
									<small><span className="text-bold">Kodepos:</span> {v.postal_code}</small><br />
									<small><span className="text-bold">Tax ID:</span> {v.tax_id}</small><br />
									{isBrowser ?
										<>
											<a href="#" className="action-address-book-row action-address-book-row-border-right" onClick={(e) => { e.preventDefault(); onSetStateForm("edit", v) }}>Ubah Penerima</a>
											{v.default !== 1 ? <a href="#" className="action-address-book-row action-address-book-row-border-right" onClick={(e) => { e.preventDefault(); setSelectedRow(v); setShowModalSetDefault(true) }}>Jadikan Penerima Utama</a> : null}
											<a href="#" className="action-address-book-row" onClick={(e) => { e.preventDefault(); setSelectedRow(v); setShowModal(true) }}>Hapus</a>
										</> :
										<SplitButton
											key={v.id}
											id={`dropdown-split-variants-` + v.id}
											variant={'outline-secondary'}
											title={"Ubah Penerima"}
											className="w-100 mt-3"
											onClick={(e) => { e.preventDefault(); onSetStateForm("edit", v) }}
										>
											<Dropdown.Item eventKey="1" onClick={(e) => { e.preventDefault(); setSelectedRow(v); setShowModalSetDefault(true) }}>Jadikan Penerima Utama</Dropdown.Item>
											<Dropdown.Divider />
											<Dropdown.Item eventKey="2" onClick={(e) => { e.preventDefault(); setSelectedRow(v); setShowModal(true) }}>Hapus</Dropdown.Item>

										</SplitButton>
									}
								</Col>
							)
						})
						: null}
					{isEmptyConsignee ?
						<Col className="p-4 mt-4 text-center">
							<img src={empty} alt="empty" style={{
								width: 120
							}} />
							<h6 className="mt-2 text-bold">Tidak Ditemukan</h6>
						</Col>
						: null}

					{isHasLoadMore ?
						<Col className="p-4 mt-4 text-center">
							<button type="button" className="btn bg-secondary text-white" onClick={(e) => fetchLoadMore(search)}>
								<FontAwesomeIcon icon={faChevronDown} /> &nbsp;
								Selanjutnya
							</button>
						</Col>
						: null}
				</Row>

				<Modal
					size="sm"
					show={showModal}
					onHide={() => setShowModal(false)}
					backdrop="static"
					keyboard={false}
					centered
				>
					<Modal.Header closeButton>
						<Modal.Title>Konfirmasi</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						{selectedRow ?
							<div className="text-center">
								{`Apakah Anda yakin untuk menghapus "${selectedRow.name}"? Anda tidak dapat mengembalikan penerima yang sudah dihapus.`}
							</div> : null}

						<hr />
						<Row>
							<Col className="d-md-block">
								<button className="btn btn-outline-primary" style={{ width: '100%' }} type="button" onClick={() => setShowModal(false)}>Batal</button>
							</Col>
							<Col className="d-md-block">
								<button className="btn btn-primary btn-block" style={{ width: '100%' }} type="button" onClick={() => { fetchDeleteConsignee(selectedRow, false); setShowModal(false) }}>Hapus</button>
							</Col>
						</Row>
					</Modal.Body>

				</Modal>

				<Modal
					size="sm"
					show={showModalSetDefault}
					onHide={() => setShowModalSetDefault(false)}
					backdrop="static"
					keyboard={false}
					centered
				>
					<Modal.Header closeButton>
						<Modal.Title>Konfirmasi</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						{selectedRow ?
							<div className="text-center">
								{`Apakah Anda yakin penerima "${selectedRow.name}" akan dijadikan sebagai penerima utama?`}
							</div> : null}

						<hr />
						<Row>
							<Col className="d-md-block">
								<button className="btn btn-outline-primary" style={{ width: '100%' }} type="button" onClick={() => setShowModal(false)}>Batal</button>
							</Col>
							<Col className="d-md-block">
								<button className="btn btn-primary btn-block" style={{ width: '100%' }} type="button" onClick={() => { setDefaultConsignee(selectedRow.id, false); setShowModalSetDefault(false) }}>Ya</button>
							</Col>
						</Row>
					</Modal.Body>

				</Modal>

				<Modal
					show={showModalCreate}
					onHide={() => setShowModalCreate(false)}
					backdrop="static"
					keyboard={false}
					centered
				>
					<Modal.Header closeButton>
						<Modal.Title>
							{formState === "create" ? "Tambah Penerima Baru" : "Ubah Penerima"}
						</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<form onSubmit={(e) => onSubmit(e)}>
							<div md={1} xs={6} className="text-start mb-2 form-group">
								<strong>*Nama Penerima</strong>
								<input className="form-control" value={formName} type="text" onChange={(e) => setFormName(e.target.value)} />
								{formErrors.formName ? <div className="invalid-feedback2">{formErrors.formName}</div> : null}
							</div>
							<div md={1} xs={6} className="text-start mb-2 form-group">
								<strong>*Nomor Telepon Penerima</strong>
								<input className="form-control" value={formPhone} type="text" onChange={(e) => setFormPhone(e.target.value)} />
								{formErrors.formPhone ? <div className="invalid-feedback2">{formErrors.formAddress}</div> : null}
							</div>
							<div md={1} xs={6} className="text-start mb-2 form-group">
								<strong>*Alamat Penerima</strong>
								<textarea className="form-control" value={formAddress} type="textarea" onChange={(e) => setFormAddress(e.target.value)}></textarea>
								{formErrors.formAddress ? <div className="invalid-feedback2">{formErrors.formAddress}</div> : null}
							</div>
							<div md={1} xs={6} className="text-start mb-2 form-group">
								<strong>*Kodepos Penerima</strong>
								<input className="form-control" value={formPostalCode} type="text" onChange={(e) => setFormPostalCode(e.target.value)} />
								{formErrors.formPostalCode ? <div className="invalid-feedback2">{formErrors.formPostalCode}</div> : null}
							</div>
							<div md={1} xs={6} className="text-start mb-2 form-group">
								<strong>*Tax ID Penerima</strong>
								<input className="form-control" value={formTaxId} type="text" onChange={(e) => setFormTaxId(e.target.value)} />
								{formErrors.formTaxId ? <div className="invalid-feedback2">{formErrors.formTaxId}</div> : null}
							</div>
							<div md={1} xs={6} className="text-start mb-2 form-group">
								<strong>Jadikan Penerima Utama</strong> {formIsDefault ? 'Ya' : 'Tidak'}<br />
								<Switch height={20} width={40} handleDiameter={10} onChange={(e) => { setFormIsDefault(e) }} checked={formIsDefault} />
							</div>
							<div md={1} xs={6} className="text-end mb-2 form-group">
								<button className="btn btn-primary" type="submit" onClick={(e) => { onSubmit(e) }} disabled={isLoadingPostConsignee}>
									{isLoadingPostConsignee ?
										<>
											<Spinner
												as="span"
												animation="border"
												size="sm"
												role="status"
												aria-hidden="true"
											/> &nbsp;</>
										: null}
									Simpan
								</button>
							</div>
						</form>
					</Modal.Body>

				</Modal>
			</>
		)
	}

	return (
		<div style={{

		}}>
			{isBrowser ? <Header /> : <HeaderBackMobile title="Daftar Alamat" />}
			<div className="app2">
				<Sidebar />
				<Container className="text-start p-4">
					<Row className="border border-rounded pt-2">
						<Col sm={12} className="pt-2" style={{ borderBottom: '1px solid #f3f3f3' }}>
							<Nav defaultActiveKey="#" as="ul">
								<Nav.Item as="li">
									<Nav.Link href="#" className={key === "Daftar Pengirim" ? 'nav-tab-address-book-selected' : 'nav-tab-address-book'} onClick={(e) => { e.preventDefault(); setKey("Daftar Pengirim") }}>Daftar Pengirim</Nav.Link>
								</Nav.Item>
								<Nav.Item as="li">
									<Nav.Link href="#" className={key === "Daftar Penerima" ? 'nav-tab-address-book-selected' : 'nav-tab-address-book'} onClick={(e) => { e.preventDefault(); setKey("Daftar Penerima") }}>Daftar Penerima</Nav.Link>
								</Nav.Item>
							</Nav>
						</Col>
						<Col sm={12} className="p-4">
							{key === "Daftar Pengirim" ? <ListShipper /> : null}
							{key === "Daftar Penerima" ? <ListConsignee /> : null}
						</Col>
					</Row>

				</Container>
			</div>
			<div style={{

			}}>
				<Footer />
			</div>
		</div >
	)
}

export default AddressBook;