
import React, { useRef } from 'react';
import { Col, Container, Row, Spinner } from 'react-bootstrap';
import Footer from './components/Footer';
import Header from './components/Header';
import HeaderBackMobile from './components/HeaderBackMobile';
import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect';
import { WordingSyaratKetentuan } from './components/alt';


function SyaratKetentuan() {


	return (
		<div style={{

		}}>
			{isBrowser ? <Header /> : <HeaderBackMobile title="Syarat Ketentuan" />}
			<div className="app">
				<Container className="text-start p-4">
					{isBrowser ?
						<div className="text-center mb-3">
							<h4>Syarat Ketentuan</h4>
						</div>
						: null}
					<WordingSyaratKetentuan />
				</Container>
			</div>
			<div style={{

			}}>
				<Footer />
			</div>
		</div >
	)
}

export default SyaratKetentuan;