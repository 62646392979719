import 'bootstrap/dist/css/bootstrap.min.css';
import Header from './components/Header';
import { Container, Image, Row, Col, Spinner, Form } from 'react-bootstrap';
import React, { useRef, useState, useEffect } from 'react';
import Footer from './components/Footer';
import { Formik, Form as FormFormik } from 'formik';
import { useAuthsso, useLogin, useProfile } from './libs/hooks';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { BottomNavigationAlt, ButtonSocialMediaLogin, InputPassword } from './components/alt';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import Helper from './libs/helper';
import { useHistory } from 'react-router';



function Login() {
	const [{ responseLogin, isLoadingLogin, errorLogin }, fetchLogin] = useLogin();
	const [{ responseProfile, isLoadingProfile }, fetchProfile] = useProfile();
	const [accessToken,isLoadingSSO] = useAuthsso();
	const formLogin = useRef();
	const history = useHistory();

	useEffect(() => {
		if (Helper.guard()) Helper.redirectTo('')
	}, [])

	useEffect(() => {
		if (!responseLogin) return;
		fetchProfile();
	}, [responseLogin])

	useEffect(() => {
		if (!responseProfile) return;
		let redirect = Helper.get('_redirectAfterLogin');
		Helper.set('_redirectAfterLogin', null)
		if (redirect !== null && redirect !== undefined && redirect != 'null') {
			window.location.href = redirect;
		} else {
			Helper.redirectTo('')
		}
	}, [responseProfile])

	const validate = (values) => {
		const errors = {};
		if (!values.email) errors.email = "Required"
		if (!values.password) errors.password = "Required"
		return errors;
	};

	const onSubmit = (data, { setFieldError }) => {
		// alert('login')
		fetchLogin({
			email: data.email,
			password: data.password,
		}, setFieldError)
	}

	return (
		<div className="App">
			<Header />
			<Container className="p-4" style={{ minHeight: '100%' }}>
				<h3>Log In</h3>
				<Row className="justify-content-center">
					<Col sm={12} md={5} className="border shadow p-4 text-start" style={{ borderRadius: 20 }}>
						<Formik innerRef={formLogin} initialValues={{
							email: "",
							password: "",
						}} onSubmit={onSubmit} onKeyPress={onSubmit} validate={validate} validateOnChange={false} validateOnBlur={false} novalidate className="needs-validation was-validated">
							{({ handleChange, handleBlur, handleSubmit, values, errors }) => (
								<FormFormik>
									<div className="text-center mb-4" style={{ color: 'grey' }}>
										Silahkan masuk <br /> Untuk mendapatkan layanan kiriman lengkap dan mudah
									</div>
									{responseLogin ?
										<div className="alert alert-success">
											Login successfully.
										</div>
										: null}
									{errorLogin ?
										<div className="alert alert-danger">
											<FontAwesomeIcon icon={faExclamationTriangle} />&nbsp;
											Email or password is invalid
										</div>
										: null}

									<div className="form-group mb-3">
										<Form.Control
											type="text"
											placeholder="Email"
											aria-describedby="inputGroupPrepend"
											name="email"
											size="lg"
											value={values.email}
											onChange={handleChange}
											isInvalid={!!errors.email}
										/>
										<div className="invalid-feedback">
											{errors.email}
										</div>
									</div>
									<div className="form-group mb-3">
										<InputPassword isInvalid={!!errors.password} invalidFeedback={errors.password} onChange={(e) => formLogin.current.setFieldValue('password', e)} />
									</div>
									<div className="form-group mt-3">
										<a href="#" className="a-primary" onClick={() => { Helper.routeTo(history, '/forgot') }}>Lupa Password?</a> <br />
										Belum punya akun? <a href="#" onClick={() => { Helper.routeTo(history, "/register") }} className="a-primary">Daftar Sekarang</a> <br />
									</div>
									<div className="d-grid mt-3">
										{/* <ButtonLoading title="Masuk" isLoading={isLoadingLogin} onClick={() => { formLogin.current.handleSubmit() }} /> */}
										<button type="submit" className="btn btn-primary btn-block btn-lg bg-secondary" disabled={isLoadingLogin || isLoadingProfile || isLoadingSSO}>
											{isLoadingLogin || isLoadingProfile || isLoadingSSO ?
												<>
													<Spinner
														as="span"
														animation="border"
														size="sm"
														role="status"
														aria-hidden="true"
													/> &nbsp;</>
												: null}
											Masuk
										</button>
										<ButtonSocialMediaLogin />
									</div>
								</FormFormik>
							)}
						</Formik>
					</Col>
				</Row>
			</Container>
			<Footer />
			<BottomNavigationAlt active={3} />
		</div>
	);
}

export default Login;
