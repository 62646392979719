import React, { useEffect, useRef, useState } from 'react';
import { Navbar, Container, Nav, Row, Col, Modal, Form, DropdownButton, Dropdown, Alert, Spinner } from 'react-bootstrap';
import logo from '../images/logo3.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle, faBell, faChevronRight, faEye } from '@fortawesome/free-solid-svg-icons'
import { useLogout, useNotification, useProfile, useUpdatepassword, useUpdateprofile } from '../libs/hooks';
import { Formik } from 'formik';
import { ButtonLoading, EmptyIcon, InputPassword } from './alt';
import Helper from '../libs/helper';
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import { useHistory } from 'react-router';
import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect';
import LinesEllipsis from 'react-lines-ellipsis'
import { ToastContainer, toast } from 'react-toastify';

export default function Header() {
	const [showModalLogin, setShowModalLogin] = useState(false)
	const [showModalProfile, setShowModalProfile] = useState(false)
	const [showModalPassword, setShowModalPassword] = useState(false)
	const [showDropdownNotification, setShowDropdownNotification] = useState(false);
	const [stateForm, setStateForm] = useState("Login");
	const [{ responseProfile, isLoadingProfile, responseRequestVerification }, fetchProfile, fetchRequestEmailVerification] = useProfile();
	const [{ responseLogout, isLoadingLogout }, fetchLogout] = useLogout()
	const [{ responseNotification, isLoadingNotification, responseReadNotification, responseReadAll, responseSummary }, fetchNotification, setReadNotification, setReadAll] = useNotification();
	const [selectedNotification, setSelectedNotification] = useState(null)
	const history = useHistory();
	const [alertCovid, setAlertCovid] = useState(true)
	const [isUserAlreadyVerification, setIsUserAlreadyVerification] = useState(true);

	useEffect(() => {
		Helper.guard()
		if (Helper.get('_disableNotifCovid') === true || Helper.get('_disableNotifCovid') === 'true') {
			setAlertCovid(false)
		}
	}, [])

	useEffect(() => {
		if (!responseRequestVerification) return;
		toast.info(responseRequestVerification.status.message, {
			position: "top-right",
			autoClose: 5000,
			hideProgressBar: false,
			closeOnClick: true,
			pauseOnHover: true,
			draggable: true,
			progress: undefined,
		});
	}, [responseRequestVerification])

	useEffect(() => {
		if (!responseProfile) return;
		setIsUserAlreadyVerification(responseProfile.data.verification)
	}, [responseProfile])

	useEffect(() => {
		if (!responseReadNotification) return;
		window.location.href = selectedNotification.link_to
	}, [responseReadNotification])

	useEffect(() => {
		if (!responseReadAll) return;
		toast.info(responseReadAll.status.message, {
			position: "top-right",
			autoClose: 5000,
			hideProgressBar: false,
			closeOnClick: true,
			pauseOnHover: true,
			draggable: true,
			progress: undefined,
		});
	}, [responseReadAll])

	const doLogout = () => {
		fetchLogout()
	}

	const ModalProfile = () => {
		const formProfile = useRef();
		const [{ responseUpdateProfile, isLoadingUpdateProfile }, updateProfile] = useUpdateprofile();
		const validateProfile = (values) => {
			const errors = {};
			// if (!values.email) errors.email = "Required"
			if (!values.name) errors.name = "Required"
			return errors;
		};

		const onSubmitProfile = (data, { setFieldError }) => {
			updateProfile({
				name: data.name,
				// avatar: null
			}, setFieldError)
		}


		return (
			<Modal aria-labelledby="contained-modal-title-vcenter"
				centered show={showModalProfile} onHide={() => { setShowModalProfile(false) }}>
				<Modal.Header closeButton>
					<Modal.Title>
						<strong className="grey-600">
							Profile
						</strong>
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Row>
						<Col sm={12} className="pb-2">
							<Formik innerRef={formProfile} initialValues={{
								name: responseProfile ? responseProfile.data.name : null,
								email: responseProfile ? responseProfile.data.email : null,
								is_agree: false
							}} onSubmit={onSubmitProfile} validate={validateProfile} validateOnChange={false} validateOnBlur={false} novalidate className="needs-validation was-validated">
								{({ handleChange, handleBlur, handleSubmit, values, errors }) => (
									<>
										{responseUpdateProfile ?
											<div className="alert alert-success">
												{responseUpdateProfile ? responseUpdateProfile.status.message : null}
											</div>
											: null}
										<div className="form-group mb-3">
											<Form.Control
												type="text"
												placeholder="Email"
												aria-describedby="inputGroupPrepend"
												name="email"
												size="lg"
												readOnly={true}
												value={values.email}
												onChange={handleChange}
												isInvalid={!!errors.email}
											/>
											<div className="invalid-feedback">
												{errors.email}
											</div>
										</div>
										<div className="form-group mb-3">
											<Form.Control
												type="text"
												placeholder="Name"
												aria-describedby="inputGroupPrepend"
												name="name"
												size="lg"
												value={values.name}
												onChange={handleChange}
												isInvalid={!!errors.name}
											/>
											<div className="invalid-feedback">
												{errors.name}
											</div>
										</div>
										<div className="d-grid mt-3">
											<ButtonLoading title="Submit" isLoading={isLoadingUpdateProfile} onClick={() => {
												formProfile.current.handleSubmit()
											}} />
										</div>
									</>
								)}
							</Formik>
						</Col>
					</Row>
				</Modal.Body>
			</Modal>
		)
	}

	const ModalPassword = () => {
		const formPassword = useRef();
		const [{ responseUpdatePassword, isLoadingUpdatePassword }, updatePassword] = useUpdatepassword()
		const validate = (values) => {
			const errors = {};
			if (!values.password) errors.password = "Required"
			if (!values.password_confirmation) errors.password_confirmation = "Required"
			if (!values.current_password) errors.current_password = "Required"
			return errors;
		};

		const onSubmit = (data, { setFieldError }) => {
			updatePassword(data, setFieldError)
		}


		return (
			<Modal aria-labelledby="contained-modal-title-vcenter"
				centered show={showModalPassword} onHide={() => { setShowModalPassword(false) }}>
				<Modal.Header closeButton>
					<Modal.Title>
						<strong className="grey-600">
							Update Password
						</strong>
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Row>
						<Col sm={12} className="pb-2">
							<Formik innerRef={formPassword} initialValues={{
								current_password: null,
								password: null,
								password_confirmation: null,
							}} onSubmit={onSubmit} validate={validate} validateOnChange={false} validateOnBlur={false} novalidate className="needs-validation was-validated">
								{({ handleChange, handleBlur, handleSubmit, values, errors }) => (
									<>
										{responseUpdatePassword ?
											<div className="alert alert-success">
												{responseUpdatePassword ? responseUpdatePassword.status.message : null}
											</div>
											: null}
										<div className="form-group mb-3">
											<label>Current Password</label>
											<InputPassword isInvalid={!!errors.current_password} invalidFeedback={errors.current_password} onChange={(e) => formPassword.current.setFieldValue('current_password', e)} />
											<div className="invalid-feedback">
												{errors.current_password}
											</div>
										</div>
										<div className="form-group mb-3">
											<label>New Password</label>
											<InputPassword isInvalid={!!errors.password} invalidFeedback={errors.password} onChange={(e) => formPassword.current.setFieldValue('password', e)} />
											<div className="invalid-feedback">
												{errors.password}
											</div>
										</div>
										<div className="form-group mb-3">
											<label>Password Confirmation</label>
											<InputPassword isInvalid={!!errors.password_confirmation} invalidFeedback={errors.password_confirmation} onChange={(e) => formPassword.current.setFieldValue('password_confirmation', e)} />
											<div className="invalid-feedback">
												{errors.password_confirmation}
											</div>
										</div>
										<div className="d-grid mt-3">
											<ButtonLoading title="Submit" isLoading={isLoadingUpdatePassword} onClick={() => {
												formPassword.current.handleSubmit()
											}} />
										</div>
									</>
								)}
							</Formik>
						</Col>
					</Row>
				</Modal.Body>
			</Modal>
		)
	}

	const closeMonitoringCovid = (state) => {
		Helper.set('_disableNotifCovid', true);
		setAlertCovid(false);
	}

	const onClickNotification = (row) => {
		setSelectedNotification(row);
		setReadNotification(row.id)
	}

	return (
		<>
			{/* <Row className="bg-grey nav-top pr-4">
				<Col sm={12}>
					<Container className="bg-grey">
						<Nav className="justify-content-end" style={{ width: "100%" }}>
							<Nav.Link href="#home" onClick={(e) => { e.preventDefault(); Helper.soon() }} className="text-dark" style={{ paddingTop: 2, paddingBottom: 2, fontSize: '.9em' }}>Layanan</Nav.Link>
							<Nav.Link href="#link" onClick={(e) => { e.preventDefault(); Helper.soon() }} className="text-dark" style={{ paddingTop: 2, paddingBottom: 2, fontSize: '.9em' }}>Blog</Nav.Link>
							<Nav.Link href="#link" onClick={(e) => { e.preventDefault(); Helper.soon() }} className="text-dark" style={{ paddingTop: 2, paddingBottom: 2, fontSize: '.9em' }}>Lacak</Nav.Link>
							<DropdownButton title={'ID'} size="sm" style={{ padding: '0px !important', margin: 0, }}>
								<Dropdown.Item eventKey="2" onClick={() => { }}>ID</Dropdown.Item>
								<Dropdown.Item eventKey="2" onClick={() => { }}>En</Dropdown.Item>
							</DropdownButton>
						</Nav>
					</Container>
				</Col>
			</Row> */}
			<ToastContainer />
			<Navbar className="bg-primary" expand="lg">
				<Container>
					<Navbar.Brand href="#" onClick={() => history.push('/')}>
						<img src={logo} alt="MrEsmallpaket.com" width={180} />
					</Navbar.Brand>
					<Navbar.Toggle aria-controls="basic-navbar-nav" />
					<Navbar.Collapse id="basic-navbar-nav">
						<Nav className="justify-content-end" style={{ width: "100%" }}>
							<Nav.Link href="#" className="text-white">
								Layanan
							</Nav.Link>
							<Nav.Link href="https://blog.smallpaket.com" target="_blank" className="text-white">
								Blog
							</Nav.Link>
							<Nav.Link href="#" onClick={()=>history.push('/tracking')} className="text-white" style={{ borderRight: '1px solid white' }}>
								Lacak
							</Nav.Link>
							{isBrowser ?
								(!Helper.guard() ?
									<>
										<Nav.Link href="#" className="btn btn-outline-secondary text-white btn-sm btn-login-nav-link ms-2 me-2" onClick={() => Helper.routeTo(history, '/login')}>Masuk</Nav.Link>
										<Nav.Link href="#" className="btn btn-outline-secondary text-white btn-sm btn-register-nav-link" onClick={() => Helper.routeTo(history, '/register')}>Daftar</Nav.Link>
									</>
									:
									<>
										<Dropdown className="text-white" drop="down" onMouseEnter={() => setShowDropdownNotification(true)} onMouseLeave={() => setShowDropdownNotification(false)}>
											<Dropdown.Toggle id="dropdown-custom-1" className="notification-dropdown-toggle">
												<span className="fa-stack has-badge" data-count={(responseSummary ? (responseSummary.unread != 0 ? responseSummary.unread : null) : null)}>
													<FontAwesomeIcon icon={faBell} />
												</span>
											</Dropdown.Toggle>
											<Dropdown.Menu show={showDropdownNotification} className="super-colors mr-4 dropdown-menu-center" style={{ width: '400px' }}>
												<Dropdown.Item eventKey="4" className="text-start align-item-center" style={{
													borderBottom: '1px solid rgb(251,128,5)',
												}}>
													<strong style={{ fontSize: '1.2em' }}>
														Notifikasi
													</strong>
												</Dropdown.Item>
												<div style={{ maxHeight: '500px', overflow: 'auto' }}>
													{responseNotification.map((v, i, a) => {
														return (
															<Dropdown.Item key={i} style={{
																borderBottom: '1px solid rgb(251,128,5)',
																maxWidth: '400px',
																backgroundColor: v.read_at ? 'white' : 'rgba(251,128,5,.2)'
															}} onClick={() => onClickNotification(v)}>
																<strong>{v.title}</strong> <br />
																<LinesEllipsis
																	text={v.content}
																	maxLine="3"
																	ellipsis='...'
																	trimRight
																	basedOn='letters'
																	className="text-wrap"
																	style={{
																		fontSize: '.8em'
																	}}
																/>
															</Dropdown.Item>
														)
													})}

													{responseNotification.length === 0 ?
														<Dropdown.Item style={{
															maxWidth: '400px',
														}} className="text-center">
															<EmptyIcon text={<i>Aman...<br /> Belum ada notifikasi buat kamu.</i>} size={100} />
														</Dropdown.Item>
														: null}
												</div>
												<Dropdown.Divider />
												<Dropdown.Item eventKey="4" className="text-end align-item-center">

													<div className="d-flex justify-content-between">

														<a href="#" className="text-orange" onClick={(e) => { e.preventDefault(); Helper.redirectTo('notifikasi') }}>
															<small>
																Lihat Selengkapnya
															</small>
														</a>
														{responseNotification.length != 0 ?
															<a href="#" className="text-orange" onClick={(e) => { e.preventDefault(); setReadAll() }}>
																<small className="text-orange">
																	Tandai Semua Dibaca
																</small>
															</a>
															: null}
													</div>
												</Dropdown.Item>
											</Dropdown.Menu>

										</Dropdown>
										<DropdownButton title={'Hai' + (responseProfile ? ', ' + responseProfile.data.name : '-')} id="bg-vertical-dropdown-2">
											<Dropdown.Item eventKey="2" onClick={() => Helper.redirectTo('order-history')}>Dashboard</Dropdown.Item>
											<Dropdown.Item eventKey="2" onClick={() => Helper.redirectTo('profile')}>Akun</Dropdown.Item>
											{/* <Dropdown.Item eventKey="2" onClick={() => setShowModalPassword(true)}>Ubah Password</Dropdown.Item> */}
											<Dropdown.Item eventKey="2" onClick={() => doLogout()}>Logout</Dropdown.Item>
										</DropdownButton>
									</>
								)
								: null}
							{/* <NavDropdown title="Dropdown" id="basic-nav-dropdown">
							<NavDropdown.Item href="#action/3.1">Action</NavDropdown.Item>
							<NavDropdown.Item href="#action/3.2">Another action</NavDropdown.Item>
							<NavDropdown.Item href="#action/3.3">Something</NavDropdown.Item>
							<NavDropdown.Divider />
							<NavDropdown.Item href="#action/3.4">Separated link</NavDropdown.Item>
						</NavDropdown> */}
						</Nav>
					</Navbar.Collapse>
				</Container>
			</Navbar>
			{alertCovid ?
				<Alert variant="warning" onClose={() => closeMonitoringCovid(false)} dismissible style={{ marginBottom: '0px' }}>
					{/* <Alert.Heading>Oh snap! You got an error!</Alert.Heading> */}
					<div className="text-center">
						<FontAwesomeIcon icon={faInfoCircle} /> &nbsp;
						We are monitoring the impact of the COVID-19 outbreak.
					</div>
				</Alert>
				: null}
			{!isUserAlreadyVerification ?
				<Alert variant="info" className="text-center" style={{ marginBottom: 'unset' }}>
					{isBrowser ?
					<>
					Akun kamu belum melakukan verifikasi email. Klik tombol berikut untuk melakukan verifikasi &nbsp;
						<button className="btn btn-primary ml-2" type="button" onClick={() => fetchRequestEmailVerification()} disabled={isLoadingProfile}>
							{isLoadingProfile ?
								<>
									<Spinner
										as="span"
										animation="border"
										size="sm"
										role="status"
										aria-hidden="true"
									/> &nbsp;</>
								: null}
							Kirim Link Verifikasi
						</button>
						</>
						:
						<>
						<small>Akun kamu belum melakukan verifikasi email. Klik tombol berikut untuk melakukan verifikasi &nbsp;</small>
						<br />
						<button className="btn btn-primary ml-2 btn-sm" type="button" onClick={() => fetchRequestEmailVerification()} disabled={isLoadingProfile}>
							{isLoadingProfile ?
								<>
									<Spinner
										as="span"
										animation="border"
										size="sm"
										role="status"
										aria-hidden="true"
									/> &nbsp;</>
								: null}
							Kirim Link Verifikasi
						</button>
						</>

					}


				</Alert>
				: null}

			<ModalProfile />
			<ModalPassword />
		</>
	)
}