
import React, { useRef, useState, useEffect } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import Footer from './components/Footer';
import Header from './components/Header';
import HeaderBackMobile from './components/HeaderBackMobile';
import Sidebar from './components/Sidebar';
import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect';
import { useNotification } from './libs/hooks';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';

function Notifikasi() {
	const [{ responseNotification, isLoadingNotification, responseReadNotification, isHasLoadMoreNotification }, fetchNotification, setReadNotification, setReadAll, fetchSummary, fetchLoadMoreNotification] = useNotification();
	const [selectedNotification, setSelectedNotification] = useState(null)
	useEffect(() => {
		if (!responseReadNotification) return;
		window.location.href = selectedNotification.link_to
	}, [responseReadNotification])
	const RowNotification = ({ data }) => {
		const onClickNotification = (row) => {
			setSelectedNotification(row);
			setReadNotification(row.id)
		}
		return (
			<a href="#" onClick={(e) => { e.preventDefault(); onClickNotification(data) }} className="card border mb-2 p-2" style={{
				backgroundColor: data.read_at ? 'white' : 'rgba(251,128,5,.2)',
				textDecoration: 'none'
			}}>
				<strong className="text-dark">{data.title}</strong>
				<small className="text-grey">{data.content}</small>
			</a>
		)
	}
	return (
		<div>
			{isBrowser ? <Header /> : <HeaderBackMobile title="Ubah Profile" />}
			<div className="app2">
				<Sidebar />
				<Container className="text-start p-4">
					<Row className="">
						<Col sm={12} md={12} lg={12} className="mb-2">
							<h5 className="text-bold">Notifikasi</h5>
						</Col>
						<Col sm={12} md={12} lg={12} className="">
							{responseNotification.map((v, i, a) => {
								return (
									<RowNotification key={i} data={v} />
								)
							})}

							{!isLoadingNotification && isHasLoadMoreNotification ?
								<div className="d-flex justify-content-center">
									<button className="btn btn-sm btn-secondary" type="button" onClick={() => {fetchLoadMoreNotification()}}>
										Tampilkan Lebih Banyak <FontAwesomeIcon icon={faChevronDown} />
									</button>
								</div>
								: null}
						</Col>
					</Row>
				</Container>
			</div>

			<Footer />

		</div >
	)
}

export default Notifikasi;