import { Formik, Form as FormFormik } from 'formik';
import React, { useEffect, useRef, useState } from 'react';
import { Col, Container, Row, Spinner } from 'react-bootstrap';
import Footer from './components/Footer';
import Header from './components/Header';
import HeaderBackMobile from './components/HeaderBackMobile';
import Sidebar from './components/Sidebar';
import { useProfile, useUpdatepassword, useUpdateprofile } from './libs/hooks';
import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect';

function Profile() {

	const [{ responseProfile, isLoadingProfile }, fetchProfile] = useProfile();
	const [file, setFile] = useState(null)
	const [filePreview, setFilePreview] = useState(null)


	useEffect(()=>{
		if(!responseProfile) return ;
		if(responseProfile.data.avatar) setFilePreview(responseProfile.data.avatar);
	},[responseProfile])

	const FormProfile = () => {
		const [{ responseUpdateProfile, isLoadingUpdateProfile }, updateProfile] = useUpdateprofile()

		useEffect(()=>{
			if(!responseUpdateProfile) return ;
			setFile(null);
			fetchProfile()
		},[responseUpdateProfile])

		const formProfile = useRef();
		const validateProfile = (values) => {
			const errors = {};
			// if (!values.email) errors.email = "Required"
			if (!values.name) errors.name = "Required"
			return errors;
		};

		const onSubmitProfile = (data, { setFieldError }) => {
			updateProfile({
				name: data.name,
				avatar: responseProfile.data.avatar,
				avatar_new: file
			}, setFieldError)
		}

		const onImageChange = async (event) => {
			setFile(null);
			setFilePreview(null)
			let _file = event.currentTarget.files[0];
			let isValid = true;
			let mimes = [
				"image/jpeg",
				"image/jpg",
				"image/png",
			];

			if (!mimes.includes(_file.type)) {
				alert("File harus merupakan PNG atau JPG");
				isValid = false;
			}
			else if (_file.size >= 50000) {
				alert("Ukuran file maksimum adalah 50kb");
				isValid = false;
			}

			if (isValid) {
				setFilePreview(URL.createObjectURL(_file))
				setFile(_file);
			}
			return true;
		}
		return (
			<Formik innerRef={formProfile} initialValues={{
				name: responseProfile ? responseProfile.data.name : "",
				email: responseProfile ? responseProfile.data.email : ""
			}} onSubmit={onSubmitProfile} validate={validateProfile} validateOnChange={false} validateOnBlur={false} novalidate className="needs-validation was-validated">
				{({ handleChange, handleBlur, handleSubmit, values, errors }) => (
					<>
						<FormFormik id="userprofile">
							{responseUpdateProfile ?
								<div className="alert alert-success">
									{responseUpdateProfile ? responseUpdateProfile.status.message : null}
								</div>
								: null}

							<div className="form-group mb-3">
								<Row className="d-flex align-items-center">
									<Col sm={12} md={3} lg={3} className="text-md-end text-lg-end">
										<small>Email Pengguna: </small>
									</Col>
									<Col sm={12} md={9} lg={9}>
										<input type="text" className="form-control form-control-sm" placeholder="Email Kamu" readOnly={true} onChange={handleChange} value={values.email} />
										<div className="invalid-feedback2">
											{errors.email}
										</div>
									</Col>
								</Row>
							</div>
							<div className="form-group mb-3">
								<Row className="d-flex align-items-center">
									<Col sm={12} md={3} lg={3} className="text-md-end text-lg-end">
										<small>Nama Lengkap Pengguna: </small>
									</Col>
									<Col sm={12} md={9} lg={9}>
										<input type="text" className="form-control form-control-sm" placeholder="Nama Kamu" name="name" onChange={handleChange} value={values.name} />
										<div className="invalid-feedback2">
											{errors.name}
										</div>
									</Col>
								</Row>
							</div>
							<div className="form-group mb-3">
								<Row className="d-flex">
									<Col sm={12} md={3} lg={3} className="text-md-end text-lg-end">
										<small>Avatar: </small>
									</Col>
									<Col sm={12} md={9} lg={9}>
										{filePreview ?
											<div className="mb-2">
												<img src={filePreview} style={{ width: 75, height: 75 }} className="rounded-circle" />
											</div> : null}
										<div className="input-group mb-3">
											<input type="file" className="form-control" accept="image/png, image/jpeg" onChange={(event) => onImageChange(event)} />
										</div>
									</Col>
								</Row>
							</div>
							<div className="form-group">
								<Row className="d-flex align-items-center">
									<Col sm={12} md={3} lg={3} className="text-end">

									</Col>
									<Col sm={12} md={9} lg={9}>
										<button type="button" className="btn btn-primary btn-sm" onClick={() => { formProfile.current.handleSubmit() }} onKeyPress={() => { formProfile.current.handleSubmit() }}>
											{isLoadingProfile || isLoadingUpdateProfile ?
												<>
													<Spinner
														as="span"
														animation="border"
														size="sm"
														role="status"
														aria-hidden="true"
													/> &nbsp;</>
												: null}
											Update Profile
										</button>
									</Col>
								</Row>
							</div>
						</FormFormik>

					</>
				)}
			</Formik>
		)
	}

	const FormPassword = () => {
		const formPassword = useRef();
		const [{ responseUpdatePassword, isLoadingUpdatePassword }, updatePassword] = useUpdatepassword()
		const validate = (values) => {
			const errors = {};
			if (!values.password) errors.password = "Required"
			if (!values.password_confirmation) errors.password_confirmation = "Required"
			if (!values.current_password) errors.current_password = "Required"
			return errors;
		};

		const onSubmit = (data, { setFieldError }) => {
			updatePassword(data, setFieldError)
		}

		return (
			<Formik innerRef={formPassword} initialValues={{
				current_password: "",
				password: "",
				password_confirmation: "",
			}} onSubmit={onSubmit} validate={validate} validateOnChange={false} validateOnBlur={false} novalidate className="needs-validation was-validated">
				{({ handleChange, handleBlur, handleSubmit, values, errors }) => (
					<>
						<FormFormik id="userpassword">
							{responseUpdatePassword ?
								<div className="alert alert-success">
									{responseUpdatePassword ? responseUpdatePassword.status.message : null}
								</div>
								: null}
							<div className="form-group mb-3">
								<Row className="d-flex align-items-center">
									<Col sm={12} className="text-start">
										<small>Password Saat Ini: </small>
									</Col>
									<Col sm={12}>
										<input type="password" className="form-control form-control-sm" onChange={handleChange} value={values.current_password} name="current_password" />
										<small className="text-help">*Silahkan isi jika kamu ingin mengubah password</small>
										<div className="invalid-feedback2">
											{errors.current_password}
										</div>
									</Col>
								</Row>
							</div>
							<div className="form-group mb-3">
								<Row className="d-flex align-items-center">
									<Col sm={12} className="text-start">
										<small>Password Baru: </small>
									</Col>
									<Col sm={12}>
										<input type="password" className="form-control form-control-sm" onChange={handleChange} value={values.password} name="password" />
										<div className="invalid-feedback2">
											{errors.password}
										</div>
									</Col>
								</Row>
							</div>
							<div className="form-group mb-3">
								<Row className="d-flex align-items-center">
									<Col sm={12} className="text-start">
										<small>Ulangi Password Baru: </small>
									</Col>
									<Col sm={12}>
										<input type="password" className="form-control form-control-sm" onChange={handleChange} value={values.password_confirmation} name="password_confirmation" />
										<div className="invalid-feedback2">
											{errors.password_confirmation}
										</div>
									</Col>
								</Row>
							</div>

							<div className="form-group">
								<Row className="d-flex align-items-center">
									<Col sm={12}>
										<button type="submit" className="btn btn-primary btn-sm" onClick={() => { formPassword.current.handleSubmit() }} onKeyPress={() => { formPassword.current.handleSubmit() }}>
											{isLoadingUpdatePassword ?
												<>
													<Spinner
														as="span"
														animation="border"
														size="sm"
														role="status"
														aria-hidden="true"
													/> &nbsp;</>
												: null}
											Update
										</button>
									</Col>
								</Row>
							</div>
						</FormFormik>

					</>
				)}
			</Formik>
		)
	}
	return (
		<div style={{

		}}>
			{isBrowser ? <Header /> : <HeaderBackMobile title="Ubah Profile" />}
			<div className="app2">
				<Sidebar />
				<Container className="text-start p-4">
					<Row className="">
						<Col sm={12} md={8} lg={8} className="mb-4">
							<h5 className="text-bold">Informasi Akun</h5>
							{responseProfile ?
								<div className="bg-selected p-3">
									<FormProfile />
								</div>
								: null}
						</Col>
						<Col sm={12} md={4} lg={4}>
							<h5 className="text-bold">Password</h5>
							<div className="bg-selected p-3">
								<FormPassword />
							</div>
						</Col>
					</Row>
				</Container>
			</div>
			<div style={{

			}}>
				<Footer />
			</div>
		</div >
	)
}

export default Profile;