import React, {forwardRef, useEffect, useImperativeHandle, useRef, useState} from 'react';
import { Col, Form, Modal, Row, Spinner, Image, Alert, Accordion } from 'react-bootstrap';
import BottomNavigation from 'reactjs-bottom-navigation'
import 'reactjs-bottom-navigation/dist/index.css'
import { faChevronRight, faEnvelope, faExclamationTriangle, faEye, faEyeSlash, faInfoCircle, faTimes, faWeight, faSearch, faPlus, faChevronCircleUp, faChevronDown, faChevronUp, faHome, faHistory, faUserCircle, faMap, faMapMarker, faCopy } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AsyncPaginate } from "react-select-async-paginate";
import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect';
import axios from 'axios';
import Helper from '../libs/helper';
import { useCalculateprice, useGetdocuments, useGetkategori, useGetpackages, usePostconsignee, usePostshipment, usePostshipper, useGetshipper, useGetconsignee, useGetinsurance } from '../libs/hooks';
import plane from '../images/plane.svg';
import dhl from '../images/dhl.png';
import aramex from '../images/aramex.png';
import sf from '../images/sf.png';
import fedex from '../images/fedex.png';
import decrease from '../images/decrease.jpg';
import ups from '../images/ups.png';
import search from '../images/search.gif';
import empty from '../images/empty.png';
import mapboxgl from '!mapbox-gl';
import * as turf from '@turf/turf';
import CurrencyInput from 'react-currency-input-field';
import { useHistory } from 'react-router';
import Switch from "react-switch";
import bankBca from '../images/bca.png'
import bankBni from '../images/bni.png'
import bankBri from '../images/bri.png'
import markerIcon from '../images/marker.png'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { FacebookLoginButton, GoogleLoginButton } from "react-social-login-buttons"
mapboxgl.accessToken = 'pk.eyJ1IjoiZmVicmlhbnJ6IiwiYSI6ImNrdTE0eWY5MTNoanQzMm5xaGo2cjJvY3EifQ.2taiTyOhlhAu9beE8nQMLg';

export const ButtonLoading = ({ title = "", isLoading = false, onClick }) => {
	return (
		<button type="submit" className="btn btn-primary btn-block btn-lg" disabled={isLoading} onClick={() => onClick()}>
			{isLoading ?
				<>
					<Spinner
						as="span"
						animation="border"
						size="sm"
						role="status"
						aria-hidden="true"
					/> &nbsp;</>
				: null}
			{title}
		</button>
	)
}

export const InputPassword = ({ isInvalid = false, invalidFeedback = "", onChange = () => { } }) => {
	const [isShow, setIsShow] = useState(false)
	const [model, setModel] = useState("")
	useEffect(() => {
		onChange(model)
	}, [model])

	return (
		<>
			{!isShow ?
				<div className="input-group">
					<input value={model} onChange={(e) => { setModel(e.target.value) }} className={`form-control border-end-0 form-control-lg ` + (isInvalid ? 'is-invalid' : '')} type="password" placeholder="Password" id="example-search-input" />
					<span className="input-group-append">
						<button className={`btn btn-outline-secondary btn-lg bg-white border-start-0 rounded-0 border ms-n3 ` + (isInvalid ? 'border-danger' : '')} type="button" onClick={() => setIsShow(true)}>
							<FontAwesomeIcon icon={faEye} />
						</button>
					</span>
					<div className="invalid-feedback">
						{invalidFeedback}
					</div>
				</div>
				: <div className="input-group">
					<input value={model} onChange={(e) => { setModel(e.target.value) }} className={`form-control border-end-0 form-control-lg ` + (isInvalid ? 'is-invalid' : '')} type="text" placeholder="Password" id="example-search-input" />
					<span className="input-group-append">
						<button className={`btn btn-outline-secondary btn-lg bg-white border-start-0 rounded-0 border ms-n3 ` + (isInvalid ? 'border-danger' : '')} type="button" onClick={() => setIsShow(false)}>
							<FontAwesomeIcon icon={faEyeSlash} />
						</button>
					</span>
					<div className="invalid-feedback">
						{invalidFeedback}
					</div>
				</div>
			}
		</>
	)
}

export const Select2 = ({ url, valueId = "id", valueLabel = "name", placeholder = "Pilih data", defaultValue = null, onChange = () => { } }) => {
	const [value, setValue] = useState(null)
	// const [options, setOptions] = useState([])
	useEffect(() => {
		onChange(value)
		// alert(value)
	}, [value])

	// const loadDefault = async () => {
	// 	const response = await axios.get(`${url}/${defaultId}`, {
	// 		headers: {
	// 			"Content-Type": 'application/json',
	// 			"Authorization": "Bearer " + Helper.get('accessToken')
	// 		}
	// 	});
	// 	console.log(response)
	// }

	useEffect(() => {
		if (defaultValue) {
			setValue(defaultValue)
		}
	}, [])

	const loadOptions = async (search, loadedOptions, { page }) => {
		let options = []
		let params = {
			search: search,
			orderBy: 'id',
			sortedBy: 'asc',
			page: page,
			per_page: 10000
		}
		let queryParams = new URLSearchParams(params)
		const response = await axios.get(`${url}?` + queryParams.toString(), {
			headers: {
				"Content-Type": 'application/json',
				"Authorization": "Bearer " + Helper.get('accessToken')
			}
		});
		let hasMore = false;
		let pageNo = page;
		response.data.data.data.map((v, i, a) => {
			let tmp = {
				value: v[valueId],
				label: v[valueLabel]
			};
			if (!options.includes(tmp)) options.push(tmp)
		})
		hasMore = response.data.data.meta.pagination.current_page === response.data.data.meta.pagination.total_pages ? false : true;
		pageNo = hasMore ? (pageNo + 1) : pageNo;
		return {
			options: options,
			has_more: hasMore,
			additional: {
				page: pageNo,
			},
		};
	}
	return (
		<AsyncPaginate
			value={value}
			placeholder={""}
			loadOptions={loadOptions}
			onChange={setValue}
			additional={{
				page: 1,
			}}
		/>
	)
}

export default function SingleKategori({ data, icon, title, isSelected = false, onClick = (v) => { } }) {

	const styles = {
		container: {
			display: 'flex',
			flexDirection: 'column',
			justifyContent: 'center',
			alignItems: 'center',
			backgroundColor: isSelected ? 'rgba(191,231,243,1)' : null,
			padding: 10,
			maxWidth: 150,
			width: 150,
			borderRadius: isSelected ? 15 : null,
		},
		containerMobile: {
			// borderBottom: isSelected ? '3px solid #fb8005' : null,
		}
	}

	if (isMobile) {
		return (
			<a href="#" className="a-dark mx-2 mb-3" onClick={(e) => { e.preventDefault(); onClick(data) }} style={styles.containerMobile}>
				<img src={icon} alt="envelope" width={40} /><br/>
				<div className="text-bold text-wrap text-xs" style={{
					fontSize: ".5em",
					borderBottom: isSelected ? '3px solid #fb8005' : null,
				}}>{title}</div>
			</a>
		)
	}
	return (
		<a href="#" className="a-dark" onClick={(e) => { e.preventDefault(); onClick(data) }} style={styles.container}>
			<img src={icon} alt="envelope" width={80} />
			<strong>{title}</strong>
		</a>
	)
}

export const FormBooking = () => {
	const [kategori, setKategori] = useState(null);
	const [{ responseKategori, isLoadingKategori }, fetchKategori] = useGetkategori()
	const [magicNumber, setMagicNumber] = useState(5500);
	const [keyJenisbarang, setKeyJenisBarang] = useState(0)
	const [origin, setOrigin] = useState("")
	const [destination, setDestination] = useState("")
	const [qty, setQty] = useState("")
	const [panjang, setPanjang] = useState("")
	const [lebar, setLebar] = useState("")
	const [tinggi, setTinggi] = useState("")
	const [berat, setBerat] = useState("")
	const [jenisBarang, setJenisbarang] = useState("")
	const [volume, setVolume] = useState(0);
	const [totalBerat, setTotalBerat] = useState(0);
	const [errors, setErrors] = useState({})
	const [priceBookKey, setPriceBookKey] = useState(0)
	const priceBookRef = useRef();

	const [{ isLoadingCalculatePrice, responseCalculatePrice, errorCalculatePrice }, fetchCalculatePrice, resetCalculatePrice] = useCalculateprice();

	useEffect(() => {
		setKeyJenisBarang((keyJenisbarang + 1));
		setPriceBookKey(priceBookKey + 1)
	}, [kategori, origin, destination])

	useEffect(() => {
		if (responseKategori.length <= 0) return;
		setKategori(responseKategori[0])
	}, [responseKategori])

	useEffect(() => {
		if (!panjang || !lebar || !tinggi || !qty) setVolume(0);
		else {
			setVolume(parseFloat(
				((panjang * lebar * tinggi * qty) / magicNumber)).toFixed(2))
		}
	}, [panjang, lebar, tinggi, qty])

	useEffect(() => {
		if (!qty || !berat) setTotalBerat(0);
		else {
			setTotalBerat(berat * qty)
		}
	}, [qty, berat])

	useEffect(() => {
		if (!errorCalculatePrice) return;
		priceBookRef.current.scrollIntoView()
		setPriceBookKey(priceBookKey + 1)
	}, [errorCalculatePrice])

	useEffect(() => {
		if (!responseCalculatePrice) return;
		priceBookRef.current.scrollIntoView()
		setPriceBookKey(priceBookKey + 1)
	}, [responseCalculatePrice])

	const styles = {
		rowKategori: {
			marginTop: 30,
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center',
			maxWidth: '100%'
		},
		colKategori: {
			// alignItems: 'center',
			// marginRight: 20,
		}
	}

	const validate = () => {
		setErrors({});
		let errorList = {};
		if (!qty) errorList['qty'] = "*Required"
		if (!panjang) errorList['panjang'] = "*Required"
		if (!lebar) errorList['lebar'] = "*Required"
		if (!tinggi) errorList['tinggi'] = "*Required"
		if (!berat) errorList['berat'] = "*Required"
		if (!jenisBarang) errorList['jenisBarang'] = "*Required"
		if (!origin) errorList['origin'] = "*Required"
		if (!destination) errorList['destination'] = "*Required"

		setErrors(errorList);
		return Object.keys(errorList).length === 0
	}

	const onSubmit = (e) => {
		e.preventDefault();
		//validasi
		if (validate()) {
			fetchCalculatePrice({
				courier_id: 1,
				origin_id: origin.value,
				destination_id: destination.value,
				jenis_barang_id: jenisBarang.value,
				quantity: qty,
				long: panjang,
				wide: lebar,
				tall: tinggi,
				weight: berat
			})
		}
	}

	return (
		<>
			<div className={`d-flex flex-row flex-wrap align-item-center justify-content-center w-100` + (isMobile ? ` justify-content-start` : null)} style={styles.rowKategori}>
				{responseKategori.map((v, i, a) => {
					return (
							<SingleKategori className="" data={v} icon={v.avatar} title={v.name} isSelected={kategori === v} key={'kt' + i} onClick={(e) => setKategori(e)} />
					)
				})}
			</div>
			<form className="" onSubmit={(e) => onSubmit(e)}>
				<div className="mt-4 p-4 shadow" style={{
					border: '1px solid #a0a0a0',
					borderRadius: '30px'
				}}>
					<Row className="mb-4">

						<Col md={6} sm={12} className="text-start">
							<strong>Dari</strong>
							<Select2
								url={process.env.REACT_APP_API_HOST_URL + process.env.REACT_APP_API_V1 + "/master/origin"}
								valueId={'id'}
								valueLabel={'name'}
								placeholder="Pilih Lokasi Asal"
								onChange={(e) => setOrigin(e)}
								className="is-invalid"
							/>
							{errors.origin ? <div className="invalid-feedback2">{errors.origin}</div> : null}
						</Col>
						<Col md={6} sm={12} className="text-start">
							<strong>Ke</strong>
							<Select2
								url={process.env.REACT_APP_API_HOST_URL + process.env.REACT_APP_API_V1 + "/master/destination"}
								valueId={'id'}
								valueLabel={'name'}
								placeholder="Pilih Lokasi Asal"
								onChange={(e) => setDestination(e)} />
							{errors.destination ? <div className="invalid-feedback2">{errors.destination}</div> : null}
						</Col>
					</Row>
					<Row className="mb-4">
						<Col md={1} xs={6} className="text-start mb-2">
							<strong>Kuantitas</strong>
							<CurrencyInput className="form-control" placeholder="" allowDecimals={false} onValueChange={(value, name) => setQty(value)} />
							{errors.qty ? <div className="invalid-feedback2">{errors.qty}</div> : null}
						</Col>
						<Col md={2} xs={6} className="text-start mb-2">
							<strong>Panjang</strong>
							<div className="input-group mb-3">
								<CurrencyInput className="form-control" placeholder="" allowDecimals={false} onValueChange={(value, name) => setPanjang(value)} />
								<span className="input-group-text" id="basic-addon2">cm</span>
							</div>
							{errors.panjang ? <div className="invalid-feedback2">{errors.panjang}</div> : null}
						</Col>
						<Col md={2} xs={6} className="text-start mb-2">
							<strong>Lebar</strong>
							<div className="input-group mb-3">
								<CurrencyInput className="form-control" placeholder="" allowDecimals={false} onValueChange={(value, name) => setLebar(value)} />
								<span className="input-group-text" id="basic-addon2">cm</span>
							</div>
							{errors.lebar ? <div className="invalid-feedback2">{errors.lebar}</div> : null}
						</Col>
						<Col md={2} xs={6} className="text-start mb-2">
							<strong>Tinggi</strong>
							<div className="input-group mb-3">
								<CurrencyInput className="form-control" placeholder="" allowDecimals={false} onValueChange={(value, name) => setTinggi(value)} />
								<span className="input-group-text" id="basic-addon2">cm</span>
							</div>
							{errors.tinggi ? <div className="invalid-feedback2">{errors.tinggi}</div> : null}
						</Col>
						<Col md={2} xs={6} className="text-start mb-2">
							<strong>Berat</strong>
							<div className="input-group mb-3">
								<CurrencyInput className="form-control" placeholder="" allowDecimals={false} onValueChange={(value, name) => setBerat(value)} />
								<span className="input-group-text" id="basic-addon2">kg</span>
							</div>
							{errors.berat ? <div className="invalid-feedback2">{errors.berat}</div> : null}
						</Col>
						<Col md={3} xs={6} className="text-start mb-2" key={keyJenisbarang}>
							<strong>Jenis Barang</strong>
							<Select2
								url={process.env.REACT_APP_API_HOST_URL + process.env.REACT_APP_API_V1 + "/master/jenisBarang/category/" + (kategori ? kategori.id : '-')}
								valueId={'id'}
								valueLabel={'name'}
								placeholder="Pilih Jenis Barang"
								onChange={(e) => setJenisbarang(e)} />
							{errors.jenisBarang ? <div className="invalid-feedback2">{errors.jenisBarang}</div> : null}
						</Col>
					</Row>
					<Row style={{
						borderTop: '5px solid #f7f7f7'
					}} className="pt-3">
						<Col md={6} sm={12} className="text-start p-3 mb-3" style={{ backgroundColor: '#F7F7F7' }}>
							<Row>
								<Col sm={12} md={4}>
									Total Volume: {volume} m<sup>3</sup>
								</Col>
								<Col sm={12} md={4}>
									Total Berat: {totalBerat} kg
								</Col>
							</Row>
						</Col>
						<Col md={6} sm={12} className="text-md-end text-sm-center">
							<button className="btn btn-lg btn-primary" type="submit" disabled={isLoadingCalculatePrice}>
								{isLoadingCalculatePrice ?
									<>
										<Spinner
											as="span"
											animation="border"
											size="sm"
											role="status"
											aria-hidden="true"
										/> &nbsp;</>
									: null}
								Dapatkan Harga Terbaik
							</button>
						</Col>
					</Row>
				</div>
			</form>

			<div ref={priceBookRef}>
				{errorCalculatePrice ? <ServiceNotFound /> : null}
				{responseCalculatePrice && jenisBarang ? <FormBookingPrice
					key={priceBookKey}
					kategoriBarang={kategori}
					originData={origin}
					destinationData={destination}
					volume={volume}
					qty={qty}
					berat={berat}
					jenisBarang={jenisBarang}
					data={responseCalculatePrice}
					panjang={panjang}
					lebar={lebar}
					tinggi={tinggi}
				/> : null}
			</div>
		</>
	)
}

export const SearchIcon = ({ text }) => {
	return (
		<Row className="d-flex justify-content-center">
			<Col className="text-center" sm={12}>
				<Image src={search} /> <br />
				<strong>{text}</strong>
			</Col>
		</Row>

	)
}

export const EmptyIcon = ({ text, size = 200 }) => {
	return (
		<Row className="d-flex justify-content-center">
			<Col className="text-center" sm={12}>
				<Image src={empty} className="mb-4" width={size} /> <br />
				<i>{text}</i>
			</Col>
		</Row>

	)
}

export const ServiceNotFound = () => {
	const onCallAdministrator = () => {
		window.location.href = `mailto:${process.env.REACT_APP_ADMINISTRATOR_EMAIL}`
	}
	return (
		<Row className="p-4 border shadow" style={{ borderRadius: 30, marginTop: 50 }}>
			<Col sm={12} md={12}>
				<Alert variant="danger">
					<Alert.Heading>Whooops!!!</Alert.Heading>
					<div className="text-center">
						<FontAwesomeIcon icon={faInfoCircle} /> &nbsp;
						Maaf layanan yang Anda minta saat ini belum tersedia, silahkan hubungi Administrator kami untuk informasi lebih lanjut.
					</div>
				</Alert>
			</Col>
			<Col sm={12} md={12}>
				<Image src={decrease} style={{ width: '30%' }} />
			</Col>
			<Col sm={12} md={12}>
				<div className="mt-2">
					<button className="btn btn-primary bg-secondary" type="button" onClick={() => { onCallAdministrator() }}>
						<FontAwesomeIcon icon={faEnvelope} /> &nbsp;
						Hubungi Administrator
					</button>
				</div>
			</Col>
		</Row>
	)
}

export const IconCourier = ({ courier_id, avatar = null }) => {
	const styles = {
		icon: {
			// width: 120,
			height: isBrowser ? 40 : 20
		},
	}
	return (
		<img src={avatar} style={styles.icon} alt="courier" />
	)

}

export const RowPriceCourier = ({ row, icon, courier_id = 1, avatar = null, paket, courier, price, durasi, formData = {}, onBooking = () => { } }) => {
	const styles = {

		paket: {
			fontSize: '1.2em'
		},
		harga: {
			fontWeight: 'bold',
			fontSize: '1.2em'
		},
		durasi: {
			fontSize: '1.2em'
		},
		buttonBook: {

		}
	}
	useEffect(() => {
		// console.log(formData)
	}, [])

	const onBookingClick = () => {
		let params = {
			_type_id: formData.jenisBarang.value,
			_type_text: formData.jenisBarang.label,
			_category_id: formData.kategoriBarang.id,
			_category_text: formData.kategoriBarang.name,
			_destination_id: formData.destinationData.value,
			_destination_text: formData.destinationData.label,
			_origin_id: formData.originData.value,
			_origin_text: formData.originData.label,
			_weight: formData.berat,
			_wide: formData.lebar,
			_tall: formData.tinggi,
			_long: formData.panjang,
			_qty: formData.qty,
			_courier_id: courier_id
		}
		let queryParams = new URLSearchParams(params);
		if (Helper.guard()) {
			Helper.redirectTo('order?' + queryParams.toString())
		} else {
			Helper.set('_redirectAfterLogin', process.env.REACT_APP_PATH_NAME + "/order?" + queryParams.toString())
			Helper.redirectTo('login')
		}
	}
	return (
		<Row className="justify-center align-items-center mt-4 p-3">
			<Col xs={4} sm={3}>
				<IconCourier courier_id={courier_id} avatar={avatar} />
			</Col>
			{isBrowser ?
				<Col xs={4} sm={3}>
					<div style={styles.paket}>{paket}</div>
				</Col>
				: null}
			<Col xs={4} sm={3}>
				<div style={styles.harga}>IDR {price}</div>
			</Col>
			{/* <Col>
				<div style={styles.durasi}>
					{durasi}
				</div>
			</Col> */}
			<Col xs={4} sm={3}>
				<button className={"btn btn-primary "+(isBrowser?'btn-lg':'btn-sm')} type="button" onClick={() => { onBookingClick() }}>Booking</button>
			</Col>
		</Row>
	)
}

export const RowPriceCourier2 = ({ row, icon, paket, courier, price, durasi, onBooking = () => { } }) => {
	const styles = {
		icon: {
			// width: 120,
			height: 40
		},
		paket: {
			fontSize: '1.2em'
		},
		harga: {
			fontWeight: 'bold',
			fontSize: '1.2em'
		},
		durasi: {
			fontSize: '1.2em'
		},
		buttonBook: {

		}
	}
	const IconCourier = () => {
		if (courier === "DHL") {
			return (
				<img src={dhl} style={styles.icon} alt="courier" />
			)
		} else if (courier === "Fedex") {
			return (
				<img src={fedex} style={styles.icon} alt="courier" />
			)
		} else {
			return (
				<img src={ups} style={styles.icon} alt="courier" />
			)
		}
	}
	return (
		<div>
			<table className="table table-striped table-bordered">
				<thead>
					<tr>
						<th>Kurir</th>
						<th>Paket</th>
						{/* <th>Durasi</th> */}
						<th>Harga</th>
					</tr>
				</thead>
			</table>
		</div>
		// <Row className="justify-center align-items-center mt-4">
		// 	<Col>
		// 		<IconCourier />
		// 	</Col>
		// 	<Col>
		// 		<div style={styles.paket}>{paket}</div>
		// 	</Col>
		// 	<Col>
		// 		<div style={styles.harga}>IDR {price}</div>
		// 	</Col>
		// 	<Col>
		// 		<div style={styles.durasi}>
		// 			{durasi}
		// 		</div>
		// 	</Col>
		// 	<Col>
		// 		<button className="btn btn-primary btn-lg" type="button" onClick={() => onBooking(row)}>Booking</button>
		// 	</Col>
		// </Row>
	)
}

export const SelectDocument = ({ jenisBarangId, defaultValue = [], onChange = () => { } }) => {
	const [{ responseDocument, isLoadingDocument }, fetchDocuments] = useGetdocuments();
	const [selectedDocuments, setSelectedDocuments] = useState([])
	useEffect(() => {
		if (!jenisBarangId) return;
		fetchDocuments(jenisBarangId)
	}, [])

	useEffect(() => {
		if (responseDocument.length <= 0) return;
		responseDocument.map((v, i, a) => {
			if (v.type === 'SUGGESTION') {
				setSelectedDocuments([
					...selectedDocuments,
					v
				])
			}
		})
	}, [responseDocument])

	useEffect(() => {
		onChange(selectedDocuments)
	}, [selectedDocuments])

	const onChangeCheckbox = (v) => {
		if (selectedDocuments.includes(v)) {
			setSelectedDocuments(selectedDocuments.filter((item) => item != v))
		} else {
			setSelectedDocuments([
				...selectedDocuments,
				v
			])
		}
	}

	const isInclude = (v) => {
		selectedDocuments.map((x, i, a) => {
			if (v.id === x.id) return true
		})
		return false;
	}

	return (
		<>
			{/* <div className="form-group"> */}
			<Row>
				{
					responseDocument.map((v, i, a) => {
						return (
							<Col sm={12} key={'doc-' + v.id} className="d-flex align-items-center">
								<input className="form-check-input mt-0" type="checkbox" aria-label="Checkbox for following text input" checked={selectedDocuments.includes(v)} readOnly={v.type === "SUGGESTION" ? true : false} onChange={(e) => { v.type === "SUGGESTION" ? null : onChangeCheckbox(v) }} />
								&nbsp;{v.name}
							</Col>
						)
					})
				}
			</Row>
			{/* </div> */}
		</>
	)
}

export const SelectPackaging = ({ onChange = () => { } }) => {
	const [{ responsePackage, isLoadingPackage }, fetchPackages] = useGetpackages()
	const [selectedPackages, setSelectedPackages] = useState([])
	useEffect(() => {
		fetchPackages()
	}, [])

	useEffect(() => {
		onChange(selectedPackages)
	}, [selectedPackages])


	const onChangeCheckbox = (v) => {
		let isExists = false;
		selectedPackages.map((x, y, z) => {
			if (x.id === v.id) isExists = true;
		})
		if (isExists) {
			setSelectedPackages(selectedPackages.filter((item) => item.id !== v.id))
		} else {
			setSelectedPackages([
				...selectedPackages,
				v
			])
		}
	}

	return (
		<>
			<Row>
				{
					responsePackage.map((v, i, a) => {
						return (
							<Col sm={12} key={'doc-' + v.id} className="d-flex align-items-center">
								<input className="form-check-input mt-0" type="checkbox" aria-label="Checkbox for following text input" onChange={() => onChangeCheckbox(v)} />
								&nbsp;{v.name}
							</Col>
						)
					})
				}
			</Row>
		</>
	)
}

export const SelectShipper = forwardRef(({onChange = () => { },doSubmitAfterResponse = (res) => {}},ref ) => {
	const [isShowModal, setIsShowModal] = useState(false)
	const [{ shipperList, isLoadingShipper }, fetchGetShipper, fetchDeleteShipper, setDefaultShipper] = useGetshipper()
	const [state, setState] = useState("List")
	const [selected, setSelected] = useState(null)
	const [userSelected, setUserSelected] = useState(null);
	const [isShowForm, setIsShowForm] = useState(false)
	const [search, setSearch] = useState("")

	const [formName, setFormName] = useState("")
	const [formPhone, setFormPhone] = useState("")
	const [formAddress, setFormAddress] = useState("")
	const [formErrors, setFormErrors] = useState({})
	const [isDefault, setIsDefault] = useState(false)
	const [isFirstLoad, setIsFirstLoad] = useState(true)
	const [isSubmitAfterResponse, setIsSubmitAfterResponse] = useState(true)
	const [isShowSetAsDefault, setIsShowSetAsDefault] = useState(false)
	const [{ responseShipper, isLoadingPostShipper, errorShipper }, fetchPostShipper] = usePostshipper()

	useEffect(() => {
		onChange(userSelected)
		if (userSelected && userSelected.default == 0) {
			setIsShowSetAsDefault(true)
		} else {
			setIsShowSetAsDefault(false)
		}
	}, [userSelected])

	useEffect(() => {
		if (shipperList.length === 0 || isFirstLoad !== true) return;
		shipperList.map((v, i, a) => {
			if (v.default) setUserSelected(v)
		})
		setIsFirstLoad(false);
	}, [shipperList])

	

	const onSelected = () => {
		setIsShowModal(false);
		setUserSelected(selected);
	}
	const onSearch = () => {
		fetchGetShipper(search)
	}
	const onSelectedSubmit = () => {
		setDefaultShipper(userSelected.id)
		setIsShowSetAsDefault(false)
	}
	const onModalOpen = () => {
		setState("List")
		setIsShowModal(true)
		setUserSelected(null)
	}


	const validate = () => {
		let errors = {}
		setFormErrors({})
		if (!formName) errors.formName = "Required"
		if (!formPhone) errors.formPhone = "Required"
		if (formPhone && formPhone.length < 3) errors.formPhone = "Minimum 3 character"
		if (!formAddress) errors.formAddress = "Required"
		if (formAddress && formAddress.length < 3) errors.formAddress = "Minimum 3 character"
		setFormErrors(errors)
		return Object.keys(errors).length === 0
	}

	// useEffect(()=>{
	// 	if(formName && formAddress && formPhone){
	// 		/** autosave after 3s */
	// 		setTimeout(()=>{
	// 			doSubmit();
	// 		},3000)
	// 	}
	// },[formName,formAddress,formPhone,isDefault])
	const doSubmit = () => {
		if (validate()) {
			fetchPostShipper({
				name: formName,
				phone: formPhone,
				address: formAddress,
				default: isDefault ? 1 : 0
			})
		}
	}
	const onSubmit = (e) => {
		e.preventDefault();
		doSubmit();
	}

	// useImperativeHandle(ref,()=>({
	// 	submitFromParent() {
	// 		doSubmit();
	// 	}
	// }))

	useEffect(() => {
		if (!responseShipper) return;
		fetchGetShipper()
		setIsShowForm(false)
		let resData = responseShipper.data;
		resData.default = 1;
		setUserSelected(resData)
		if (isDefault) {
			setDefaultShipper(resData.id)
			setIsShowSetAsDefault(false)
		} else {
			setIsShowSetAsDefault(true)
		}
		if(isSubmitAfterResponse) doSubmitAfterResponse(resData)
	}, [responseShipper])



	return (
		<div style={{}}>
			{!userSelected ?
				<>
					<a href="#" onClick={(e) => { e.preventDefault(); onModalOpen() }} style={{ color: '#333', textDecoration: 'none' }}>
						<div className="d-flex justify-content-between align-items-center" style={{ border: '1px solid #f3f3f3', padding: 10, borderRadius: 10 }}>
							<span>Pilih Pengirim dari Address Book</span>
							<FontAwesomeIcon icon={faChevronRight} />
						</div>
					</a>
					<small className="mt-2">atau</small> <br />
					{!isShowForm ?
						<button type="button" className="btn btn-outline-secondary btn-sm mt-2 mb-4" onClick={() => setIsShowForm(true)}>
							<FontAwesomeIcon icon={faChevronDown} />&nbsp;
							Buat Pengirim Baru
						</button>
						:
						<button type="button" className="btn btn-outline-danger btn-sm mt-2 mb-4" onClick={() => setIsShowForm(false)}>
							<FontAwesomeIcon icon={faChevronUp} />&nbsp;
							Batal Buat Pengirim Baru
						</button>
					}
				</>
				:
				<>
					<div className="d-flex justify-content-between align-items-center" style={{ border: '1px solid #f3f3f3', padding: 10, borderRadius: 10 }}>
						<div className="d-flex flex-column">
							<div style={{ fontWeight: 'bold', fontSize: '1em' }}>{userSelected.name}</div>
							<div style={{ fontSize: '.8em' }}>Telepon: {userSelected.phone}</div>
							<div style={{ fontSize: '.8em' }}>Alamat: {userSelected.address}</div>
							{isShowSetAsDefault ?
								<div md={1} xs={6} className="text-start mb-2 mt-4 form-group">
									<strong><small>Jadikan Sebagai Pengirim Utama</small></strong><br />
									<Switch id="list-shipper" height={20} width={40} handleDiameter={10} onChange={(e) => { onSelectedSubmit(e) }} checked={isDefault} />
								</div> : null}
							<button type="button" className="btn btn-outline-secondary btn-sm mt-4" onClick={() => onModalOpen()}>
								<FontAwesomeIcon icon={faSearch} />&nbsp;
								Pilih Pengirim dari Address Book
							</button>
						</div>
						<a href="#" onClick={(e) => { e.preventDefault(); onModalOpen() }} style={{ color: '#333', textDecoration: 'none' }}>
							<FontAwesomeIcon icon={faChevronRight} />
						</a>
					</div>
					<small className="mt-2">atau</small> <br />
					{!isShowForm ?
						<button type="button" className="btn btn-outline-secondary btn-sm mt-2 mb-4" onClick={() => setIsShowForm(true)}>
							<FontAwesomeIcon icon={faChevronDown} />&nbsp;
							Buat Pengirim Baru
						</button>
						:
						<button type="button" className="btn btn-outline-danger btn-sm mt-2 mb-4" onClick={() => setIsShowForm(false)}>
							<FontAwesomeIcon icon={faChevronUp} />&nbsp;
							Batal Buat Pengirim Baru
						</button>
					}
				</>
			}
			{isShowForm ?
				<>
					<Modal show={isShowForm} onHide={() => setIsShowForm(false)}>
						<Modal.Header closeButton>
							<Modal.Title>Tambah Pengirim Baru</Modal.Title>
						</Modal.Header>
						<Modal.Body>
							<>
							<form onSubmit={(e) => onSubmit(e)}>
								<div md={1} xs={6} className="text-start mb-2 form-group">
									<strong>Nama Pengirim</strong>
									<input className="form-control" value={formName} type="text" onChange={(e) => setFormName(e.target.value)} />
									{formErrors.formName ? <div className="invalid-feedback2">{formErrors.formName}</div> : null}
								</div>
								<div md={1} xs={6} className="text-start mb-2 form-group">
									<strong>Nomor Telepon Pengirim</strong>
									<input className="form-control" value={formPhone} type="text" onChange={(e) => setFormPhone(e.target.value)} />
									{formErrors.formPhone ? <div className="invalid-feedback2">{formErrors.formAddress}</div> : null}
								</div>
								<div md={1} xs={6} className="text-start mb-2 form-group">
									<strong>Alamat Pengirim</strong>
									<textarea className="form-control" value={formAddress} type="textarea" onChange={(e) => setFormAddress(e.target.value)}></textarea>
									{formErrors.formAddress ? <div className="invalid-feedback2">{formErrors.formAddress}</div> : null}
								</div>
								<div md={1} xs={6} className="text-start mb-2 form-group">
									<strong>Jadikan Pengirim Utama</strong> {isDefault ? 'Ya' : 'Tidak'}<br />
									<Switch height={20} width={40} handleDiameter={10} onChange={(e) => { setIsDefault(e) }} checked={isDefault} />
								</div>
								<div md={1} xs={6} className="text-end mb-2 form-group">
									<button className="btn btn-primary w-100" type="submit" onClick={(e) => { onSubmit(e) }} disabled={isLoadingPostShipper}>
										{isLoadingPostShipper ?
											<>
												<Spinner
													as="span"
													animation="border"
													size="sm"
													role="status"
													aria-hidden="true"
												/> &nbsp;</>
											: null}
										Simpan Informasi Pengirim
									</button>
								</div>
							</form>
							</>
						</Modal.Body>
					</Modal>
				</>
				: null}
			<Modal show={isShowModal} onHide={() => setIsShowModal(false)}>
				<Modal.Header closeButton>
					<Modal.Title>Pilih Pengirim</Modal.Title>
				</Modal.Header>

				<Modal.Body>
					<>
						<div className="form-group">
							<div className="input-group mb-3">
								<input type="search" className="form-control" disabled={isLoadingShipper} placeholder="Pencarian" value={search} onChange={(e) => setSearch(e.target.value)} />
								<button className="btn btn-outline-secondary" type="button" id="button-addon2" onClick={() => { onSearch() }}>
									<FontAwesomeIcon icon={faSearch} />
								</button>
							</div>
						</div>
						<div className="mt-3">
							{isLoadingShipper ?
								<div className="d-flex justify-content-center">
									<Spinner
										as="span"
										animation="border"
										size="sm"
										role="status"
										aria-hidden="true"
									/> &nbsp;
								</div>
								: null}
							{shipperList ?
								(
									(shipperList.length > 0 ?
										shipperList.map((v, i, a) => {
											return (
												<a href="#" key={'shipper-' + v.id} style={{ textDecoration: 'none' }} onClick={(e) => {
													e.preventDefault();
													setSelected(v)
												}}>
													<div
														className={`mb-3 p-2 ` + (selected === v ? `border-shipper-selected` : 'border-shipper')}
													>
														<div className="d-flex justify-content-between">
															<div style={{ fontWeight: 'bold', fontSize: '1em' }}>{v.name}</div>
															<div href="#" onClick={(e) => { e.preventDefault(); setSelected(null); fetchDeleteShipper(v) }} style={{ color: "#333" }}>
																<FontAwesomeIcon icon={faTimes} />
															</div>
														</div>
														<div style={{ fontSize: '.8em' }}>{v.phone}</div>
														<div style={{ fontSize: '.8em' }}>{v.address}</div>
														{v.default === 1 ?
															<div className="text-end" style={{ fontSize: '.7em', padding: 0, width: 'unset' }}><small className="bg-secondary text-white" style={{ border: '1px solid', paddingLeft: 5, paddingRight: 5, paddingTop: 2, paddingBotton: 2, borderRadius: 10 }}>Utama</small></div> : null}
													</div>
												</a>
											)
										})
										:
										<div className="d-flex justify-content-center">
											<i>Tidak ada shipper tersedia</i>
										</div>
									)
								)
								: null}
						</div>
					</>
				</Modal.Body>

				{(selected != null) && (shipperList && shipperList.length > 0) ?
					<Modal.Footer>
						<button className="btn btn-primary" type="button" onClick={() => onSelected()}>
							Pilih
						</button>
					</Modal.Footer>
					: null}
			</Modal>
		</div>
	)
})

export const SelectConsignee = forwardRef(({onChange = () => { },doSubmitAfterResponse = (res) => {}},ref ) => {
	const [isShowModal, setIsShowModal] = useState(false)
	const [{ consigneeList, isLoadingConsignee }, fetchGetConsignee, fetchDeleteConsignee, setDefaultConsignee] = useGetconsignee()
	const [state, setState] = useState("List")
	const [selected, setSelected] = useState(null)
	const [userSelected, setUserSelected] = useState(null);
	const [isShowForm, setIsShowForm] = useState(false)
	const [search, setSearch] = useState("")

	const [formName, setFormName] = useState("")
	const [formPhone, setFormPhone] = useState("")
	const [formPostalCode, setFormPostalCode] = useState("")
	const [formTaxId, setFormTaxId] = useState("")
	const [formAddress, setFormAddress] = useState("")
	const [formErrors, setFormErrors] = useState({})
	const [isSubmitAfterResponse, setIsSubmitAfterResponse] = useState(false);
	const [isDefault, setIsDefault] = useState(false)
	const [isFirstLoad, setIsFirstLoad] = useState(true)
	const [isShowSetAsDefault, setIsShowSetAsDefault] = useState(false)
	const [{ responseConsignee, isLoadingPostConsignee, errorConsignee }, fetchPostConsignee] = usePostconsignee()

	useEffect(() => {
		onChange(userSelected)
		if (userSelected && userSelected.default == 0) {
			setIsShowSetAsDefault(true)
		} else {
			setIsShowSetAsDefault(false)
		}
	}, [userSelected])


	useEffect(() => {
		if (consigneeList.length === 0 || isFirstLoad !== true) return;
		consigneeList.map((v, i, a) => {
			if (v.default) setUserSelected(v)
		})
		setIsFirstLoad(false);
	}, [consigneeList])

	const onSelected = () => {
		setIsShowModal(false);
		setUserSelected(selected);
	}
	const onSelectedSubmit = () => {
		setDefaultConsignee(userSelected.id)
		setIsShowSetAsDefault(false)
	}
	const onSearch = () => {
		fetchGetConsignee(search)
	}
	const onModalOpen = () => {
		setState("List")
		setIsShowModal(true)
		setUserSelected(null)
	}


	const validate = () => {
		let errors = {}
		setFormErrors({})
		if (!formName) errors.formName = "Required"
		if (!formPhone) errors.formPhone = "Required"
		if (formPhone && formPhone.length < 3) errors.formPhone = "Minimum 3 character"
		if (!formPostalCode) errors.formPostalCode = "Required"
		if (formPostalCode && formPostalCode.length < 3) errors.formPostalCode = "Minimum 3 character"
		if (!formTaxId) errors.formTaxId = "Required"
		if (formTaxId && formTaxId.length < 3) errors.formTaxId = "Minimum 3 character"
		if (!formAddress) errors.formAddress = "Required"
		if (formAddress && formAddress.length < 3) errors.formAddress = "Minimum 3 character"
		setFormErrors(errors)
		return Object.keys(errors).length === 0
	}

	const doSubmit = () => {
		if (validate()) {
			fetchPostConsignee({
				name: formName,
				phone: formPhone,
				address: formAddress,
				postal_code: formPostalCode,
				tax_id: formTaxId,
				default: isDefault ? 1 : 0
			})
		}
	}

	const onSubmit = (e) => {
		e.preventDefault();
		doSubmit();
	}

	useEffect(() => {
		if (!responseConsignee) return;
		fetchGetConsignee()
		setIsShowForm(false)
		let resData = responseConsignee.data;
		resData.default = 1;
		setUserSelected(resData)
		if (isDefault) {
			setDefaultConsignee(resData.id)
			setIsShowSetAsDefault(false)
		} else {
			setIsShowSetAsDefault(true)
		}
		if(isSubmitAfterResponse) {
			doSubmitAfterResponse(resData)
		}
	}, [responseConsignee])


	return (
		<div style={{}}>
			{!userSelected ?
				<>
					<a href="#" onClick={(e) => { e.preventDefault(); onModalOpen() }} style={{ color: '#333', textDecoration: 'none' }}>
						<div className="d-flex justify-content-between align-items-center" style={{ border: '1px solid #f3f3f3', padding: 10, borderRadius: 10 }}>
							<span>Pilih Penerima dari Address Book</span>
							<FontAwesomeIcon icon={faChevronRight} />
						</div>
					</a>
					<small className="mt-2">atau</small> <br />
					{!isShowForm ?
						<button type="button" className="btn btn-outline-secondary btn-sm mt-2 mb-4" onClick={() => setIsShowForm(true)}>
							<FontAwesomeIcon icon={faChevronDown} />&nbsp;
							Buat Penerima Baru
						</button>
						:
						<button type="button" className="btn btn-outline-danger btn-sm mt-2 mb-4" onClick={() => setIsShowForm(false)}>
							<FontAwesomeIcon icon={faChevronUp} />&nbsp;
							Batal Buat Penerima Baru
						</button>
					}
				</>
				:
				<>
					<div className="d-flex justify-content-between align-items-center" style={{ border: '1px solid #f3f3f3', padding: 10, borderRadius: 10 }}>
						<div className="d-flex flex-column">
							<div style={{ fontWeight: 'bold', fontSize: '1em' }}>{userSelected.name}</div>
							<div style={{ fontSize: '.8em' }}>Telepon: {userSelected.phone}</div>
							<div style={{ fontSize: '.8em' }}>Alamat: {userSelected.address}</div>
							<div style={{ fontSize: '.8em' }}>Kodepos: {userSelected.postal_code}</div>
							<div style={{ fontSize: '.8em' }}>Tax ID: {userSelected.tax_id}</div>
							{isShowSetAsDefault ?
								<div md={1} xs={6} className="text-start mb-2 mt-4 form-group">
									<strong><small>Jadikan Sebagai Penerima Utama</small></strong><br />
									<Switch id="list" height={20} width={40} handleDiameter={10} onChange={(e) => { onSelectedSubmit(e) }} checked={isDefault} />
								</div> : null}
							<button type="button" className="btn btn-outline-secondary btn-sm mt-4" onClick={() => onModalOpen()}>
								<FontAwesomeIcon icon={faSearch} />&nbsp;
								Pilih Penerima dari Address Book
							</button>
						</div>
						<a href="#" onClick={(e) => { e.preventDefault(); onModalOpen() }} style={{ color: '#333', textDecoration: 'none' }}>
							<div>
								<FontAwesomeIcon icon={faChevronRight} />
							</div>
						</a>
					</div>
					<small className="mt-2">atau</small> <br />
					{!isShowForm ?
						<button type="button" className="btn btn-outline-secondary btn-sm mt-2 mb-4" onClick={() => setIsShowForm(true)}>
							<FontAwesomeIcon icon={faChevronDown} />&nbsp;
							Buat Penerima Baru
						</button>
						:
						<button type="button" className="btn btn-outline-danger btn-sm mt-2 mb-4" onClick={() => setIsShowForm(false)}>
							<FontAwesomeIcon icon={faChevronUp} />&nbsp;
							Batal Buat Penerima Baru
						</button>
					}
				</>
			}
			{isShowForm ?
				<>
					<Modal show={isShowForm} onHide={() => setIsShowForm(false)}>
						<Modal.Header closeButton>
							<Modal.Title>Tambah Penerima Baru</Modal.Title>
						</Modal.Header>

						<Modal.Body>
							<>
							<form onSubmit={(e) => onSubmit(e)}>
								<div md={1} xs={6} className="text-start mb-2 form-group">
									<strong>Nama Penerima</strong>
									<input className="form-control" value={formName} type="text" onChange={(e) => setFormName(e.target.value)} />
									{formErrors.formName ? <div className="invalid-feedback2">{formErrors.formName}</div> : null}
								</div>
								<div md={1} xs={6} className="text-start mb-2 form-group">
									<strong>Nomor Telepon Penerima</strong>
									<input className="form-control" value={formPhone} type="text" onChange={(e) => setFormPhone(e.target.value)} />
									{formErrors.formPhone ? <div className="invalid-feedback2">{formErrors.formAddress}</div> : null}
								</div>
								<div md={1} xs={6} className="text-start mb-2 form-group">
									<strong>Alamat Penerima</strong>
									<textarea className="form-control" value={formAddress} type="textarea" onChange={(e) => setFormAddress(e.target.value)}></textarea>
									{formErrors.formAddress ? <div className="invalid-feedback2">{formErrors.formAddress}</div> : null}
								</div>
								<div md={1} xs={6} className="text-start mb-2 form-group">
									<strong>Kodepos Penerima</strong>
									<input className="form-control" value={formPostalCode} type="text" onChange={(e) => setFormPostalCode(e.target.value)} />
									{formErrors.formPostalCode ? <div className="invalid-feedback2">{formErrors.formPostalCode}</div> : null}
								</div>
								<div md={1} xs={6} className="text-start mb-2 form-group">
									<strong>Tax ID</strong>
									<input className="form-control" value={formTaxId} type="text" onChange={(e) => setFormTaxId(e.target.value)} />
									{formErrors.formTaxId ? <div className="invalid-feedback2">{formErrors.formTaxId}</div> : null}
								</div>
								<div md={1} xs={6} className="text-start mb-2 form-group">
									<strong>Jadikan Sebagai Penerima Utama</strong> <small>({isDefault ? 'Ya' : 'Tidak'})</small><br />
									<Switch id="form" height={20} width={40} handleDiameter={10} onChange={(e) => { setIsDefault(e) }} checked={isDefault} />
								</div>
								<div md={1} xs={6} className="text-end mb-2 form-group">
									<button className="btn btn-primary w-100" type="submit" onClick={(e) => { onSubmit(e) }} disabled={isLoadingPostConsignee}>
										{isLoadingPostConsignee ?
											<>
												<Spinner
													as="span"
													animation="border"
													size="sm"
													role="status"
													aria-hidden="true"
												/> &nbsp;</>
											: null}
										Simpan Informasi Penerima
									</button>
								</div>
							</form>
							</>
						</Modal.Body>
					</Modal>
				</>
				: null}
			<Modal show={isShowModal} onHide={() => setIsShowModal(false)}>
				<Modal.Header closeButton>
					<Modal.Title>Pilih Penerima</Modal.Title>
				</Modal.Header>

				<Modal.Body>
					<>
						<div className="form-group">
							<div className="input-group mb-3">
								<input type="search" className="form-control" disabled={isLoadingConsignee} placeholder="Pencarian" value={search} onChange={(e) => setSearch(e.target.value)} />
								<button className="btn btn-outline-secondary" type="button" id="button-addon2" onClick={() => { onSearch() }}>
									<FontAwesomeIcon icon={faSearch} />
								</button>
							</div>
						</div>
						<div className="mt-3">
							{isLoadingConsignee ?
								<div className="d-flex justify-content-center">
									<Spinner
										as="span"
										animation="border"
										size="sm"
										role="status"
										aria-hidden="true"
									/> &nbsp;
								</div>
								: null}
							{consigneeList ?
								(
									(consigneeList.length > 0 ?
										consigneeList.map((v, i, a) => {
											return (
												<a href="#" key={'shipper-' + v.id} style={{ textDecoration: 'none' }} onClick={(e) => {
													e.preventDefault();
													setSelected(v)
												}}>
													<div
														className={`mb-3 p-2 ` + (selected === v ? `border-shipper-selected` : 'border-shipper')}
													>
														<div className="d-flex justify-content-between">
															<div style={{ fontWeight: 'bold', fontSize: '1em' }}>{v.name}</div>
															<div href="#" onClick={(e) => { e.preventDefault(); setSelected(null); fetchDeleteConsignee(v) }} style={{ color: "#333" }}>
																<FontAwesomeIcon icon={faTimes} />
															</div>
														</div>
														<div style={{ fontSize: '.8em' }}>{v.phone}</div>
														<div style={{ fontSize: '.8em' }}>{v.address}</div>
														{v.default === 1 ?
															<div className="text-end" style={{ fontSize: '.7em', padding: 0, width: 'unset' }}><small className="bg-secondary text-white" style={{ border: '1px solid', paddingLeft: 5, paddingRight: 5, paddingTop: 2, paddingBotton: 2, borderRadius: 10 }}>Utama</small></div> : null}
													</div>
												</a>
											)
										})
										:
										<div className="d-flex justify-content-center">
											<i>Tidak ada shipper tersedia</i>
										</div>
									)
								)
								: null}
						</div>
					</>
				</Modal.Body>

				{(selected != null) && (consigneeList && consigneeList.length > 0) ?
					<Modal.Footer>
						<button className="btn btn-primary" type="button" onClick={() => onSelected()}>
							Pilih
						</button>
					</Modal.Footer>
					: null}
			</Modal>
		</div>
	)
});


export const FormBookingPrice = ({ data, jenisBarang, kategoriBarang, volume, berat, qty, panjang, lebar, tinggi, originData, destinationData }) => {
	const mapContainer = useRef(null);
	const map = useRef(null);
	const [lng, setLng] = useState(107.08);
	const [lat, setLat] = useState(-6.53);
	const [zoom, setZoom] = useState(1.8);
	const [origin, setOrigin] = useState([107.08982901878244, -6.530624565049507]);
	const [destination, setDestination] = useState([101.60692722493536, 3.047844634871481]);
	const [route, setRoute] = useState({
		'type': 'FeatureCollection',
		'features': [
			{
				'type': 'Feature',
				'geometry': {
					'type': 'LineString',
					'coordinates': [origin, destination]
				}
			}
		]
	})
	const [point, setPoint] = useState({
		'type': 'FeatureCollection',
		'features': [
			{
				'type': 'Feature',
				'properties': {},
				'geometry': {
					'type': 'Point',
					'coordinates': origin
				}
			}
		]
	})
	const [steps, setSteps] = useState(500);
	const [selectedPriceBook, setSelectedPriceBook] = useState(null)
	const [formStep, setFormStep] = useState(1)
	const [itemDescription, setItemDescription] = useState("")
	const [itemPrice, setItemPrice] = useState("")
	const [shipper, setShipper] = useState(null)
	const [consignee, setConsignee] = useState(null)
	const [documentList, setDocumentLists] = useState([])
	const [packageList, setPackageLists] = useState([])


	useEffect(() => {
		if (map.current) return; // initialize map only once
		// setLineDistance(turf.length(route.features[0]));
		let arc = [];
		let lineDistance = turf.length(route.features[0]);
		for (let i = 0; i < lineDistance; i += lineDistance / steps) {
			const segment = turf.along(route.features[0], i);
			// setArc([...arc, segment.geometry.coordinates])
			arc.push(segment.geometry.coordinates)
		}
		let tmp = route;
		tmp.features[0].geometry.coordinates = arc;
		// setRoute(tmp);
		map.current = new mapboxgl.Map({
			container: mapContainer.current,
			style: 'mapbox://styles/mapbox/streets-v11',
			center: [lng, lat],
			zoom: zoom,
			dragPan: false,
			dragRotate: false,
			scrollZoom: false
		});
		map.current.on('load', function () {
			map.current.addSource('route', {
				'type': 'geojson',
				'data': route
			});

			map.current.addSource('point', {
				'type': 'geojson',
				'data': point
			});

			map.current.addLayer({
				'id': 'route',
				'source': 'route',
				'type': 'line',
				'paint': {
					'line-width': 2,
					'line-color': '#007cbf'
				}
			});

			map.current.addLayer({
				'id': 'point',
				'source': 'point',
				'type': 'symbol',
				'layout': {
					// This icon is a part of the Mapbox Streets style.
					// To view all images available in a Mapbox style, open
					// the style in Mapbox Studio and click the "Images" tab.
					// To add a new image to the style at runtime see
					// https://docs.mapbox.com/mapbox-gl-js/example/add-image/
					'icon-image': 'airport-15',
					'icon-rotate': ['get', 'bearing'],
					'icon-rotation-alignment': 'map',
					'icon-allow-overlap': true,
					'icon-ignore-placement': true,
					'icon-size': 2
				}
			});
			let counter = 0;
			const animate = () => {
				const start =
					route.features[0].geometry.coordinates[
					counter >= steps ? counter - 1 : counter
					];
				const end =
					route.features[0].geometry.coordinates[
					counter >= steps ? counter : counter + 1
					];
				if (!start || !end) return;

				// Update point geometry to a new position based on counter denoting
				// the index to access the arc
				point.features[0].geometry.coordinates = route.features[0].geometry.coordinates[counter];

				// Calculate the bearing to ensure the icon is rotated to match the route arc
				// The bearing is calculated between the current point and the next point, except
				// at the end of the arc, which uses the previous point and the current point
				point.features[0].properties.bearing = turf.bearing(
					turf.point(start),
					turf.point(end)
				);

				// Update the source with this new data
				map.current.getSource('point').setData(point);

				// Request the next frame of animation as long as the end has not been reached

				if (counter < steps) {
					requestAnimationFrame(animate);
				}
				counter = counter + 1;

			}
			animate()
		});
	}, [])

	const styles = {
		container: {
			marginTop: 50
		},
		originDestination: {
			marginTop: 20,
			display: 'flex',
			flexDirection: 'row',
			width: '100%',
			justifyContent: 'center',
			alignItems: 'center'
		},
		dashed: {
			border: "1px dashed #1513A0",
			height: '1px',
			width: '100%',
			position: 'relative',
		},
		label: {
			paddingLeft: 10,
			paddingRight: 10,
			fontSize: isBrowser ? '1.5em' : '1em',
			fontWeight: 'bold',
			color: '#707070'
		},
		planeIcon: {
			position: 'absolute',
			width: 48.89,
			height: 52.96,
			margin: 'auto',
			zIndex: 1000,
			top: 0,
			transform: "translateY(-50%)",
			left: "43%",
		},
		rounded: {
			border: '3px solid #52b6d3',
			borderRadius: 15,
			height: 15,
			width: 15,
			marginLeft: 5,
			marginRight: 5,
		},
		containerDashPlane: {
			width: '30%',
			position: 'relative'
		},
		containerInfoPaket: {
			marginTop: 30,
			display: 'flex',
			justifyContent: 'space-between',
			alignItems: 'center',
			padding: 10,
			paddingLeft: 40,
			paddingRight: 40,
			border: '1px solid #FFE3CC',
			backgroundColor: '#FFE3CC',
			borderRadius: 10,
		},
		mapsPrice: {
			marginTop: 50,
			border: '1px solid #ffe3cc',
			borderRadius: 10
		},
		maps: {

		},
		mapContainer: {
			height: '300px'
		},
		priceList: {

		}
	}

	/** Form isian awal berupa qty dan ukuran */
	const Step1 = () => {
		const onBookingCourierClick = (e) => {
			setSelectedPriceBook(e)
			setFormStep(formStep + 1)
		}
		return (
			<Row>
				<Col>
					<div style={styles.mapsPrice} className="shadow">
						<div style={styles.maps}>
							<div ref={mapContainer} style={styles.mapContainer} />
						</div>
						<div style={styles.priceList} className="mb-4">
							{data.data.map((v, i, a) => {
								return (
									<RowPriceCourier
										key={'pr' + i}
										row={v}
										icon=""
										courier={v.courier}
										courier_id={v.courier_id}
										paket={v.courier}
										price={v.price_label}
										avatar={v.avatar}
										durasi="1-3 hari"
										formData={{
											jenisBarang: jenisBarang,
											kategoriBarang: kategoriBarang,
											berat: berat,
											qty: qty,
											panjang: panjang,
											lebar: lebar,
											tinggi: tinggi,
											originData: originData,
											destinationData: destinationData
										}}
										onBooking={(e) => onBookingCourierClick(e)}
									/>
								)
							})}
						</div>
					</div>
				</Col>
			</Row>
		)
	}

	/** form isian deskripsi dan addon */
	const Step2 = ({ namaBarang = "", hargaBarang = "", documentBarang = [], packageBarang = [] }) => {
		const [formNamaBarang, setFormNamaBarang] = useState(namaBarang)
		const [formHargaBarang, setFormHargaBarang] = useState(hargaBarang)
		const [formErrors, setFormErrors] = useState("")
		const [selectedDocuments, setSelectedDocuments] = useState([])
		const [selectedPackages, setSelectedPackages] = useState([])


		const validate = () => {
			setFormErrors({})
			let errors = {};
			if (!formNamaBarang) errors.formNamaBarang = "Required"
			if (!formHargaBarang) errors.formHargaBarang = "Required"
			setFormErrors(errors)
			return Object.keys(errors).length === 0
		}
		const onSubmit = (e) => {
			e.preventDefault();
			if (validate()) {
				setItemDescription(formNamaBarang)
				setItemPrice(formHargaBarang)
				setDocumentLists(selectedDocuments)
				setPackageLists(selectedPackages)
				setFormStep(formStep + 1)
			}
		}
		return (
			<form className="" onSubmit={(e) => onSubmit(e)}>
				<Row style={styles.mapsPrice} className="shadow p-4">
					<Col md={6} sm={12} className="text-start">
						<h4>Item Description</h4>
						<div md={1} xs={6} className="text-start mb-2 form-group">
							<strong>Description</strong>
							<textarea className="form-control" value={formNamaBarang} onChange={(e) => setFormNamaBarang(e.target.value)}></textarea>
							{formErrors.formNamaBarang ? <div className="invalid-feedback2">{formErrors.formNamaBarang}</div> : null}
						</div>
						<div md={1} xs={6} className="text-start mb-2 form-group">
							<strong>Item Price</strong>
							<CurrencyInput className="form-control" placeholder="" allowDecimals={false} defaultValue={0} onValueChange={(value, name) => setFormHargaBarang(value)} />
							{/* <input className="form-control" value={formHargaBarang} onChange={(e) => setFormHargaBarang(e.target.value)} /> */}
							{formErrors.formHargaBarang ? <div className="invalid-feedback2">{formErrors.formHargaBarang}</div> : null}
						</div>

					</Col>
					<Col md={6} sm={12} className="text-start">
						<h4>Documents</h4>
						<SelectDocument jenisBarangId={jenisBarang.value} onChange={(e) => setSelectedDocuments(e)} defaultValue={documentBarang} />
						<br />
						<h4>Packages</h4>
						<SelectPackaging onChange={(e) => setSelectedPackages(e)} defaultValue={packageBarang} />
					</Col>
					<Col className="mt-4 text-end" md={12} sm={12}>
						<button className="btn btn-primary btn-lg" style={{ marginRight: 10 }} type="button" onClick={() => setFormStep(formStep - 1)}>
							Sebelumnya
						</button>
						<button className="btn btn-primary btn-lg" type="submit">
							Selanjutnya
						</button>
					</Col>
				</Row>
			</form>
		)
	}

	const Step3 = () => {
		const [errors, setFormErrors] = useState({})
		const [lShipper, setLShipper] = useState(null)
		const [lConsignee, setLConsignee] = useState(null)

		const validate = () => {
			setFormErrors({})
			let errors = {};
			if (!lShipper) errors.lShipper = "Required"
			if (!lConsignee) errors.lConsignee = "Required"
			setFormErrors(errors)
			return Object.keys(errors).length === 0
		}

		const onSubmit = (e) => {
			e.preventDefault();


			if (validate()) {
				setConsignee(lConsignee)
				setShipper(lShipper)
				setFormStep(formStep + 1)

			}
		}

		return (
			<form className="" onSubmit={(e) => onSubmit(e)}>
				<Row style={styles.mapsPrice} className="shadow p-4">
					<Col md={6} sm={12} className="text-start">
						<h4>Pengirim</h4>
						<SelectShipper onChange={(e) => setLShipper(e)} />
					</Col>
					<Col md={6} sm={12} className="text-start">
						<h4>Penerima</h4>
						<SelectConsignee onChange={(e) => setLConsignee(e)} />
					</Col>
					<Col className="mt-4 text-end" md={12} sm={12}>
						<button className="btn btn-primary btn-lg" style={{ marginRight: 10 }} type="button" onClick={() => setFormStep(formStep - 1)}>
							Sebelumnya
						</button>
						<button className="btn btn-primary btn-lg" type="submit">

							Selanjutnya
						</button>
					</Col>
				</Row>
			</form>

		)
	}

	/** Halaman konfirmasi */
	const Step4 = () => {
		const [{ responseShipment, isLoadingShipment, errorShipment }, fetchPostShipment] = usePostshipment()
		const [totalPriceAddon, setTotalPriceAddon] = useState(0)
		const [{ rateInsurance }, fetchGetInsurance] = useGetinsurance();
		const [amountInsurance, setAmountInsurance] = useState(0)
		const [totalAmount, setTotalAmount] = useState(0)
		const history = useHistory();

		useEffect(() => {
			if (!responseShipment) return;
			history.push({
				pathname: '/success',
				state: {
					data: responseShipment
				}
			})
		}, [responseShipment])

		useEffect(() => {
			let totalAddon = 0;
			documentList.map((v, i, a) => {
				totalAddon += v.price
			})
			packageList.map((v, i, a) => {
				totalAddon += v.price
			})
			setTotalPriceAddon(totalAddon)
		}, [])

		useEffect(() => {
			if (rateInsurance !== 0) {
				let total = (parseFloat(selectedPriceBook.price)) * parseFloat(rateInsurance) / 100;
				setTotalAmount(parseFloat(total + selectedPriceBook.price + totalPriceAddon).toFixed(0))
				setAmountInsurance(total.toFixed(2))
			} else {
				setAmountInsurance(0)
			}
		}, [totalPriceAddon, rateInsurance])

		const onSubmit = () => {
			let addons = [];
			documentList.map((v, i, a) => {
				addons.push({
					type: 'DOCUMENT',
					id: v.id,
					price: v.price
				})
			})
			packageList.map((v, i, a) => {
				addons.push({
					type: 'PACKAGING',
					id: v.id,
					price: v.price
				})
			})
			let params = {
				"shipper_id": shipper.id,
				"consignee_id": consignee.id,
				"quantity": qty,
				"long": panjang,
				"wide": lebar,
				"tall": tinggi,
				"weight": berat,
				"courier_id": 1,
				"origin_id": originData.value,
				"destination_id": destinationData.value,
				"category_id": kategoriBarang.id,
				"jenis_barang_id": jenisBarang.value,
				"item_description": itemDescription,
				"item_price": itemPrice,
				"documents": documentList,
				"packages": packageList,
				"addons": addons
			}
			fetchPostShipment(params)
		}

		const styles = {
			title: {
				fontSize: '1em',
				fontWeight: 'bold',
				color: '#333'
				// color: '#fafafa'
			}
		}
		return (
			<Row className="mt-4">
				<Col md={9} sm={12} className="text-start shadow-sm p-3">
					<h5>Confirmation</h5>
					<div className="form-group mb-3 border-bottom">
						<label style={styles.title}>Item Description</label>
						<p>{itemDescription}</p>
					</div>
					<div className="form-group mb-3 border-bottom">
						<label style={styles.title}>Item Price</label>
						<p>{Helper.formatMoney(itemPrice)}</p>
					</div>
					<div className="form-group mb-3 border-bottom pb-3">
						<div style={{ fontWeight: 'bold' }}>Shipper: {shipper.name}</div>
						<div style={{ fontSize: '.9em' }}>Phone: {shipper.phone}</div>
						<div style={{ fontSize: '.9em' }}>Address: {shipper.address}</div>
					</div>
					<div className="form-group mb-3 border-bottom pb-3">
						<div style={{ fontWeight: 'bold' }}>Consignee: {shipper.name}</div>
						<div style={{ fontSize: '.9em' }}>Phone: {shipper.phone}</div>
						<div style={{ fontSize: '.9em' }}>Address: {shipper.address}</div>
						<div style={{ fontSize: '.9em' }}>Postal Code: {shipper.postal_code}</div>
						<div style={{ fontSize: '.9em' }}>Tax ID: {shipper.tax_id}</div>
					</div>
					<div className="form-group mb-3 border-bottom pb-3">
						<label style={styles.title}>Courier</label>
						<p>{selectedPriceBook.courier}</p>
					</div>

				</Col>
				<Col md={3} sm={12} className="text-start shadow-sm p-3">
					<h5 className="border-bottom mb-3">Transaction Overview</h5>
					<div className="d-flex justify-content-between mb-2">
						<div>Amount</div>
						<div>{Helper.formatMoney(selectedPriceBook.price)}</div>
					</div>
					<div className="d-flex justify-content-between mb-2">
						<div>Addon</div>
						<div>{Helper.formatMoney(totalPriceAddon)}</div>
					</div>
					<div className="d-flex justify-content-between mb-2">
						<div>Insurance ({rateInsurance}%)</div>
						<div>{Helper.formatMoney(amountInsurance)}</div>
					</div>
					<div className="d-flex justify-content-between border-top mb-4 pt-3 pb-3 border-bottom">
						<div>Total Amount</div>
						<div style={{ fontWeight: 'bold' }}>{Helper.formatMoney(totalAmount)}</div>
					</div>
					<div className="text-end">
						<button className="btn btn-primary btn-block" type="button" disabled={isLoadingShipment} onClick={() => onSubmit()}>
							{isLoadingShipment ?
								<>
									<Spinner
										as="span"
										animation="border"
										size="sm"
										role="status"
										aria-hidden="true"
									/> &nbsp;</>
								: null}
							Submit
						</button>
					</div>
				</Col>
			</Row>
		)
	}

	const FormBookStep = () => {
		if (formStep === 2) {
			return (
				<Step2 namaBarang={itemDescription} hargaBarang={itemPrice} documentBarang={documentList} packageBarang={packageList} />
			)
		} else if (formStep === 3) {
			return (<Step3 />)
		} else if (formStep === 4) {
			return (<Step4 />)
		}
		return (
			<Step1 />
		)
	}

	return (
		<div style={styles.container}>
			<span className="title-section">Daftar Harga</span>
			<div style={styles.originDestination}>
				<div style={styles.label}>{originData.label}</div>
				<div style={styles.rounded}>&nbsp;</div>
				<div style={styles.containerDashPlane}>
					<img src={plane} style={styles.planeIcon} alt="plane" />
					<div style={styles.dashed}>&nbsp;</div>
				</div>
				<div style={styles.rounded}>&nbsp;</div>
				<div style={styles.label}>{destinationData.label}</div>
			</div>
			{isBrowser ?
				<Row className="justify-content-md-center">
					<Col sm={12} md={8}>
						<div style={styles.containerInfoPaket}>
							<div>{kategoriBarang.name}: {jenisBarang.label}</div>
							<div>Jumlah: {qty} Unit</div>
							<div>Volume: {volume} m<sup>3</sup></div>
							<div>Berat: {berat} kg</div>
						</div>
					</Col>
				</Row>
				:
				<Row className="justify-content-start" style={styles.containerInfoPaket}>
					<Col xs={5} sm={5} className="text-end">{kategoriBarang.name}:</Col>
					<Col xs={7} sm={7} className="text-start">{jenisBarang.label}</Col>
					<Col xs={5} sm={5} className="text-end">Jumlah:</Col>
					<Col xs={7} sm={7} className="text-start">{qty} Unit</Col>

					<Col xs={5} sm={5} className="text-end">Volume:</Col>
					<Col xs={7} sm={7} className="text-start">{volume} m<sup>3</sup></Col>

					<Col xs={5} sm={5} className="text-end">Berat:</Col>
					<Col xs={7} sm={7} className="text-start">{berat} kg</Col>
				</Row>
			}
			<FormBookStep />
		</div>
	)
}

export const BottomNavigationAlt = ({ active = 0 }) => {
	const history = useHistory();
	const bottomNavItems = [
		{
			title: 'Home',
			icon: <FontAwesomeIcon icon={faHome} />,
			activeIcon: <FontAwesomeIcon icon={faHome} />,
			path: '/'
		},
		{
			title: 'Booking',
			icon: <FontAwesomeIcon icon={faPlus} />,
			activeIcon: <FontAwesomeIcon icon={faPlus} />,
			path: 'order',
		},
		{
			title: 'Riwayat',
			icon: <FontAwesomeIcon icon={faHistory} />,
			activeIcon: <FontAwesomeIcon icon={faHistory} />,
			path: 'order-history'
		},
		{
			title: 'Akun',
			icon: <FontAwesomeIcon icon={faUserCircle} />,
			activeIcon: <FontAwesomeIcon icon={faUserCircle} />,
			path: 'm-user-profile'
		},
	];
	return (
		<div>
			{isMobile ?
				<BottomNavigation
					items={bottomNavItems}
					defaultSelected={active}
					onItemClick={(item) => Helper.routeTo(history, item.path)}
				/> : null}
		</div>
	)
}

export const WordingKebijakanPrivasi = () => {
	return (
		<>
			{isBrowser ?
				<div className="text-center mb-3">
					<h4>Kebijakan Privasi</h4>
				</div>
				: null}
			<p>
				{`Smallpaket berkomitmen untuk melindungi privasi Anda selama kunjungan Anda ke situs web kami dan mengakui tanggung jawab kami untuk menjaga kerahasiaan informasi yang Anda berikan kepada kami setiap saat.`}
			</p>
			<p>
				<div className="text-bold">SYARAT PENGGUNAAN</div>
				{`
				Dalam Kebijakan Privasi ini, kecuali konteksnya mensyaratkan lain, kata-kata, dan ungkapan yang didefinisikan dalam Ketentuan Penggunaan akan memiliki arti yang sama ketika digunakan dalam Kebijakan Privasi ini. Kebijakan Privasi ini merupakan bagian dari Ketentuan Penggunaan.
				Akses dan penggunaan situs web tunduk pada Kebijakan Privasi kami dan dengan mengakses situs web, Anda mengkonfirmasi bahwa Anda telah membaca dan memahami Kebijakan Privasi dan Anda menandakan penerimaan Anda atas hal yang sama.
				Silakan juga merujuk ke Syarat Penggunaan untuk rincian kontak tambahan untuk kami. Dalam Kebijakan Privasi ini, "kami", "kami", dan "milik kami" berarti smallpaket dan / atau anak perusahaannya.
				`}
			</p>
			<p>
				<div className="text-bold">DATA DIKUMPULKAN SECARA OTOMATIS</div>
				{`Kami mencatat domain dan alamat IP setiap pengunjung secara otomatis. Informasi ini tidak mengidentifikasi Anda sebagai individu tetapi mengidentifikasi komputer yang digunakan untuk melihat situs web. Data ini digunakan untuk melihat di mana situs digunakan di dunia untuk memastikan cakupan, dan untuk analisis aliran klik untuk membantu lebih memahami penggunaan situs web ini sehingga kami dapat meningkatkan layanan kami kepada Anda. Kami tidak menghubungkan informasi yang secara otomatis dicatat dengan cara tersebut dengan data pribadi tentang individu tertentu.`}
			</p>
			<p>
				<div className="text-bold">COOKIE</div>
				{`"Cookie" adalah file data kecil yang ditransfer oleh situs web ke hard drive komputer Anda. Smallpaket mungkin saja mengirimkan cookie saat Anda mendaftar, menjelajahi situs kami, meminta atau mempersonalisasi informasi, atau mendaftarkan diri Anda untuk layanan tertentu. Menerima cookie yang digunakan di situs web kami tidak memberi kami akses ke informasi pribadi Anda, tetapi kami dapat menggunakan cookie untuk mengidentifikasi komputer Anda. Cookie biasanya diklasifikasikan sebagai cookie "sesi" atau cookie "persisten".
					Cookie "Sesi" tidak tinggal di komputer Anda setelah Anda meninggalkan situs web kami atau menutup browser Anda. Kami menggunakan "cookie sesi" untuk menyediakan fungsionalitas utama pada situs web dan untuk memungkinkan kami menganalisis lalu lintas ke situs dan meningkatkan tingkat layanan yang kami sediakan untuk semua pengunjung situs web.
					Cookie "persisten" adalah cookie yang tetap ada di komputer Anda. Mereka hanya digunakan di situs web kami jika Anda mendaftar ke situs tersebut dan membantu kami meningkatkan tingkat layanan yang kami tawarkan kepada Anda. Misalnya, cookie tetap memungkinkan Anda memasukkan kata sandi hanya sekali di situs, bukan di setiap halaman web yang memerlukan login. Pengguna dapat secara manual menghapus cookie "Persisten" kapan saja.
					Sebagian besar browser secara otomatis menerima cookie secara default, tetapi Anda biasanya dapat menolak cookie atau secara selektif menerima cookie tertentu dengan menyesuaikan preferensi di browser Anda. Jika Anda mematikan cookie, mungkin ada beberapa fitur situs kami yang tidak akan tersedia untuk Anda dan beberapa halaman di situs web mungkin tidak ditampilkan dengan benar.`}
			</p>
			<p>
				<div className="text-bold">PENGUMPULAN DAN PENGGUNAAN DATA</div>
				{`Saat Anda meminta informasi melalui situs web ini, kami mungkin perlu mengetahui beberapa informasi pribadi tentang Anda. Saat mengirim email kepada kami, atau berlangganan berita atau informasi gratis, kami memerlukan alamat email, nama, dan pengenal pribadi terbatas lainnya, biasanya nama dan nomor kontak.
					Jika Anda meminta layanan atau produk terkait pekerjaan, kami biasanya memerlukan data terbatas tentang pengenal pribadi, pendidikan, pekerjaan, keluarga, dan detail keuangan. Data pribadi Anda akan diproses untuk memberikan informasi, barang, produk, dan layanan yang Anda minta dalam bentuk yang Anda butuhkan.
					Smallpaket dapat merilis informasi pribadi jika kami meyakini bahwa rilis tersebut sesuai untuk mematuhi hukum yang berlaku; menegakkan atau menerapkan Ketentuan Penggunaan dan perjanjian lainnya. Ini termasuk bertukar informasi dengan perusahaan, agensi, dan organisasi lain untuk perlindungan kejahatan dan penipuan serta pengurangan risiko kredit. Namun, ini tidak termasuk menjual, menyewakan, berbagi, atau mengungkapkan informasi identitas pribadi dari pelanggan untuk tujuan komersial yang melanggar komitmen yang ditetapkan dalam Kebijakan Privasi ini.`}
			</p>
			<p>
				<div className="text-bold">RETENSI DATA</div>
				{`Data akan disimpan selama langganan Anda aktif untuk tujuan menghubungi Anda secara efektif setelah waktu itu tidak dipersonalisasi dan tidak dapat diidentifikasi. Kami tidak mengumpulkan informasi tambahan apa pun tentang Anda selain yang diuraikan di atas.`}
			</p>
			<p>
				<div className="text-bold">AKSES KE DATA PRIBADI ANDA</div>
				{`Untuk menanyakan tentang mengakses data pribadi Anda, atau jika Anda memiliki pertanyaan terkait dengan kebijakan privasi data kami, silakan kirim email ke info@smallpaket.com`}
			</p>
			<p>
				<div className="text-bold">AMANDEMEN</div>
				{`Undang-undang perlindungan data dapat direvisi dan kami menyarankan Anda untuk secara teratur memeriksa Kebijakan Privasi ini untuk perubahan.`}
			</p>
			<p>
				<div className="text-bold">HUKUM YANG MENGATUR DAN YURISDIKSI</div>
				{`Kebijakan Privasi ini diatur oleh hukum Indonesia, tidak termasuk penerapan aturan pertentangan hukumnya.`}
			</p>
		</>
	)
}

export const WordingSyaratKetentuan = () => {
	return (
		<ol>
			<li>
				<span className="text-bold">Definisi</span>
				<ol type="a">
					<li>Small paket berarti PT. Triton Nusantara Tangguh dan karyawannya</li>
					<li>Pengguna Layanan berarti pihak yang menggunakan jasa dari Mister Exportir baik pengirim maupun penerima</li>
					<li>Kurir berarti partner kurir yang ditunjuk Mister Exportir untuk melakukan proses pengiriman</li>
				</ol>
			</li>
			<li>
				<span className="text-bold">Jenis Layanan</span>
				<ol type="a">
					<li>Jenis layanan yang disediakan oleh smallpaket adalah DDU dari INCOTERM 2010 yang  di bentuk oleh ICC (International Chamber of Commerce) dan di dalam pelaksanaanya didukung oleh beberapa kurir yang telah disediakan oleh smallpaket</li>
					<li>Fitur add ons yang disediakan oleh smallpaket akan otomatis menggunakan undername dari PT Triton Nusantara Tangguh</li>
				</ol>
			</li>
			<li>
				<span className="text-bold">Ketentuan Barang</span>
				<ol type="a">
					<li>Jika data isi kiriman tidak sesuai dengan barang yang dikirim, sehingga ditahan/dikembalikan oleh pihak bea cukai karena melanggar aturan aviation security serta aturan IATA, maka ongkos kirim dinyatakan hangus dan apabila paket ini disita oleh bea cukai, maka hal ini di luar tanggung jawab Smallpaket. Smallpaket tidak bertanggung jawab atas segala informasi yang tidak benar yang diberikan oleh pengirim.</li>
					<li>Pengguna layanan Smallpaket menjamin bahwa data barang yang diberikan lengkap dan sesuai dengan keadaan riil barang yang akan dikirimkan dan Smallpaket tidak bertanggung jawab apabila pengguna layanan tidak memberitahukan informasi tindakan khusus.</li>
					<li>Smallpaket tidak bertanggung jawab atas kerusakan yang ditimbulkan karena proses pembungkusan yang dilakukan oleh pengguna layanan, ataupun proses pengepakan dan aspek lainnya dalam proses pengiriman barang yang dilakukan oleh kurir.</li>
					<li>Tidak mengirim barang berupa barang yang diklasifikasikan sebagai bahan berbahaya, barang berbahaya, bahan terlarang atau dibatasi menurut Regulator di indonesia, IATA (International Air Transport Association), ICAO (International Civil Aviation Organisation), ADR (Regulasi Transportasi Jalan Raya Eropa untuk barang berbahaya/ European Road Transport Regulation on dangerous goods), atau organisasi yang berkaitan lainnya (“Barang Berbahaya”).</li>
					<li>Dengan mengirim barang ke kantor smallpaket, pengguna layanan menyetujui mengenai pemeriksaan fisik/ validasi barang yang akan dilakukan oleh smallpaket demi menghindari kesalahan deskripsi barang/ penyelundupan.</li>
					<li>Barang yang Kategori dan atau sub kategorinya-nya tidak sesuai dengan input data yang dilakukan oleh pengguna layanan akan diberhentikan proses pengirimannya sampai pengguna layanan membuat kode pemesanan baru, dan akan diinfokan oleh admin smallpaket</li>
					<li>Smallpaket sebagai agen pengiriman dari pengguna layanan tidak bertanggung jawab atas barang yang tidak mematuhi asas Hukum yang berlaku di negara pengirim dan penerima, dan pengguna layanan bertanggung jawab secara pribadi sebagai pemilik barang tersebut.</li>
				</ol>
			</li>
			<li>
				<span className="text-bold">Ketentuan Pembayaran</span>
				<ol type="a">
					<li>Nilai Invoice yang terbit dalam aplikasi atau website adalah nilai estimasi dari pengiriman yang akan dijalankan, Nilai Riil (Fixed Rate) akan diberitahukan setelah proses Validasi yang dilakukan oleh Smallpaket</li>
					<li>Biaya yang tercantum dalam Fixed Rate belum termasuk biaya pajak kepabeanan di negara asal dan negara tujuan</li>
					<li>Rate yang diberlakukan adalah hasil penghitungan berat atau volume barang dan layanan yang dipilih, dan berdasarkan perhitungan yang lebih besar antara Berat barang dan Volume Barang.</li>
					<li>Pengguna Pelayanan hanya melakukan Pembayaran ke rekening atas nama PT. Triton Nusantara Tangguh, selain pembayaran yang dikirimkan ke rekening tersebut tidak diakui oleh Smallpaket.</li>
					<li>Pembayaran oleh pengguna layanan dipenuhi setelah Fixed Rate Diterbitkan dan proses Ekspor tidak akan dilanjutkan sebelum pembayaran dipenuhi oleh pengguna Layanan</li>
					<li>Pengguna Layanan Menyetujui bahwa segala bentuk pembayaran shipment yang telah diberikan kepada Mister Exportir tidak bisa dikembalikan.</li>
				</ol>
			</li>
			<li>
				<span className="text-bold">Proses Bea Cukai</span>
				<ol type="a">
					<li>Dengan ini pengirim menyatakan PT Triton Nusantara Tangguh sebagai agen  pengirim dan menunjuk kurir pengiriman untuk melakukan proses di bea cukai baik di Negara pengirim dan di Negara Tujuan sesuai layanan yang dipilih.</li>
					<li>Pengguna layanan harus memastikan penerima barang di negara tujuan siap untuk melakukan proses Import Clearance dan menyiapkan semua syarat yang ditetapkan oleh Bea cukai negara tujuan atas barang yang dikirim.</li>
					<li>Pengguna layanan menyetujui dan mengizinkan inspeksi barang oleh Smallpaket untuk Keperluan Kepabeanan yang berlaku.</li>
					<li>Biaya bea cukai. Pengirim bertanggung jawab penuh untuk menjamin bahwa penerima di Negara tujuan bersedia menerima barang tersebut.</li>
				</ol>
			</li>
			<li>
				<span className="text-bold">Klaim & Kompensasi</span>
				<ol type="a">
					<li>Setiap klaim kerusakan harus dilaporkan oleh Pengirim/Penerima kepada Smallpaket, dalam waktu 7 hari setelah paket diterima, Smallpaket tidak akan melayani klaim yang dilakukan lebih dari waktu tersebut. Barang pecah belah tidak termasuk sebagai barang yang bisa diklaim.</li>
					<li>Smallpaket  tidak bertanggung jawab untuk setiap kerugian, keterlambatan dan kerusakan yang terjadi oleh pihak kurir dalam proses pengiriman barang, jika memang kerugian dan kerusakan terjadi karena Smallpaket, limit tanggung jawab Mister Exportir adalah sampai dengan IDR 1,000,000.00 (Satu Juta Rupiah), jumlah ini adalah jumlah maksimal penggantian.</li>
					<li>Setiap pengiriman paket ataupun dokumen dilakukan oleh partner kurir, sehingga jika terjadi resiko, Mister Exportir akan membantu sebagai penghubung Pengguna layanan ke pihak kurir dan berlaku sebagai komunikator antara keluhan pengguna layanan dan kurir</li>
					<li>Kerusakan dan kehilangan tidak termasuk kerugian tidak langsung yang mana disebabkan antara lain : keterlambatan pengiriman, kerugian pendapatan, keuntungan, bunga, dan perbedaan harga selisih pasar, dan kerugian lainnya. tidak menjadi tanggung jawab Smallpaket</li>
					<li>Status barang hilang akan berlaku jika tidak ada informasi dari kurir dalam tempo 30 hari kerja dari tanggal pengiriman.</li>
					<li>Proses penggantian kerugian atas kehilangan barang kiriman akan dilakukan dalam 7 hari kerja.</li>
				</ol>
			</li>
			<li>
				<span className="text-bold">Retur</span>
				<ol type="a">
					<li>Retur Barang adalah proses pengembalian barang yang dilakukan oleh smallpaket kepada pengguna layanan terhadap barang yang tidak bisa melewati proses kepabeanan, pengguna layanan bisa mengambil paket yang gagal dikirim di kantor Smallpaket</li>
					<li>Segala biaya yang dibutuhkan pihak kurir untuk proses retur barang akan menjadi tanggung jawab pengguna layanan.</li>
					<li>Waktu proses pengembalian barang menyesuaikan dengan waktu proses yang bisa dilakukan oleh pihak kurir dan proses akan dimulai setelah pengguna layanan mengisi Formulir Pengembalian Barang yang telah disediakan Mister Exportir.</li>
				</ol>
			</li>
			<li>
				<span className="text-bold">Lain-lain</span>
				<ol type="a">
					<li>Pengguna Layanan Bertanggung jawab sebagai pribadi dan membebaskan Smallpaket  dari segala tuntutan hukum yang terjadi jika terjadi hal-hal yang melanggar hukum dikarenakan pengiriman ini.</li>
					<li>Dengan membayar tagihan pengiriman, Pengguna menerima semua poin Syarat & Ketentuan Layanan Smallpaket  tanpa terkecuali</li>
				</ol>
			</li>
		</ol>
	)
}

export const SelectWordingStatus = ({ shipment, status_no }) => {
	if (status_no === 2) return (<WordingValidasiOrder order_no={shipment.order_number} />)
	if (status_no === 3) return (<WordingMenungguPembayaran order_no={shipment.order_number} order_date={shipment.order_date} amount={shipment.total_amount} />)
	if (status_no === 4) return (<WordingPengurusanDokumen order_no={shipment.order_number} order_date={shipment.order_date} amount={shipment.total_amount} />)
	if (status_no === 5) return (<WordingPengiriman tracking_no={shipment.courier_track_no} order_no={shipment.order_number} order_date={shipment.order_date} amount={shipment.total_amount} />)
	if (status_no === 6) return (<WordingDiterima order_no={shipment.order_number} order_date={shipment.order_date} amount={shipment.total_amount} />)
	return (<WordingMenungguBarang order_no={shipment.order_number} shipment={shipment} />)
}

export const WordingPengurusanDokumen = ({ order_no }) => {
	return (
		<>
			<p>
				Order kamu dengan nomor <strong>{order_no}</strong> sedang dalam proses pengurusan dokumen pengiriman.
			</p>
		</>
	)
}

export const WordingPengiriman = ({ order_no, tracking_no }) => {
	const isUrl = (str) => {
		var pattern = new RegExp('^(https?:\\/\\/)?' + // protocol
			'((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
			'((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
			'(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
			'(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
			'(\\#[-a-z\\d_]*)?$', 'i'); // fragment locator
		return !!pattern.test(str);
	}
	return (

		<>
			<p>
				Order kamu dengan nomor <strong>{order_no}</strong> sedang dalam proses pengiriman. Berikut adalah informasi tracking dari Kurir: <br />
				{isUrl(tracking_no) ?
					<a href={tracking_no}>{tracking_no}</a>
					:
					<strong>
						<i>
							{tracking_no}
						</i>
					</strong>
				}
			</p>
		</>
	)
}
export const WordingDiterima = ({ order_no }) => {
	return (

		<>
			<p>
				Order kamu dengan nomor <strong>{order_no}</strong> telah sampai dialamat tujuan.
			</p>
		</>
	)
}

export const WordingValidasiOrder = ({ order_no }) => {
	return (
		<>
			<p>
				Order kamu dengan nomor <strong>{order_no}</strong> sedang dalam proses validasi, ada beberapa penyesuaian dari Pak Min untuk shipment kamu.
			</p>
		</>
	)
}

export const WordingMenungguPembayaran = ({ order_no, order_date, amount }) => {
	const copyToClipboard = async (text) => {
		toast.info('Berhasil dicopy ke clipboard.', {
			position: "top-right",
			autoClose: 5000,
			hideProgressBar: false,
			closeOnClick: true,
			pauseOnHover: true,
			draggable: true,
			progress: undefined,
		});
		if ('clipboard' in navigator) {
			return await navigator.clipboard.writeText(text);
		} else {
			return document.execCommand('copy', true, text);
		}
	}
	const [urlKonfirmasi, setUrlKonfirmasi] = useState(Helper.generateLinkKonfirmasi(order_no, order_date, amount))
	return (
		<>
			<p>Order kamu dengan nomor <strong>{order_no}</strong> sudah berhasil di validasi, silahkan melakukan pembayaran dengan salah satu metode pembayaran Pak Min berikut:</p>

			<ToastContainer />
			<Accordion>
				<Accordion.Item eventKey="0">
					<Accordion.Header>
						<img src={bankBca} width={50} className="me-4" />
						Bank BCA
					</Accordion.Header>
					<Accordion.Body>
						<div className="text-center">
							<h5>
								546 538 5151 &nbsp;
								<a href="#" title="Copy" onClick={(e) => { e.preventDefault(); copyToClipboard("5465385151") }}>
									<FontAwesomeIcon icon={faCopy} />
								</a>
							</h5>
							<h5>PT. Triton Nusantara Tangguh</h5>
						</div>
						<p>
							<ol>
								<li>Buka menu Transfer</li>
								<li>Masukkan nomor rekening tujuan <strong>546 538 5151</strong>.</li>
								<li>Pastikan nama pemilik rekening atas nama <strong>PT. Triton Nusantara Tangguh</strong>.</li>
								<li>Masukkan nominal pengiriman <strong>{Helper.formatMoney(amount)}</strong></li>
								<li>Submit Transfer.</li>
								<li>Lakukan Konfirmasi Pembayaran.</li>
							</ol>
						</p>
					</Accordion.Body>
				</Accordion.Item>
				<Accordion.Item eventKey="1">
					<Accordion.Header>
						<img src={bankBni} width={50} className="me-4" />
						Bank BNI
					</Accordion.Header>
					<Accordion.Body>
						<div className="text-center">
							<h5>
								117 543 0185 &nbsp;
								<a href="#" title="Copy" onClick={(e) => { e.preventDefault(); copyToClipboard("1175430185") }}>
									<FontAwesomeIcon icon={faCopy} />
								</a>
							</h5>
							<h5>PT. Triton Nusantara Tangguh</h5>
						</div>
						<p>
							<ol>
								<li>Buka menu Transfer</li>
								<li>Masukkan nomor rekening tujuan <strong>117 543 0185</strong>.</li>
								<li>Pastikan nama pemilik rekening atas nama <strong>PT. Triton Nusantara Tangguh</strong>.</li>
								<li>Masukkan nominal pengiriman <strong>{Helper.formatMoney(amount)}</strong></li>
								<li>Submit Transfer.</li>
								<li>Lakukan Konfirmasi Pembayaran.</li>
							</ol>
						</p>
					</Accordion.Body>
				</Accordion.Item>
				<Accordion.Item eventKey="2">
					<Accordion.Header>
						<img src={bankBri} width={50} className="me-4" />
						Bank BRI
					</Accordion.Header>
					<Accordion.Body>
						<div className="text-center">
							<h5>
								0672 0100 0385 306 &nbsp;
								<a href="#" title="Copy" onClick={(e) => { e.preventDefault(); copyToClipboard("067201000385306") }}>
									<FontAwesomeIcon icon={faCopy} />
								</a>
							</h5>
							<h5>PT. Triton Nusantara Tangguh</h5>
						</div>
						<p>
							<ol>
								<li>Buka menu Transfer</li>
								<li>Masukkan nomor rekening tujuan <strong>0672 0100 0385 306</strong>.</li>
								<li>Pastikan nama pemilik rekening atas nama <strong>PT. Triton Nusantara Tangguh</strong>.</li>
								<li>Masukkan nominal pengiriman <strong>{Helper.formatMoney(amount)}</strong></li>
								<li>Submit Transfer.</li>
								<li>Lakukan Konfirmasi Pembayaran.</li>
							</ol>
						</p>
					</Accordion.Body>
				</Accordion.Item>
			</Accordion>
			<div className="mt-3 mb-3 text-center"> atau </div>
			<div className="text-center">
				<a href={urlKonfirmasi} target="_blank" rel="noreferrer" className="btn btn-primary">
					Saya Ingin Konfirmasi Pembayaran
				</a>
			</div>
		</>
	)
}

export const WordingMenungguBarang = ({ order_no, shipment }) => {
	const mapContainer = useRef(null);
	const map = useRef(null);
	const [lng, setLng] = useState((shipment && shipment.origin ? shipment.origin.longitude : 106.8506568));
	const [lat, setLat] = useState((shipment && shipment.origin ? shipment.origin.latitude : -6.3720204));
	const [zoom, setZoom] = useState(16);


	const styles = {
		maps: {

		},
		mapContainer: {
			height: '300px'
		}
	}
	useEffect(() => {
		if (map.current) return; // initialize map only once
		map.current = new mapboxgl.Map({
			container: mapContainer.current,
			style: 'mapbox://styles/mapbox/streets-v11',
			center: [lng, lat],
			zoom: zoom,
		});
		const el = document.createElement('div');
		const width = 50;
		const height = 50;
		// el.className = 'marker';
		// el.style.backgroundImage = `url(https://placekitten.com/g/${width}/${height}/)`;
		// el.style.backgroundImage = markerIcon;
		// el.style.width = `${width}px`;
		// el.style.height = `${height}px`;
		// el.style.backgroundSize = '100%';
		const marker = new mapboxgl.Marker()
			.setLngLat([lng, lat])
			.setPopup(new mapboxgl.Popup().setHTML("<strong>Smallpaket.com</strong><br/><a href='https://www.google.co.id/maps/place/6%C2%B022'19.1%22S+106%C2%B051'05.0%22E/@" + lat + "," + lng + ",17z' className=`btn btn-primary` target='_blank'>Lihat pada peta</a>")) // add popup
			.addTo(map.current);
		// map.marker(marker)
		// map.current.marker(marker)
	});
	return (
		<>
			<p>
				{`Order kamu dengan nomor`} <strong>{order_no}</strong> {`berhasil. segera kirimkan barang ke Pak Min yang beralamat di:`}
			</p>
			<p>
				<i>
					{shipment && shipment.origin ? shipment.origin.address : '-'}
				</i>
			</p>
			<div style={styles.maps}>
				<div ref={mapContainer} style={styles.mapContainer} />
			</div>
			<div className="mt-2 text-center">
				<a href="https://www.google.com/maps/place/Jl.+Raya+Pd.+Duta,+Kota+Depok,+Jawa+Barat+16418/@-6.3720204,106.8506568,18.54z/data=!4m9!1m2!2m1!1sJl.+Raya+Pondok+Duta+Raya+No.+17+RT+10+RW+8,+Kel.+Tugu,+Kec.+Cimanggis,+Kota+Depok,+Jawa+Barat+16451!3m5!1s0x2e69ec71872d95d3:0x83fe083416d679cc!8m2!3d-6.3722802!4d106.850683!15sCmRKbC4gUmF5YSBQb25kb2sgRHV0YSBSYXlhIE5vLiAxNyBSVCAxMCBSVyA4LCBLZWwuIFR1Z3UsIEtlYy4gQ2ltYW5nZ2lzLCBLb3RhIERlcG9rLCBKYXdhIEJhcmF0IDE2NDUxkgEFcm91dGU" className="btn btn-primary" target="_blank" rel="noreferrer">
					<FontAwesomeIcon icon={faMapMarker} />&nbsp;
					Lihat Pada Peta
				</a>
			</div>
		</>
	)
}

export const ButtonSocialMediaLogin = () => {
	const isShowAuth = process.env.REACT_APP_ENV === "production";
	const openAuth = (provider) => {
		window.location.href = process.env.REACT_APP_API_HOST_URL + `/api/v1/auth/socialite/${provider}`
		// window.location.href = `https://api.smallpaket.com/api/v1/auth/socialite/${provider}`
	}
	return (

		isShowAuth ?
			<>
				<div className="mt-2 mb-2 text-center" > atau masuk dengan</div>
				<Row>
					<Col>
						<GoogleLoginButton onClick={() => openAuth("google")} align="center">
							<span>Google</span>
						</GoogleLoginButton>
					</Col>
					<Col>
						<FacebookLoginButton onClick={() => openAuth("facebook")} align="center">
							<span>Facebook</span>
						</FacebookLoginButton>
					</Col>
				</Row>
			</>
			: <></>

	)
}