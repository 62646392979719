import { faChevronRight, faMap, faPencilAlt, faInfoCircle, faShieldAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState, useEffect } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useHistory } from 'react-router';
import Header from './components/Header';
import Helper from './libs/helper';
import InitialsAvatar from 'react-initials-avatar';
import 'react-initials-avatar/lib/ReactInitialsAvatar.css';
import { useProfile } from './libs/hooks';
import { BottomNavigationAlt } from './components/alt';

export default function MUserProfile() {
	const history = useHistory();
	const [{ responseProfile, isLoadingProfile }, fetchProfile] = useProfile();
	return (
		<div>
			<Header />
			{responseProfile ?
				<div className="app2 p-3">
					<Row className="w-100">
						<Col xs={2} sm={3}>
							<InitialsAvatar name={responseProfile.data.name} />
						</Col>
						<Col xs={9} sm={3} className="d-flex flex-column">
							<span className="text-bold mb-0">{responseProfile.data.name}</span>
							<small className="mt-0">{responseProfile.data.email}</small><br />
						</Col>
						<Col xs={1} sm={3}>
							<a href="#" onClick={(e) => { e.preventDefault(); Helper.routeTo(history, "/profile") }}>
								<FontAwesomeIcon icon={faPencilAlt} />
							</a>
						</Col>

						<Col sm={12} xs={12} className="mt-4">
							<span className="text-bold">Akun</span>
							<a href="#" className="a-dark" onClick={(e)=>{e.preventDefault();Helper.routeTo(history,'address-shipper')}}>
								<Row className="d-flex align-items-center mt-2">
									<Col xs={2} sm={2} className="text-center pb-1">
										<FontAwesomeIcon icon={faMap} />
									</Col>
									<Col xs={10} sm={10} className="d-flex align-items-center justify-content-between pb-1" style={{ borderBottom: '1px solid #f3f3f3' }}>
										<span className="text-bold">Daftar Alamat</span>
										<FontAwesomeIcon icon={faChevronRight} />
									</Col>
								</Row>
							</a>
						</Col>
						<Col sm={12} xs={12} className="mt-4 d-flex flex-column">
							<span className="text-bold">Lainnya</span>
							<a href="#" className="a-dark mb-2" onClick={(e)=>{e.preventDefault();Helper.routeTo(history,'/kebijakan-privasi')}}>
								<Row className="d-flex align-items-center mt-2">
									<Col xs={2} sm={2} className="text-center pb-1">
										<FontAwesomeIcon icon={faShieldAlt} />
									</Col>
									<Col xs={10} sm={10} className="d-flex align-items-center justify-content-between pb-1" style={{ borderBottom: '1px solid #f3f3f3' }}>
										<span className="text-bold">Kebijakan Privasi</span>
										<FontAwesomeIcon icon={faChevronRight} />
									</Col>
								</Row>
							</a>
							<a href="#" className="a-dark" onClick={(e)=>{e.preventDefault();Helper.routeTo(history,'/syarat-ketentuan')}}>
								<Row className="d-flex align-items-center mt-2">
									<Col xs={2} sm={2} className="text-center pb-1">
										<FontAwesomeIcon icon={faInfoCircle} />
									</Col>
									<Col xs={10} sm={10} className="d-flex align-items-center justify-content-between pb-1" style={{ borderBottom: '1px solid #f3f3f3' }}>
										<span className="text-bold">Syarat & Ketentuan</span>
										<FontAwesomeIcon icon={faChevronRight} />
									</Col>
								</Row>
							</a>
						</Col>

						<Col sm={12} className="d-flex mt-4">
							<button className="btn btn-outline-danger text-bold w-100" onClick={(e)=>{Helper.logout()}}>
								Keluar
							</button>
						</Col>
					</Row>
				</div>
				: null}

				<BottomNavigationAlt active={3} />
		</div >
	)
}